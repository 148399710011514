import { createSlice } from '@reduxjs/toolkit'

export const valueSlice = createSlice({
  name: 'value',
  initialState: null,
  reducers: {
    setValue: (state, action) => {
      return { ...state, ...action.payload }
    },
    removeValue: () => {
      return null
    }
  },
})

export const { setValue, removeValue } = valueSlice.actions

export default valueSlice.reducer