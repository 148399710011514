import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  faGoogle,
  faFacebook,
  faApple,
} from "@fortawesome/free-brands-svg-icons";
import "./Pages.css";
import logo from "./../Assests/dyrect-logo.png";
import { useNavigate } from "react-router-dom";
import {
  signInWithTOTP,
  isAuthenticated,
  AuthService,
  confirmPassword,
  forgotPassword,
  getUserDetails,
  getCognitoUserMfaMethod,
  signInTOTP,
  signInWithEmail,
  verifyEmailOTP,
} from "../Services/AuthService";
import ErrorModal from "../Components/ErrorModal";
import { useSnackbar } from "../Components/SnackbarContext";
import { useGoogleLogin } from "@react-oauth/google";
import { emailValidation, getUserProfileDetails } from "../Services/ShowsApi";

import axios from "axios";
const dyrectApi = process.env.REACT_APP_DYRECT_API_URL;

function Login() {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [popup, setPopup] = useState({ open: false, title: "", message: "" });
  const handlePopupClose = () =>
    setPopup({ open: false, title: "", message: "" });
  const [totpCode, setTotpCode] = useState("");
  const [showTOTP, setShowTOTP] = useState(false);
  const [otpCode, setOtpCode] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [challengeResponse, setChallengeResponse] = useState(null);
  const [signInResponse, setSignInResponse] = useState(null);

  const navigate = useNavigate();
  const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN; // Replace with your Cognito domain
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const redirectUri = "http://localhost:3000/login"; // Set this to your login page's callback URL
  const googleProvider = "Google";
  const { triggerSnackbar } = useSnackbar();
  const [forgotPasswordPopup, setForgotPasswordPopup] = useState({
    open: false,
    stage: 1, // Stage 1: Request Code, Stage 2: Reset Password
    username: "",
    verificationCode: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const navigateToHome = async(event) => {
    const response = await getUserProfileDetails("UserID");
    if(response?.data?.body?.Data[0]?.IsProfileCompleted === 1){
    navigate("/home");
  } else{
    navigate("/information");
  }
  };
  const handleLogin = async () => {
    try {
      if (showTOTP) {
        console.log("Attempting TOTP verification", cognitoUser);
        await signInWithTOTP(cognitoUser, totpCode);
        navigateToHome();
        // navigate("/home");
      } else {
        console.log("Starting login process...");
        const result = await signInWithEmail(email, password);
        console.log("Login result:", result);

        if (result.userConfirmationNecessary) {
          console.log("Setting sign-in response:", result.signInResponse);
          setSignInResponse(result.signInResponse);
          setUsername(result.username);
          setShowTOTP(true);
        }
      }
    } catch (err) {
      console.error("Login error:", err);
      setPopup({
        open: true,
        message: "Login failed. Please check your credentials and try again.",
      });
    }
  };

  const handleVerifyOTP = async () => {
    try {
      console.log("signInResponse", signInResponse);
      if (!signInResponse) {
        console.error("No sign-in response available");
        setPopup({
          open: true,
          message: "Authentication error. Please try logging in again.",
        });
        return;
      }

      if (!totpCode || totpCode.length !== 6) {
        setPopup({
          open: true,
          message: "Please enter a valid 6-digit verification code.",
        });
        return;
      }

      console.log("Verifying Email OTP...");
      const verificationResult = await verifyEmailOTP(totpCode, signInResponse);

      console.log("username....", username);
      if (verificationResult.success) {
        console.log("OTP verified successfully");
        const userDetails = await getUserProfileDetails("UserName", username);
        console.log("userDetails: ", userDetails);
        console.log(userDetails?.data?.body?.Data[0]?.UserID);
        localStorage.setItem(
          "user_id",
          userDetails?.data?.body?.Data[0]?.UserID
        );
        navigateToHome();
      } else {
        setPopup({
          open: true,
          message: "Invalid verification code. Please try again.",
        });
      }
    } catch (err) {
      console.error("OTP verification error:", err);
      setPopup({
        open: true,
        message:
          "An error occurred while verifying the OTP. Please try again later.",
      });
    }
  };
  const handleSignInError = (err) => {
    console.error("Authentication error:", err);

    const errorMessages = {
      NotAuthorizedException:
        "Incorrect username or password. Please try again.",
      UserNotFoundException:
        "User does not exist. Please check your email and try again.",
      CodeMismatchException:
        "The verification code you entered is incorrect. Please double-check it and try again.",
      default: "An error occurred during login. Please try again later.",
    };

    setPopup({
      open: true,
      message: errorMessages[err.code] || errorMessages.default,
    });
  };

  const handleGoogleLogin = useGoogleLogin({
    flow: "popup",
    onSuccess: async (tokenResponse) => {
      console.log("Token Response:", tokenResponse);
      try {
        const access_token = await exchangeGoogleCodeForToken(
          tokenResponse?.code
        );
        const userInfo = await fetchGoogleUserInfo(access_token);
        if (userInfo?.email === undefined) {
          setPopup({
            open: true,
            message: "Unable to retrieve the email. Please try again.",
          });
          return;
        }
        const doesEmailExist = await emailValidation(userInfo?.email);
        if (doesEmailExist?.data?.response === "exist") {
          const userDetails = await getUserProfileDetails(
            "Email",
            userInfo?.email
          );
          console.log("userDetails: ", userDetails);
          console.log(userDetails?.data?.body?.Data[0]?.UserID);
          localStorage.setItem(
            "user_id",
            userDetails?.data?.body?.Data[0]?.UserID
          );
          navigateToHome();
          // navigate("/home");
        } else {
          setPopup({
            open: true,
            message:
              "No account was found. Please sign up before attempting to log in.",
          });
          return;
        }
      } catch (error) {
        console.error("Handle Google Sign up failure:", error);
        setPopup({
          open: true,
          message: error?.message ? error.message : "Unable to Create the User",
        });
      }
    },
    onError: (error) => {
      console.error("Google Login Failed:", error);
    },
  });

  const exchangeGoogleCodeForToken = async (authorizationCode) => {
    const tokenUrl = process.env.REACT_APP_GOOGLE_TOKEN_URL;

    const body = new URLSearchParams({
      code: authorizationCode,
      client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
      client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
      redirectUri: process.env.REACT_APP_GOOGLE_REDIRECT_URL,
      grant_type: "authorization_code",
    });

    try {
      const response = await fetch(tokenUrl, {
        method: "POST",
        body: body,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });

      const data = await response.json();

      if (response.ok) {
        console.log("Access Token:", data.access_token);
        console.log("ID Token:", data.id_token); // Optional: You can use the id_token to get user info
        return data.access_token; // You can use this token to make authenticated API requests
      } else {
        throw new Error("Failed to exchange code for access token");
      }
    } catch (error) {
      console.error("Error exchanging code for token:", error);
    }
  };

  const fetchGoogleUserInfo = async (accessToken) => {
    const userInfoUrl = process.env.REACT_APP_GOOGLE_USER_INFO_URL;

    try {
      const response = await fetch(userInfoUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const userInfo = await response.json();
      console.log("User Info:", userInfo);
      return userInfo;
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  const handleFacebookLogin = () => {
    window.FB.getLoginStatus((response) => {
      if (response.status === "connected") {
        console.log("User already signed in. Logging out first...");
        window.FB.logout(() => {
          console.log("Logged out. Now showing sign-up popup...");
          openFacebookLogin();
        });
      } else {
        openFacebookLogin();
      }
    });
  };

  const openFacebookLogin = () => {
    window.FB.login(
      function (response) {
        if (response.authResponse) {
          console.log("Sign-up successful! Fetching user data...");
          fetchFacebookUser();
        } else {
          console.log("User cancelled sign-up. Logging out...");
          window.FB.logout(); // 🔥 Logs out to prevent auto-login if user closes popup
        }
      },
      {
        scope: "email,public_profile",
        auth_type: "reauthenticate",
        display: "popup",
      }
    );
  };

  const fetchFacebookUser = () => {
    window.FB.api(
      "/me",
      { fields: "email,first_name,last_name" },
      async function (user) {
        console.log("User data received:", user);

        if (!user?.email) {
          console.error("Unable to retrieve the email. Logging out...");
          window.FB.logout(); // 🔥 Logs out to prevent auto-login with partial data
          setPopup({
            open: true,
            message: "Unable to retrieve the email. Please try again.",
          });
          return;
        }

        const doesEmailExist = await emailValidation(user?.email);
        if (doesEmailExist?.data?.response === "exist") {
          const userDetails = await getUserProfileDetails("Email", user?.email);
          console.log("userDetails: ", userDetails);
          console.log(userDetails?.data?.body?.Data[0]?.UserID);
          localStorage.setItem(
            "user_id",
            userDetails?.data?.body?.Data[0]?.UserID
          );
          navigateToHome();
          // navigate("/home");
        } else {
          setPopup({
            open: true,
            message:
              "No account was found. Please sign up before attempting to log in.",
          });
          return;
        }
      }
    );
  };

  const handleForgotPassword = async () => {
    try {
      await forgotPassword(forgotPasswordPopup.username);
      setForgotPasswordPopup((prev) => ({ ...prev, stage: 2 }));
      setErrorMessage("");
    } catch (error) {
      setErrorMessage("Failed to send verification code.");
    }
  };

  const handleResetPassword = async () => {
    if (
      forgotPasswordPopup.newPassword !== forgotPasswordPopup.confirmPassword
    ) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    try {
      await confirmPassword(
        forgotPasswordPopup.username,
        forgotPasswordPopup.verificationCode,
        forgotPasswordPopup.newPassword
      );
      setShowSuccessModal(true);
      setForgotPasswordPopup({
        open: false,
        stage: 1,
        username: "",
        verificationCode: "",
        newPassword: "",
        confirmPassword: "",
      });
      triggerSnackbar("Password changed successfully.");
    } catch (error) {
      setErrorMessage("Failed to reset password.");
    }
  };

  const renderForgotPasswordStage = () => {
    switch (forgotPasswordPopup.stage) {
      case 1:
        return (
          <div>
            <TextField
              label="Username"
              variant="filled"
              fullWidth
              margin="normal"
              onChange={(e) =>
                setForgotPasswordPopup({
                  ...forgotPasswordPopup,
                  username: e.target.value,
                })
              }
              InputProps={{ style: { color: "white" } }}
              InputLabelProps={{ style: { color: "white" } }}
              sx={{ bgcolor: "rgba(255, 255, 255, 0.1)", borderRadius: 1 }}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleForgotPassword}
              // disabled={loading}
            >
              Send Verification Code
            </Button>
            {/* {loading && <CircularProgress />}
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>} */}
          </div>
        );
      case 2:
        return (
          <div>
            <TextField
              label="Verification Code"
              variant="filled"
              fullWidth
              margin="normal"
              onChange={(e) =>
                setForgotPasswordPopup({
                  ...forgotPasswordPopup,
                  verificationCode: e.target.value,
                })
              }
              InputProps={{ style: { color: "white" } }}
              InputLabelProps={{ style: { color: "white" } }}
              sx={{ bgcolor: "rgba(255, 255, 255, 0.1)", borderRadius: 1 }}
            />
            <TextField
              label="New Password"
              type="password"
              variant="filled"
              fullWidth
              margin="normal"
              onChange={(e) =>
                setForgotPasswordPopup({
                  ...forgotPasswordPopup,
                  newPassword: e.target.value,
                })
              }
              InputProps={{ style: { color: "white" } }}
              InputLabelProps={{ style: { color: "white" } }}
              sx={{ bgcolor: "rgba(255, 255, 255, 0.1)", borderRadius: 1 }}
            />
            <TextField
              label="Confirm New Password"
              type="password"
              variant="filled"
              fullWidth
              margin="normal"
              onChange={(e) =>
                setForgotPasswordPopup({
                  ...forgotPasswordPopup,
                  confirmPassword: e.target.value,
                })
              }
              InputProps={{ style: { color: "white" } }}
              InputLabelProps={{ style: { color: "white" } }}
              sx={{ bgcolor: "rgba(255, 255, 255, 0.1)", borderRadius: 1 }}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleResetPassword}
              // disabled={loading}
            >
              Reset Password
            </Button>
            {/* {errorMessage && <Alert variant="danger">{errorMessage}</Alert>} */}
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      localStorage.setItem("accessToken", code);
      navigate("/home");
    }
  }, [navigate]);

  const signUp = () => {
    navigate("/signup");
  };

  const closePopup = () => {
    setForgotPasswordPopup({
      ...forgotPasswordPopup,
      open: false,
    });
  };

  return (
    <div className="height-100 clr-white">
      <div className="container-fluid">
        <div className="row theme-bg height-100">
          <div className="col-xl-7 col-lg-6">
            <div className="logo-container">
              <img src={logo} alt="Dyrect Logo" className="logo-image" />
              <span className="logo-text">yrect</span>
            </div>
            <div className="welcome-grid height-100">Welcome!</div>
            <div className="ellipse" />
          </div>
          <div className="col-xl-5 col-lg-6" style={{ zIndex: "1000" }}>
            <div className="login-grid m-5">
              {!showTOTP && (
                <>
                  <h2 className="login-title">Login</h2>
                  <p className="login-subtitle">Ready to dive in? Log in now</p>
                  <TextField
                    label="Username"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className="input-label input-field"
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <TextField
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className="input-label input-field"
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOff className="clr-white" />
                            ) : (
                              <Visibility className="clr-white" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        name="remember"
                        color="primary"
                        sx={{
                          color: "#fff",
                          "&.Mui-checked": {
                            color: "#fff",
                          },
                        }}
                      />
                    }
                    label="Remember me"
                    className="remember-me"
                  />
                </>
              )}
              {showTOTP && (
                <>
                  <h2 className="login-title">Authenticator Code</h2>
                  <TextField
                    placeholder="Enter code from email app"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className="input-label input-field"
                    sx={{
                      marginBottom: {
                        xs: "2%",
                        sm: "3%",
                        md: "5%",
                      },
                    }}
                    value={totpCode}
                    onChange={(e) => setTotpCode(e.target.value)}
                    required
                  />
                </>
              )}

              <Button
                variant="contained"
                color="primary"
                fullWidth
                className="login-button"
                onClick={showTOTP ? handleVerifyOTP : handleLogin}
              >
                {showTOTP ? "Verify Code" : "Login"}
              </Button>
              <p
                className="forgot-password pointer links"
                onClick={() =>
                  setForgotPasswordPopup((prev) => ({ ...prev, open: true }))
                }
              >
                <a>Forgot password?</a>
              </p>

              <div className="or-section">
                <span>Or</span>
              </div>

              <div className="social-icons">
                <IconButton
                  aria-label="Google"
                  className="social-icon google"
                  onClick={handleGoogleLogin}
                >
                  <FontAwesomeIcon
                    icon={faGoogle}
                    style={{ color: "#db4437", fontSize: "1.5em" }}
                  />
                </IconButton>
                <IconButton
                  aria-label="Facebook"
                  className="social-icon facebook"
                  onClick={handleFacebookLogin}
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    style={{ color: "#4267B2", fontSize: "1.5em" }}
                  />
                </IconButton>
                <IconButton
                  aria-label="Apple"
                  className="social-icon apple"
                  onClick={() => alert("Apple clicked")}
                >
                  <FontAwesomeIcon
                    icon={faApple}
                    style={{ color: "#ffffff", fontSize: "1.5em" }}
                  />
                </IconButton>
              </div>
              <p className="signup-text">
                Don't have an account?{" "}
                <span className="links" onClick={signUp}>
                  Signup
                </span>
              </p>
              <div className="footer-links">
                <a href="javascript:void(0)">Terms & Conditions</a>{" "}
                <a href="javascript:void(0)">Support</a>{" "}
                <a href="javascript:void(0)">Customer Care</a>
              </div>
            </div>
          </div>
          <div className="bottom-ellipse"></div>
        </div>
        {forgotPasswordPopup.open && (
          <div className="forgot-password-popup-overlay" onClick={closePopup}>
            <div
              className="forgot-password-popup"
              onClick={(e) => e.stopPropagation()}
            >
              <h3>
                {forgotPasswordPopup.stage === 1
                  ? "Forgot Password"
                  : "Reset Password"}
              </h3>
              {renderForgotPasswordStage()}
            </div>
          </div>
        )}

        <ErrorModal
          open={popup.open}
          title={popup.title}
          message={popup.message}
          onClose={handlePopupClose}
        />
      </div>
    </div>
  );
}

export default Login;
