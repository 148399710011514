import { configureStore } from '@reduxjs/toolkit'
import dataSlice from './dataSlice';
import bannerSlice from './bannerSlice';
import logoSlice from './logoSlice';
import logger from 'redux-logger';
import sideBarSlice from './sideBarSlice'
import notificationSlice from './notificationSlice';
import userSlice from './userSlice';
import valueSlice from './valueSlice';

export default configureStore(
  {
    reducer: {
      data: dataSlice,
      banner: bannerSlice,
      logo: logoSlice,
      sidebar: sideBarSlice,
      info: notificationSlice,
      user: userSlice,
      value: valueSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
  })