import React, { useState } from "react";
import SideBar from "../../Components/Sidebar";
import CommunityTop from "../Container/Community/CommunityTop";
import "./Community.css";
import { useDispatch, useSelector } from "react-redux";
import Pageheader from "../Container/PageHeader/Pageheader";
import TopCreators from "./TopCreators";
import Recommendations from "./Recommendations";
import CommunitySearch from "../Container/Community/CommunitySearch";
import ConnectionPage from "../ConnectionPages/ConnectionPage";

const Community = () => {
  const dispatch = useDispatch();
  const info = useSelector((state) => state.info);
  const value = useSelector((state) => state?.value);

  const [viewAll, setViewAll] = useState(false); // ⬅️ Lifted state

  return (
    <>
      <div
        className={`theme-bg clr-white height-100 ${info?.show ? "blur-background" : ""
          }`}
        style={{ display: "flex" }}
      >
        <SideBar />
        <div className="container-fluid overflow-x-none ml-80p height-100" style={{ overflowY: 'auto' }}>
          <div className="row">
            <div className="container" style={{ minHeight: "100vh", color: "#fff" }}>
              <div>
                <Pageheader pageTitle="Community" />
              </div>
              <div className="community_container">
                <CommunityTop />
                <div>
                  {value?.profileSearch ? (
                    <CommunitySearch />
                  ) : (
                    !viewAll && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "16px",
                          width: "100%",
                          height: "100%",
                          transition: "all 0.5s ease", // Smooth transition for the entire block
                          opacity: viewAll ? 0 : 1, // Hide the section completely
                          filter: viewAll ? "blur(5px)" : "none", // Blur effect
                          visibility: viewAll ? "hidden" : "visible", // Completely hide
                        }}
                      >
                        <Recommendations />
                        <TopCreators width="50%" height="50%" />
                      </div>
                    )
                  )}
                  <ConnectionPage
                    viewAll={viewAll}
                    setViewAll={setViewAll}
                    style={{
                      transition: "transform 0.5s ease, opacity 0.5s ease",
                      transform: viewAll ? "translateY(-100px)" : "translateY(0)", // Smooth move to the top
                      opacity: viewAll ? 1 : 0, // Fade-in effect for ConnectionPage
                      position: "relative", // Ensures the element stays within the flow of the page
                      zIndex: viewAll ? 1 : -1, // Ensure ConnectionPage is on top
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Community;
