import React, { useState, useEffect, useRef } from 'react'
import './Profileinfo.css'
import Closeicon from "../../../Assests/X.svg"
import Dollar from "../../../Assests/Dollar Coins.svg"
import Edit from "../../../Assests/edit.svg"
import { useDispatch, useSelector } from 'react-redux'
import { setProfileShow } from '../../../redux/notificationSlice'
import Insta from "../../../Assests/Insta.svg"
import LinkedIn from "../../../Assests/Linkedin.svg"
import Facebook from "../../../Assests/Facebook.svg"
import Spotify from "../../../Assests/Spotify.svg"
import Youtube from "../../../Assests/Youtube.svg"
import Avatar from "../../../Assests/avatar.svg";
import Switch from "../../../Components/switch"
import { Tooltip, Dialog } from "@mui/material";
import { useNavigate } from 'react-router-dom'

const Profileinfo = ({ profileInfoRef }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((state) => state?.user || []);
    const userProfile = user?.[0] || {};
    const { FirstName, LastName,
        Collaborate, connectionCount,
        connectionProfile,
        FacebookLink,
        InstagramLink,
        LinkedinLink,
        SpotifyLink,
        YoutubeLink,
        ProfileImageKey, Bio, Topics } = userProfile;

    const [isOpenToCollaborate, setIsOpenToCollaborate] = useState(Collaborate === 1);

    let topicsArray = [];
    topicsArray = Topics ? JSON.parse(Topics) : [];
    let connections = connectionProfile ? connectionProfile : [];

    useEffect(() => {
        setIsOpenToCollaborate(Collaborate === 1);
    }, [Collaborate]);

    const handleProfileClose = () => dispatch(setProfileShow(false));

    const toggleSwitch = () => {
        // setIsOpenToCollaborate(!isOpenToCollaborate)
    };
    const info = useSelector((state) => state.info)
    return (
        <Dialog open={info?.profileShow}
            sx={{
                "& .MuiDialog-paper": {
                    position: "absolute",
                    top: "0.5%",
                    right: info?.profileShow ? "34%" : "-100%",
                    transition: "right 0.9s ease-in-out",
                },
            }}
        >
            <div className="profile_container" ref={profileInfoRef}>
                <div className="profile_info">
                    <div className="profile_img">
                        <img alt="" src={ProfileImageKey || Avatar} />
                        <div className='profile_close' onClick={handleProfileClose}>
                            <img src={Closeicon} />
                        </div>
                    </div>
                    <div className='profile_data'>
                        <div className='profile_data_top'>
                            <div className='profile_user'>
                                <span className='profile_user_name'>{FirstName || "Guest"} {LastName || ""}</span>
                                <span className='profile_user_role'>Podcast Creater</span>
                            </div>
                            <div className='profile_Edit' onClick={() => navigate('/user-profile')}>
                                <span>Edit</span>
                                <img src={Edit} />
                            </div>

                        </div>
                        <div className='profile_data_bottom'>
                            <div className='profile_data_connection'>
                                <span className='profile_connection_count'>{connectionCount || 0}</span>
                                <span className='profile_text connection'>Connections</span>
                            </div>
                            <div className='profile_user_connections'>
                                {connections.slice(0, 3).map((item, index) => (
                                    <img
                                        key={index}
                                        className={`connectedUser${index + 1}`}
                                        src={item?.ProfileImageKey}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className='profile_data_bottom'>
                            <div className='profile_data_collabaration'>
                                <Switch isOn={isOpenToCollaborate} toggleSwitch={toggleSwitch} />
                                <span className='profile_text'>{isOpenToCollaborate ? "Open" : "Close"} to Collaborate</span>
                            </div>
                            <div className='profile_data_pricing'>
                                <img src={Dollar}></img>
                                <span>$ 300/Ep</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profile_content">
                    <div className="profile_content_title">Bio</div>
                    <div className="profile_text">{Bio}</div>
                </div>
                <div className="profile_content">
                    <div className="profile_content_title">Interests</div>
                    <div className="profile_content_interest">
                        {topicsArray.length > 0 ? (
                            topicsArray.map((item, index) => (
                                <div key={index} className="profile_content_interest_item">
                                    <span>#</span>
                                    <span>{item}</span>
                                </div>
                            ))
                        ) : (
                            <span>No interests listed</span>
                        )}
                    </div>
                </div>
                <div className="profile_content">
                    <div className="profile_content_title">Connect On</div>
                    <div className="profile_content_socials">
                        {
                            FacebookLink && (
                                <Tooltip
                                    title={
                                        <span style={{ fontSize: '10px' }}>
                                            View Facebook Profile
                                        </span>
                                    }
                                >
                                    <div>
                                        <a href={FacebookLink}>
                                            <img className="socialsfacebook-icon" alt="" src={Facebook} />
                                        </a>
                                    </div>
                                </Tooltip>
                            )
                        }
                        {
                            SpotifyLink && (
                                <Tooltip
                                    title={
                                        <span style={{ fontSize: '10px' }}>
                                            View Spotify Profile
                                        </span>
                                    }
                                >
                                    <div>
                                        <a href={SpotifyLink}>
                                            <img className="socialsfacebook-icon" alt="" src={Spotify} />
                                        </a>
                                    </div>
                                </Tooltip>
                            )
                        }
                        {
                            InstagramLink && (
                                <Tooltip
                                    title={
                                        <span style={{ fontSize: '10px' }}>
                                            View Instagram Profile
                                        </span>
                                    }
                                >
                                    <div>
                                        <a href={InstagramLink}>
                                            <img className="socialsfacebook-icon" alt="" src={Insta} />
                                        </a>
                                    </div>
                                </Tooltip>
                            )
                        }
                        {
                            LinkedinLink && (
                                <Tooltip
                                    title={
                                        <span style={{ fontSize: '10px' }}>
                                            View LinkedIn Profile
                                        </span>
                                    }
                                >
                                    <div>
                                        <a href={LinkedinLink}>
                                            <img className="socialsfacebook-icon" alt="" src={LinkedIn} />
                                        </a>
                                    </div>
                                </Tooltip>
                            )
                        }
                        {
                            YoutubeLink && (
                                <Tooltip
                                    title={
                                        <span style={{ fontSize: '10px' }}>
                                            View YouTube Profile
                                        </span>
                                    }
                                >
                                    <div>
                                        <a href={YoutubeLink}>
                                            <img className="socialsfacebook-icon" alt="" src={Youtube} />
                                        </a>
                                    </div>
                                </Tooltip>
                            )
                        }
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default Profileinfo
