import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../Components/Header';
import notificationIcon from '../../../Assests/Group 7.svg'
import notificationActive from "../../../Assests/Notification (4).svg"
import { useDispatch, useSelector } from 'react-redux'
import { setShow, setProfileShow } from '../../../redux/notificationSlice'
import "./Pageheader.css";
import { Dialog, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Profileinfo from '../ProfileInfo/Profileinfo';
import ProfileOverview from '../ProfileInfo/profileOverview';
import useClickOutside from '../../../Hook/useClickOutside';
import { setProfileBarShow } from '../../../redux/notificationSlice';
import NotificationTab from '../Notification/NotificationTab';

const Pageheader = ({ pageTitle, path, nextPage }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const info = useSelector((state) => state.info)
    const [open, setOpen] = useState(info?.show);
    const [active, setActive] = useState(notificationIcon);

    const profileTabRef = useRef(null)
    const headerTabRef = useRef(null)
    const profileInfoRef = useRef(null)
    const notificationTabRef = useRef(null)
    const notificationRef = useRef(null)
    useClickOutside([profileInfoRef, profileTabRef], () => {
        dispatch(setProfileShow(false));
    });
    useClickOutside([profileTabRef, headerTabRef], () => {
        dispatch(setProfileBarShow(false));
    });

    const handleClick = () => {
        if (open) {
            setActive(notificationIcon)
        } else {
            setActive(notificationActive)
        }
        info?.show ? dispatch(setShow(false)) : dispatch(setShow(true));
        setOpen(true)
    }

    useClickOutside([notificationTabRef, notificationRef], () => {
        dispatch(setShow(false));
    });


    return (
        <>
            <div id='page-top'>
                <div>
                    <span className='header-page-title' onClick={() => navigate(path)}>
                        {pageTitle}
                    </span>
                    {nextPage && (
                        <span className='header-page-title'>
                    &nbsp; > &nbsp;{nextPage}
                        </span>
                    )}
                </div>

                <div className='page-icon'>
                    <Tooltip
                        title={
                            <span style={{ fontSize: '10px' }}>
                                Notification Tab
                            </span>
                        }
                    >
                        <div className='notification-bar pointer' ref={notificationRef}>
                            <img className={info?.show ? 'notification-icon_active' : 'notification-icon'} src={info?.show ? notificationActive : notificationIcon} onClick={handleClick}></img>
                            {info?.count > 0 &&
                                <span>{info?.count}</span>
                            }
                        </div>
                    </Tooltip>
                    <Header headerTabRef={headerTabRef} />
                </div>
            </div>
            <div className={`profile-overView ${info?.profileBarShow ? "show" : ""}`}>
                <ProfileOverview profileTabRef={profileTabRef} />
            </div>
            {/* <div className={`profile-window ${info?.profileShow ? "show" : ""}`}> */}
            <Profileinfo profileInfoRef={profileInfoRef} />
            {/* </div> */}
            {/* <div className={`notification-window ${info?.show ? "show" : ""}`}> */}
            {/* <Dialog className={`notification-window ${info?.show ? "show" : ""}`} show={info?.show}> */}
            <NotificationTab notificationTabRef={notificationTabRef} />
            {/* </Dialog> */}
            {/* </div> */}
        </>
    )
}

export default Pageheader
