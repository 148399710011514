import axios from "axios";
const dyrectApi = process.env.REACT_APP_DYRECT_API_URL;

const parsedUrl = new URL(window.location.href);
const dyrectAiBaseUrl = `${parsedUrl.protocol}//${parsedUrl.host}`;

export const getScheduledPodcasts = async (PodcastStatus) => {
    try {
        let path = "podcasts/scheduled";
        path += localStorage.getItem('user_id') ? `?UserID=${localStorage.getItem('user_id')}` : '';
        path += PodcastStatus ? `&PodcastStatus=${PodcastStatus}` : '';
        const response = await axios.get(dyrectApi + path );
        return response;
    } catch (error) {
        return 'Unable to fetch scheduled podcasts';
    }
};

export const getScheduledPodcastsByMeetingId = async (meetingId) => {
    try {
        const path = "schedules";
        const response = await axios.get(dyrectApi + path + `?MeetingID=${meetingId}`);
        return response;
    } catch (error) {
        return 'Unable to fetch scheduled podcasts';
    }
};

export const createPodcast = async (data) => {
    try {
        const path = "podcasts/scheduled";
        const response = await axios.post(dyrectApi + path, data);
        return response;
    } catch (error) {
        return 'Unable to create podcast';
    }
};

export const updatePodcast = async (data) => {
    try {
        const path = "podcasts/scheduled";
        const response = await axios.put(dyrectApi + path, data);
        return response;
    } catch (error) {
        return 'Unable to update scheduled podcast';
    }
};

export const deletePodcast = async (data) => {
    try {
        const path = "podcasts/scheduled";
        const response = await axios.delete(dyrectApi + path + `?ScheduleID=${data}`);
        return response;
    } catch (error) {
        return 'Unable to delete scheduled podcast';
    }
};

export const authorize = async () => {
    const path = 'yt/authentication';
    const data = {
        redirectUri: `${dyrectAiBaseUrl}/yt`
    };
    try {
        const response = await axios.post(dyrectApi + path, data);
        return response;
    } catch (error) {
        return 'Unable to generate auth uri';
    }
};

export const callback = async (code) => {
    const path = 'yt/callback';
    console.log(localStorage);
    const data = {
        code: code,
        redirectUri: `${dyrectAiBaseUrl}/yt`,
    };
    try {
        const response = await axios.post(dyrectApi + path, data);
        return response;
    } catch (error) {
        return 'Unable to create the access token';
    }
};

export const uploadToYT = async (data) => {
    const path = 'uploadToYT';
    try {
        const response = await axios.post(dyrectApi + path, data);
        return response;
    } catch (error) {
        return 'Unable to YT';
    }
};

export const uploadToLI = async (data) => {
    const path = 'uploadToLinkedIn';
    try {
        const response = await axios.post(dyrectApi + path, {
            ...data,
            redirectURI: `${dyrectAiBaseUrl}/li`
        });
        return response;
    } catch (error) {
        console.error('Error uploading to LinkedIn:', error);
        return 'Unable to upload to LinkedIn';
    }
};
export const getLinkedinPost = async (ShowID) => {
    const path = 'getLinkedinPost';
    try {
        const response = await axios.get(dyrectApi + path + `?UserID=${localStorage.getItem('user_id')}&ShowID=${ShowID}`);
        return response;
    } catch (error) {
        return 'Unable to fetch scheduled podcasts';
    }
};

export const getConnectionRequestsByUserId = async (UserID) => {
    try {
        const path = "notifications";
        const response = await axios.get(dyrectApi + path + `?UserID=${UserID}`);
        return response?.data;
    } catch (error) {
        return 'Unable to fetch connection details';
    }
};

export const acceptConnectionRequests = async (data) => {
    try {
        const path = "connections";
        const response = await axios.put(dyrectApi + path, data);
        return response;
    } catch (error) {
        return 'Unable to accept connection Request';
    }
};

export const deleteAllConnectionRequests = async (UserID) => {
    try {
        const path = "notifications";
        const response = await axios.delete(dyrectApi + path + `?UserID=${UserID}`);
        return response;
    } catch (error) {
        return 'Unable to delete scheduled connection details';
    }
};

export const rejectConnectionRequests = async (data) => {
    try {
        const path = "connections";
        const response = await axios.put(dyrectApi + path, data);
        return response;
    } catch (error) {
        return 'Unable to delete scheduled connection details';
    }
};

export const clearNotification = async (NotificationID) => {
    try {
        const path = "notifications";
        const response = await axios.delete(`${dyrectApi}${path}?UserID=${localStorage.getItem('user_id')}&NotificationID=${NotificationID}`);
        return response;
    } catch (error) {
        return 'Unable to delete scheduled connection details';
    }
};