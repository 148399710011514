import axios from "axios";

const URL = "http://localhost:3000/dev/starred";
const GET_CONNECTION_URL = "http://localhost:3000/dev/connections";
const dyrectApi = process.env.REACT_APP_DYRECT_API_URL;

// Fetch connections based on UserID with pagination
export const getConnections = async (UserID, page = 1, limit = 9) => {
  try {
    const response = await axios.get(`${dyrectApi}connections?UserID=${UserID}&page=${page}&limit=${limit}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching connections:", error);
    throw error;
  }
};

// Update the starred status of a connection
export const updateStarredStatus = async (UserID1, UserID2, Starred) => {
  try {
    const response = await axios.put(`${dyrectApi}starred`, {
      UserID1,
      UserID2,
      Starred: Starred === 1 ? true : false,
    });
    console.log("Starred status updated", response);
    return response.data;
  } catch (error) {
    console.error("Error updating starred status:", error);
    throw error;
  }
};
