import { useTheme } from "@mui/material";
import { useMeeting } from "@videosdk.live/react-sdk";
import React, { useEffect, useMemo, useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { appThemes, useMeetingAppContext } from "../../MeetingAppContextDef";
import { meetingLayouts } from "../../MeetingAppContextDef";
import {
  calcQuality,
  getGridForMainParticipants,
  getGridRowsAndColumns,
  localAndPinnedOnTop,
  meetingResolutions,
} from "../../utils/common";
import useIsLGDesktop from "../../utils/useIsLGDesktop";
import useIsMobile from "../../utils/useIsMobile";
import useIsSMDesktop from "../../utils/useIsSMDesktop";
import useIsTab from "../../utils/useIsTab";
import useResponsiveSize from "../../utils/useResponsiveSize";
import { MemoizedMotionParticipant } from "../mainViewContainer/MainViewContainer";
import ParticipantViewer from "../mainViewContainer/ParticipantViewer";
import PresenterView from "../mainViewContainer/PresenterView";
import WhiteboardContainer, {
  convertHWAspectRatio,
} from "../../components/whiteboard/WhiteboardContainer";
import { usePubSub, Constants } from "@videosdk.live/react-sdk";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import MeetingStyles from "../MeetingStyles";
import { setIsLogoDragging, setLogoMargin } from "../../../redux/logoSlice";

const PinnedLayoutViewContainer = ({
  height,
  width,
  whiteboardToolbarWidth,
  whiteboardSpacing,
  left
}) => {
  const {
    meetingLayout,
    sideBarMode,
    whiteboardStarted,
    animationsEnabled,
    reduceEdgeSpacing,
    isRecorder,
    layoutGridSize,
    appTheme,
    meetingResolution,
  } = useMeetingAppContext();

  const logoData = useSelector((state) => state?.logo);
  const sideBarData = useSelector((state) => state?.sidebar);

  const dispatch = useDispatch()
  const location = useLocation();
  const { publish } = usePubSub("UPDATE_LOGO");
  const queryParams = new URLSearchParams(location.search);
  const position = queryParams.get('position') || 'bl';
  const guest = queryParams.get("guest");
  const color = queryParams.get('color') || 'white';

  const mMeeting = useMeeting();
  const localParticipantId = mMeeting?.localParticipant?.id;
  const presenterId = mMeeting?.presenterId;
  const pinnedParticipants = mMeeting?.pinnedParticipants;

  const isMobile = useIsMobile();
  const isTab = useIsTab();
  const isSMDesktop = useIsSMDesktop();
  const isLGDesktop = useIsLGDesktop();
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const mobilePortrait = isMobile && isPortrait;
  const [font, setFont] = useState();

  const {
    spotlightParticipantId,
    sideBarPinnedParticipantIds,
    spotlightMediaType,
    singleRow,
  } = useMemo(() => {
    let pinnedParticipantIds = [...pinnedParticipants.keys()];

    const pinnedPresenter =
      pinnedParticipantIds.findIndex((id) => id === presenterId) !== -1;

    if (isRecorder && pinnedParticipantIds.length > layoutGridSize) {
      if (pinnedPresenter) {
        const pinnedParticipantIndexToBeRemoved =
          pinnedParticipantIds.findIndex((id) => id === presenterId);

        pinnedParticipantIds.splice(pinnedParticipantIndexToBeRemoved, 1);

        pinnedParticipantIds = [pinnedPresenter, ...pinnedParticipantIds];
      }

      pinnedParticipantIds = pinnedParticipantIds.slice(0, layoutGridSize);
    }

    let obj;

    if (pinnedPresenter) {
      obj = {
        spotlightParticipantId: presenterId,
        sideBarPinnedParticipantIds:
          meetingLayout === meetingLayouts.SPOTLIGHT
            ? []
            : pinnedParticipantIds,
        spotlightMediaType: "SHARE",
      };
    } else if (whiteboardStarted) {
      if (meetingLayout === meetingLayouts.SPOTLIGHT) {
        obj = {
          spotlightParticipantId: "white-board-id",
          sideBarPinnedParticipantIds: [],
          spotlightMediaType: "WHITEBOARD",
        };
      } else {
        obj = {
          spotlightParticipantId: "white-board-id",
          sideBarPinnedParticipantIds: pinnedParticipantIds,
          spotlightMediaType: "WHITEBOARD",
        };
      }
    } else {
      if (meetingLayout === meetingLayouts.SPOTLIGHT) {
        obj = {
          spotlightParticipantId: null,
          sideBarPinnedParticipantIds: pinnedParticipantIds,
          spotlightMediaType: null,
        };
      } else {
        const spotlightParticipantId = pinnedParticipantIds[0];
        pinnedParticipantIds.splice(0, 1);

        obj = {
          spotlightParticipantId: spotlightParticipantId,
          sideBarPinnedParticipantIds: pinnedParticipantIds,
          spotlightMediaType: "WEBCAM",
        };
      }
    }

    if (mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR) {
      const splicesActiveParticipants = obj[
        "sideBarPinnedParticipantIds"
      ].splice(0, 4);

      obj["sideBarPinnedParticipantIds"] = splicesActiveParticipants;
    }

    const participantsCount = obj.sideBarPinnedParticipantIds?.length;

    if (participantsCount) {
      const pinnedParticipantsMap = new Map(pinnedParticipants);

      if (obj["spotlightMediaType"] === "WEBCAM") {
        if (obj["spotlightParticipantId"]) {
          pinnedParticipantsMap.delete(obj["spotlightParticipantId"]);
        }
      }

      const gridInfo = getGridRowsAndColumns({
        participantsCount,
        isMobile:
          mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR
            ? false
            : isMobile,
        isTab:
          mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR
            ? false
            : isTab,
        isSMDesktop:
          mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR
            ? false
            : isSMDesktop,
        isLGDesktop:
          mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR
            ? true
            : isLGDesktop,
        isLandscape:
          mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR
            ? true
            : !isPortrait,
        isPresenting:
          mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR
            ? false
            : !!obj.spotlightParticipantId,
      });

      const { singleRow } = getGridForMainParticipants({
        participants: localAndPinnedOnTop({
          localParticipantId,
          participants: [...obj.sideBarPinnedParticipantIds],
          pinnedParticipantIds: [...pinnedParticipantsMap.keys()],
          moveLocalUnpinnedOnTop: false,
        }),
        gridInfo,
      });

      obj["singleRow"] = singleRow;
    } else {
      obj["singleRow"] = [];
    }

    return obj;
  }, [
    mobilePortrait,
    pinnedParticipants,
    meetingLayout,
    presenterId,
    localParticipantId,
    isMobile,
    isTab,
    isSMDesktop,
    isLGDesktop,
    isPortrait,
    whiteboardStarted,
    isRecorder,
    layoutGridSize,
  ]);

  const theme = useTheme();

  const rowSpacing = useResponsiveSize({
    xl: 24,
    lg: 16,
    md: 14,
    sm: 12,
    xs: 8,
  });

  const gutter = 4;
  const spacing = (reduceEdgeSpacing ? 4 : rowSpacing) - gutter;

  const _presentingSideBarWidth = useResponsiveSize({
    xl: 320,
    lg: 280,
    md: 260,
    sm: 240,
    xs: 200,
  });

  const presentingSideBarWidth = useMemo(() => {
    return (whiteboardStarted || presenterId) &&
      meetingLayout === meetingLayouts.SPOTLIGHT
      ? 0
      : _presentingSideBarWidth;
  }, [_presentingSideBarWidth, whiteboardStarted, presenterId, meetingLayout]);

  const _mainContainerHorizontalPadding =
    spotlightParticipantId && singleRow.length !== 0
      ? 0
      : typeof sideBarMode === "string"
        ? 0
        : sideBarPinnedParticipantIds?.length <= 9
          ? isLGDesktop
            ? singleRow.length === 2
              ? 0
              : 140
            : isSMDesktop
              ? singleRow.length === 2
                ? 20
                : 90
              : isTab && !isPortrait
                ? 60
                : 0
          : 0;

  const mainContainerHorizontalPadding = useMemo(() => {
    return reduceEdgeSpacing
      ? 0
      : (whiteboardStarted || presenterId) &&
        meetingLayout === meetingLayouts.SPOTLIGHT
        ? 0
        : _mainContainerHorizontalPadding;
  }, [
    _mainContainerHorizontalPadding,
    whiteboardStarted,
    presenterId,
    meetingLayout,
    reduceEdgeSpacing,
  ]);

  const gridVerticalSpacing = useResponsiveSize({
    xl: 160,
    lg: 90,
    md: 90,
    sm: 60,
    xs: 60,
  });


  const [bannerName, setBannerName] = useState('Default Banner');
  const [bannerId, setBannerId] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState('#00002910');
  const [fontSize, setFontSize] = useState('20px');
  const [logo, setLogo] = useState("");
  const [meetingBackground, setMeetingBackground] = useState("");
  const [meetingOverlay, setMeetingOverlay] = useState("");
  const [bannerTheme, setBannerTheme] = useState(0)

  const [logoPosition, setLogoPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);
  const [logoWidth, setLogoWidth] = useState('10');

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const bannerType = [
    {
      // width: isVisible ? '75%' : '0px',
      transform: isVisible ? 'translateX(0)' : 'translateX(-100%)',
      borderRadius: '5px',
    },
    {
      marginLeft: '10px',
    }, {
      marginLeft: '10px',
      borderRadius: '100px',
    }, {
      width: '75%',
      clipPath: isVisible
        ? 'polygon(0% 100%, 98% 100%, 100% 0%, 0% 0%)'
        : 'polygon(0% 100%, 0% 100%, 0% 0%, 0% 0%)',
      borderTopRightRadius: '10px',
    }
  ]


  usePubSub("UPDATE_BANNER", {
    onMessageReceived: (data) => {
      const { message } = data;
      console.log('message', message);
      setBannerName(message?.message);  // Update bannerName state here
      setBannerId(message?.BannerID)
      setBackgroundColor(message?.message ? message?.backgroundColor : 'transparent');
      setFontSize(message?.fontSize);
      setBannerTheme(message?.bannerStyle ? message?.bannerStyle : 0)
      setFont(message?.font);
      // enqueueSnackbar(`New Banner - ${message}`);
    },
    onOldMessagesReceived: (messages) => {
      // Check if any old message exists, and if so, set it to the state
      if (messages && messages.length > 0) {
        const latestMessage = messages[messages.length - 1].message; // Get the most recent banner message
        setBannerName(latestMessage?.message);  // Update bannerName state
        setBannerId(latestMessage?.BannerID)
        setBackgroundColor(latestMessage?.message ? latestMessage?.backgroundColor : 'transparent'); // Update background color
        setFontSize(latestMessage?.fontSize); // Update font size
        setBannerTheme(latestMessage?.bannerStyle ? latestMessage?.bannerStyle : 0)
      }
    },
  });

  usePubSub("UPDATE_LOGO", {
    onMessageReceived: (data) => {
      const { message } = data;
      console.log('message', message);
      setLogo(message?.LogoUrl);
      setLogoWidth(message?.width);
      if (message.LogoAxisX || message.LogoAxisY) {
        setLogoPosition({ x: message.LogoAxisX, y: message.LogoAxisY });
      }
    },
    onOldMessagesReceived: (messages) => {
      // Check if any old message exists, and if so, set it to the state
      if (messages && messages.length > 0) {
        const latestMessage = messages[messages.length - 1].LogoUrl; // Get the most recent banner message
        setLogo(latestMessage?.LogoUrl);
        setLogoWidth(latestMessage?.width);
      }
    },
  });

  usePubSub("UPDATE_MEETING_BACKGROUND", {
    onMessageReceived: (data) => {
      const { message } = data;
      setMeetingBackground(message?.BackgroundUrl);
    },
    onOldMessagesReceived: (messages) => {
      // Check if any old message exists, and if so, set it to the state
      if (messages && messages.length > 0) {
        const latestMessage = messages[messages.length - 1].message.BackgroundUrl; // Get the most recent banner message
        setMeetingBackground(latestMessage);
      }
    },
  });

  usePubSub("UPDATE_MEETING_OVERLAY", {
    onMessageReceived: (data) => {
      const { message } = data;
      setMeetingOverlay(message?.OverlayUrl);
      console.log(meetingOverlay);
    },
    onOldMessagesReceived: (messages) => {
      // Check if any old message exists, and if so, set it to the state
      if (messages && messages.length > 0) {
        const latestMessage = messages[messages.length - 1].message.OverlayUrl; // Get the most recent banner message
        console.log(latestMessage)
        setMeetingOverlay(latestMessage);
      }
    },
  });
    const audioRef = useRef(null);
      usePubSub("UPDATE_MUSIC", {
          onMessageReceived: (data) => {
            const { action, url } = data;
      
            if (!audioRef.current) {
              audioRef.current = new Audio(url); // Create the audio element if it doesn't exist
            }
      
            const audio = audioRef.current;
      
            if (action === "play" && url) {
              audio.src = url; // Set the audio URL when playing
              audio.play();
            } else if (action === "pause") {
              audio.pause();
            }
          },
      
          onOldMessagesReceived: (messages) => {
            if (messages && messages.length > 0) {
              const { action, url } = messages[messages.length - 1].data;
      
              if (!audioRef.current) {
                audioRef.current = new Audio(url); // Create the audio element if it doesn't exist
              }
      
              const audio = audioRef.current;
      
              if (action === "play" && url) {
                audio.src = url;
                audio.play();
              } else if (action === "pause") {
                audio.pause();
              }
            }
          },
        });

  // const bannerStyles = {
  //   ...bannerType[bannerTheme],
  //   position: 'absolute',
  //   // left: position === 'cc' ? '50%' : '0',
  //   // transform: position === 'cc' ? 'translateX(-50%)' : 'none',
  //   // transition: 'all 0.9s ease-out',
  //   opacity: isVisible ? 1 : 0,
  //   backgroundColor: backgroundColor,
  //   color: color,
  //   fontSize: fontSize,
  //   padding: '10px 10px',
  //   // textAlign: 'center',
  //   zIndex: 1000,
  //   height: 'auto',
  //   minHeight: '45px',
  //   lineHeight: '1.2',
  //   bottom: '0px',
  //   overflow: 'hidden',
  //   wordWrap: 'break-word',
  //   // color: 'White',
  //   paddingLeft: '3%',
  //   paddingRight: '3%',
  //   fontWeight: '600',
  //   left: position === 'cc' ? '50%' : '0',
  //   transform: position === 'cc' ? 'translateX(-50%)' : 'none',
  //   fontFamily: font
  // };

  // const handleMouseDown = (e) => {
  //   e.preventDefault();
  //   const rect = e.currentTarget.getBoundingClientRect();
  //   setDragOffset({
  //     x: e.clientX - rect.left,
  //     y: e.clientY - rect.top
  //   });
  //   setIsDragging(true);
  // };

  const handleMouseUp = () => {
    setIsDragging(false);
    dispatch(setIsLogoDragging(false))
  };


  const handleMouseMove = (e) => {
    if (!logoData?.isLogoDragging) return;

    const container = containerRef.current;
    const { left, top, width, height } = container.getBoundingClientRect();
    const { offsetX, offsetY } = logoData;


    // let logosize = parseFloat(logoWidth) * 16
    let logoSize = logoData?.logoSize
    let logosize = parseFloat(logoSize) * 16

    let newX = Math.max(0, Math.min(e.clientX - left - offsetX, width - logosize));
    let newY = Math.max(0, Math.min(e.clientY - top - offsetY, height - logosize));

    newX = (newX / width) * 100;
    newY = (newY / height) * 100;

    dispatch(setLogoMargin({ logoMarginX: `${newX}%`, logoMarginY: `${newY}%` }))
    publish(
      { LogoUrl: logo, LogoAxisX: `${newX}%`, LogoAxisY: `${newY}%`, visibility: true, width: logoWidth },
      { persist: true }
    );
  };

  return (
    // <div
    //   ref={containerRef}
    //   style={{
    //     height,
    //     width,
    //     backgroundImage: `url(${meetingBackground})`,
    //     backgroundSize: 'cover', // Adjust the image size to cover the entire div
    //     backgroundPosition: 'center', // Center the image
    //     overflow: 'hidden',
    //     transition: `width ${400 * (animationsEnabled ? 1 : 0.5)}ms`,
    //     transitionTimingFunction: 'ease-in-out',
    //     display: 'flex',
    //     flexDirection:
    //       mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR
    //         ? 'column'
    //         : 'row',
    //     position: 'relative',
    //     placeSelf: 'center',
    //     marginLeft: guest ? '0px' : '80px'
    //   }}
    //   onMouseMove={handleMouseMove}
    //   onMouseUp={handleMouseUp}>
    <>
      <div
        style={{
          position: 'absolute', // Absolutely position the background image
          top: '0',
          left: '0',
          // right: guest? '0px': '80px',
          width: '100%',
          height: '100%', // Ensure the div fills its container
          backgroundImage: `url(${meetingOverlay})`,
          backgroundSize: '100% 100%', // Ensures it covers the entire div
          backgroundPosition: 'center', // Centers the image
          backgroundRepeat: 'no-repeat', // Prevents tiling
          zIndex: 1000, // Ensure it's below other elements
        }}
      ></div>
<div style={{
      height: '100%',
      width: '100%',
      display: "flex", // Add this to create a row layout
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row", // Make sure items align horizontally
      padding: meetingOverlay && '5% 0%',
      }}>
      {mMeeting?.pinnedParticipants.size > 0 &&(
        <>
          <div
            style={{
              width: spotlightParticipantId
                ? sideBarPinnedParticipantIds?.length > 0
                  ? width - presentingSideBarWidth - 100
                  : width
                : 0,
              // height: mobilePortrait && !presenterId ? height / 2 : height,
              height: '100%',
              transition: `width ${800 * (animationsEnabled ? 1 : 0.5)}ms`,
              transitionTimingFunction: "ease-in-out",
              // paddingTop:spacing,
              // paddingLeft:spacing,
              paddingLeft: spotlightMediaType === "SHARE" || spotlightMediaType === "WHITEBOARD"
                ? spacing
                : 0,
              paddingTop: spotlightMediaType === "SHARE" || spotlightMediaType === "WHITEBOARD"
              ? spacing
              : 0,
              display: "flex"
            }}
          >
            <div
              style={{
                // height:
                //   mobilePortrait && !presenterId
                //     ? height / 2 - 2 * spacing
                //     : height - 2 * spacing,
                // height:
                //   mobilePortrait && !presenterId ? height / 2 - 2 * spacing: height,
                width: mobilePortrait
                  ? width - 2 * spacing
                  : (spotlightParticipantId
                    ? (sideBarPinnedParticipantIds?.length > 0
                      ? width - presentingSideBarWidth
                      : width) -
                    2 * spacing
                    : 0) -
                  (singleRow.length === 0
                    ? (2 * mainContainerHorizontalPadding) + (sideBarData.type === true ? spacing * 9 : -spacing * 2)
                    : 0),
                transition: `width ${800 * (animationsEnabled ? 1 : 0.5)}ms`,
                transitionTimingFunction: "ease-in-out",
                borderRadius: theme.spacing(1),
                overflow: "hidden",
                position: "relative",
                flex: '1 1 0px',
                // marginLeft:
                //   singleRow.length === 0 ? mainContainerHorizontalPadding : 0,
                // marginRight:
                //   singleRow.length === 0 ? mainContainerHorizontalPadding : 0,
                // padding: '1%'
              }}
            >
              {spotlightParticipantId ? (
                spotlightMediaType === "SHARE" ? (
                  <PresenterView
                    key={`spotlightParticipantId_${spotlightParticipantId}`}
                    presenterId={spotlightParticipantId}
                  />
                ) : spotlightMediaType === "WHITEBOARD" ? (
                  <WhiteboardContainer
                    {...{
                      ...convertHWAspectRatio({
                        height:
                          height -
                          2 * spacing -
                          (whiteboardToolbarWidth === 0 ? 2 * 16 : 0),
                        width: whiteboardStarted
                          ? width -
                          (isMobile ? 0 : presentingSideBarWidth) -
                          2 * spacing -
                          (whiteboardToolbarWidth + 2 * whiteboardSpacing) -
                          (whiteboardToolbarWidth === 0 ? 2 * 16 : 0)
                          : 0,
                      }),
                      whiteboardToolbarWidth,
                      whiteboardSpacing,
                      originalHeight: height - 2 * spacing,
                      originalWidth:
                        width -
                        (isMobile ? 0 : presentingSideBarWidth) -
                        2 * spacing,
                    }}
                  />
                ) : (
                  <div
                  style={{
                    position: "relative",
                    transition: `height ${800 * (animationsEnabled ? 1 : 0.5)}ms`,
                    transitionTimingFunction: "ease-in-out",
                    height: '100%',
                    width: meetingLayout === 'UNPINNED_SIDEBAR' && singleRow.length > 0 ? '80%' : '100%',
                  }}
                >
                  <MemoizedMotionParticipant
                    {...{
                      participantId: spotlightParticipantId,
                      gutter,
                      quality: meetingResolution
                        ? meetingResolution === meetingResolutions.SD
                          ? "s1t2"
                          : meetingResolution === meetingResolutions.HD && "s2t2"
                        : "s2t2",
                      relativeHeight: 100,
                      relativeWidth: 100,
                      relativeTop: 0,
                      relativeLeft: 0,
                    }}
                    key={`spotlightParticipantId_${spotlightParticipantId}`}
                  />
                  </div>
                )
              ) : null}
            </div>
          </div>

          {
            singleRow.length === 0 ||
              (mobilePortrait && presenterId) ? null : mobilePortrait &&
                meetingLayout === meetingLayouts.SIDEBAR ? (
              <div
                style={{
                  height: "50%",
                  width: "100%",
                  display: "flex",
                  position: "relative",
                }}
              >
                {singleRow.map(
                  ({
                    participantId,
                    relativeHeight,
                    relativeWidth,
                    relativeTop,
                    relativeLeft,
                  }) => {
                    return (
                      <div
                        style={{
                          padding: 8,
                          position: "absolute",
                          top: `${relativeTop}%`,
                          left: `${relativeLeft}%`,
                          width: `${relativeWidth}%`,
                          height: `${relativeHeight}%`,
                        }}
                        id={`pinned_sidebar_${participantId}`}
                        key={`pinned_sidebar_${participantId}`}
                      >
                        <div
                          style={{
                            height: `100%`,
                            width: `100%`,
                          }}
                        >
                          <ParticipantViewer
                            participantId={participantId}
                            quality={"low"}
                          />
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            ) : (
              <div
                style={{
                  // backgroundColor:
                  //   appTheme === appThemes.DARK
                  //     ? theme.palette.darkTheme.main
                  //     : appTheme === appThemes.LIGHT
                  //       ? theme.palette.lightTheme.main
                  //       : theme.palette.background.default,
                  // background: 'red',
                  // overflowX: "hidden",
                  overflowY: spotlightParticipantId ? "scroll" : "hidden",
                  // width: spotlightParticipantId ? presentingSideBarWidth : width,
                  height: '100%',
                  width: '100%',
                  // margin: spacing,
                  transition: `all ${800 * (animationsEnabled ? 1 : 0.5)}ms`,
                  transitionTimingFunction: "ease-in-out",
                  // paddingLeft: mainContainerHorizontalPadding,
                  // paddingRight: mainContainerHorizontalPadding,
                  // paddingTop:
                  //   singleRow.length === 2 && !spotlightParticipantId && !isMobile
                  //     ? gridVerticalSpacing
                  //     : 0,
                  // paddingTop: '1%',
                  // marginRight: guest ? '0px': '80px'
                  // padding: meetingOverlay && meetingLayout === meetingLayouts.SIDEBAR ? '4% 0%': '0%'
                }}
              >
                <div
                  style={{
                    // height: meetingLayout === meetingLayouts.SIDEBAR ?
                    //   (spotlightParticipantId
                    //     ? (singleRow.length * presentingSideBarWidth *2) / 4
                    //     : height - 2 * spacing) -
                    //   (singleRow.length === 2 && !spotlightParticipantId && !isMobile
                    //     ? 2 * gridVerticalSpacing
                    //     : 0) : '100%',
                    // height: '100%',
                    height: meetingLayout === meetingLayouts.SIDEBAR ?
                      (spotlightParticipantId
                        ? (meetingOverlay ? ( singleRow.length * presentingSideBarWidth * 2) / 4 : (singleRow.length * presentingSideBarWidth * 2) / 3)
                        : height - 2 * spacing) -
                      (singleRow.length === 2 && !spotlightParticipantId && !isMobile
                        ? 2 * gridVerticalSpacing
                        : 0) : '100%',
                    position: "relative",
                    transition: `height ${800 * (animationsEnabled ? 1 : 0.5)}ms`,
                    transitionTimingFunction: "ease-in-out",
                    zIndex: meetingOverlay ? "900" : "1000",
                  }}
                >
                  {((meetingLayout === meetingLayouts.SIDEBAR && meetingOverlay) ? singleRow.slice(0, 4) : singleRow).map((c) => (
                  // {( singleRow).map((c) => (
                    <MemoizedMotionParticipant
                      {...c}
                      quality={
                        meetingResolution
                          ? meetingResolution === meetingResolutions.SD
                            ? "s1t2"
                            : meetingResolution === meetingResolutions.HD && "s2t2"
                          : calcQuality(singleRow?.length)
                      }
                      key={`pinned_${c.participantId}`}
                      gutter={gutter}
                      useVisibilitySensor={presenterId ? true : false}
                    />
                  ))}
                </div>
              </div>
            )
          }
        </>
      )}
      </div>
    </>
    // </div >
  );
};

export default React.memo(
  PinnedLayoutViewContainer,
  (prevProps, nextProps) =>
    prevProps.width === nextProps.width && prevProps.height === nextProps.height
);
