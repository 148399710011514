import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getScheduledPodcastsByMeetingId } from "../Services/SchedulesApi";
import { useLocation } from "react-router-dom";
import { commonConstants } from "../utils/constant";

const AIAssistantPanel = () => {

    const [schedule, setSchedule] = useState();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const meetingId = queryParams.get("meetingId");

    useEffect(() => {
        const fetchSchedule = async () => {
            try {
                // Fetch podcast details
                const response = await getScheduledPodcastsByMeetingId(meetingId); // API call to fetch podcasts
                setSchedule(response?.data?.Data[0]); // Update podcasts data

            } catch (error) {
                console.error("Error fetching podcasts:", error); // Error handling
            } finally {
                // setLoading(false); // End loading after the fetch is complete
            }
        };

        fetchSchedule();
    }, []);

    return (
        <Box color='white' className="px-3">
            <div className="mt-3">
                <Accordion
                    sx={{
                        backgroundColor: '#ffffff10',
                        color: 'white',
                        borderRadius: '10px',
                        borderTopLeftRadius: '10px !important',
                        borderTopRightRadius: '10px !important'
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                    >
                        Agenda
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{ maxHeight: '40vh', overflowY: 'auto' }}>
                            {schedule?.Agenda ? (
                                <div dangerouslySetInnerHTML={{ __html: schedule?.Agenda }} />
                                // schedule?.Agenda
                            ) : (
                                <div>
                                    {commonConstants.NO_AGENDA}
                                </div>
                            )}
                        </div>
                    </AccordionDetails>
                </Accordion>
                <div className="bannerData pointer mt-3">
                    <Typography variant="h8">AI Suggestions</Typography>
                    <br />
                    Under development - Coming soon
                </div>
                <div className="bannerData pointer">
                    <Typography variant="h8">AI Tracking</Typography>
                    <br />
                    Under development - Coming soon
                </div>
            </div>
        </Box>
    );
};

export default AIAssistantPanel;
