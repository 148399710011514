import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: null,
  reducers: {
    setData: (state, action) => {
      return { ...state, ...action.payload }
    },
    removeUserData: () => {
      return null
    }
  },
})

export const { setData, removeUserData } = userSlice.actions

export default userSlice.reducer