import { createSlice } from '@reduxjs/toolkit'

const initialState = { item_id: [] };

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setDataValue: (state, action) => {
      return { ...state, ...action.payload }
    },
    addData: (state, action) => {
      state.item_id = [...state.item_id, action.payload]
    },
    removeData: (state, action) => {
      state.item_id = state.item_id.filter(
        (item) => item.participantId !== action.payload.participantId
      );
    },
    clearData: () => initialState,
  },
})

export const { setDataValue, addData, removeData, clearData } = dataSlice.actions

export default dataSlice.reducer