import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    logoSize: 10,
    logoFile: null,
    isLogoDragging: false,
    offsetX: 0,
    offsetY: 0,
    logoMarginX: 0,
    logoMarginY:0
}

const logoSlice = createSlice({
    name: 'logo',
    initialState,
    reducers: {
        setLogoFile(state, action) {
            state.logoFile = action.payload;
        },
        setLogoSize(state, action) {
            state.logoSize = action.width;
        },
        setLogoOffset(state,action){
            state.offsetX = action.payload.offsetX;
            state.offsetY = action.payload.offsetY;
        },
        setIsLogoDragging(state, action) {
            state.isLogoDragging = action.payload;
        },
        setLogoMargin(state,action){
            state.logoMarginX = action.payload.logoMarginX;
            state.logoMarginY = action.payload.logoMarginY;
        },
    }
})
export const { setLogoFile, setLogoSize,setLogoOffset,setIsLogoDragging,setLogoMargin } = logoSlice.actions;
export default logoSlice.reducer;