import React, { useState } from 'react';
import Star from "../../../Assests/star.svg";
import Dollar from "../../../Assests/Dollar Coins.svg";
import messageIcon from "../../../Assests/cardMsg.svg";
import Connect from "../../../Assests/Light.svg";
import Avatar from "../../../Assests/avatar.svg";
import Pending from "../../../Assests/pending.svg";
import { useSelector } from 'react-redux';


const CommunityProfileCard = ({
    userConnection,
    creater,
    handleClickViewProfile,
    handleConnectionClick
}) => {

    const value = useSelector((state) => state?.value)
    let filterValue = value ? value?.filterValue : "";

    const {
        UserID,
        FirstName, LastName,
        Collaborate,
        ProfileImageKey,
        Bio,
        Rate,
        ConnectionStatus,
        Topics } = creater;

    const isOpenToCollaborate = Boolean(Collaborate)

    let topicsArray = [];
    topicsArray = Topics ? JSON.parse(Topics) : [];

    return (
        <div className='profilecard_container'>
            <div className='profilecard_container_top'>
                <div className='profilecard_header'>
                    <div className='profilecard_user_img'>
                        <img src={ProfileImageKey || Avatar} />
                    </div>
                    <div className='profilecard_user_info'>
                        <div className='profilecard_user_name ellipsis'>
                            <span >{FirstName} {LastName}</span>
                            {
                                userConnection && <img src={Star} />
                            }
                        </div>
                        <span className="profilecard_user_role">Podcast Creater</span>
                    </div>
                </div>
                <div className='profilecard_user_status'>
                    <div className='profilecard_collab_status'>
                        <div
                            className={isOpenToCollaborate ?
                                'profilecard_collab_open_status' : 'profilecard_collab_close_status'
                            }>
                        </div>
                        <span>
                            {isOpenToCollaborate ? "Let's Collaborate!" : "Not Now"}
                        </span>
                    </div>
                    <div className='profilecard_rate_status'>
                        <img src={Dollar} />
                        <div>
                            <span>$</span>
                            <span>{Rate ? Rate : '-'}</span>
                            <span>/Ep</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='profilecard_container_mid'>
                {
                    userConnection &&
                    <div className='profilecard_container_mid_bio'>
                        <span>{Bio}</span>
                    </div>
                }
                <div className='profilecard_container_mid_topic'>
                    <span className='profilecard_container_mid_title'>
                        Interests
                    </span>
                    <div className='profilecard_mid_topics'>
                        {
                            topicsArray?.map((item) => (
                                <div className={`profilecard_mid_topics_item ${(item.toLowerCase()).includes(filterValue.toLowerCase()) && !userConnection ? "searched_topic" : ""}`}>
                                    <span>#</span>
                                    <span>{item}</span>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className='profilecard_container_bottom'>
                <button className="profilecard_container_profile_bottom"
                    onClick={() => handleClickViewProfile(UserID)}>
                    <span>View Profile</span>
                </button>

                <button className={`profilecard_container_message_bottom ${ConnectionStatus !== "NOT SENT" ? "pending_connected_button" : ""}`}
                    onClick={() => handleConnectionClick(UserID)}
                    disabled={ConnectionStatus == "PENDING"}
                >
                    <span>
                        {userConnection
                            ? "Message"
                            : ConnectionStatus === "NOT SENT"
                                ? "Connect"
                                : ConnectionStatus === "PENDING"
                                    ? "Pending"
                                    : "Connected"}
                    </span>
                    {
                        ConnectionStatus !== "ACCEPTED" &&
                        <img src={userConnection ? messageIcon : (ConnectionStatus == "PENDING" ? Pending : Connect)} />
                    }

                </button>

            </div>
        </div>
    )
}

export default CommunityProfileCard
