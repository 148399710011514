export const InterestTopics = [
    "Technology",
    "True Crime",
    "Comedy",
    "Health",
    "Politics",
    "Education",
    "Current Affairs",
    "Art & Design",
    "Music",
    "Film & TV",
    "Gaming",
    "Travel",
    "Food",
    "AI & Machine Learning",
    "Coding",
    "Startups",
    "Web3",
    "Cybersecurity",
    "UX/UI Design",
    "AI",
    "Machine Learning",
    "Deep Learning",
    "Neural Networks",
    "Natural Language Processing",
    "Computer Vision",
    "Data Science",
    "Big Data",
    "Cloud Computing",
    "Blockchain",
    "Cryptocurrency",
    "NFTs",
    "Metaverse",
    "Virtual Reality",
    "Augmented Reality",
    "Mixed Reality",
    "Quantum Computing",
    "DevOps",
    "Software Development",
    "Programming",
    "Full-Stack Development",
    "Frontend Development",
    "Backend Development",
    "Mobile App Development",
    "Game Development",
    "Cybersecurity Threats",
    "Penetration Testing",
    "IoT (Internet of Things)",
    "5G Technology",
    "Edge Computing",
    "Tech Startups",
    "Tech Gadgets",
    "Wearable Tech",
    "Open Source Projects",
    "Automation",
    "AI Ethics",
    "Bioinformatics",
    "Genetic Engineering",
    "Human Augmentation",
    "Digital Twins",
    "Tech Reviews",
    "True Crime & Mystery",
    "Serial Killers",
    "Unsolved Mysteries",
    "Forensic Science",
    "Cold Cases",
    "Criminal Psychology",
    "Crime Scene Investigation",
    "Fraud & Scams",
    "Organized Crime",
    "Mafia Stories",
    "Mobsters",
    "Famous Heists",
    "Dark Web Crimes",
    "Courtroom Dramas",
    "Wrongful Convictions",
    "Police Investigations",
    "Corruption Cases",
    "Conspiracy Theories",
    "Cult Crimes",
    "Missing Persons Cases",
    "Undercover Operations",
    "Psychological Thrillers",
    "Kidnapping Cases",
    "Famous Prisons",
    "Forensic Technology",
    "Legal Battles",
    "Comedy & Entertainment",
    "Stand-up Comedy",
    "Improv Comedy",
    "Satirical News",
    "Late Night Shows",
    "Dark Humor",
    "Sketch Comedy",
    "Parody",
    "Comedy Podcasts",
    "Internet Humor",
    "Memes",
    "Funny Stories",
    "Slapstick Comedy",
    "Sarcasm & Wit",
    "Roast Battles",
    "Pranks & Reactions",
    "Comedic TV Shows",
    "Comedic Movies",
    "Online Troll Culture",
    "Mockumentaries",
    "Health & Wellness",
    "Mental Health",
    "Physical Fitness",
    "Nutrition",
    "Yoga",
    "Meditation",
    "Self-Care",
    "Alternative Medicine",
    "Holistic Healing",
    "Wellness Trends",
    "Personal Training",
    "Gut Health",
    "Healthy Diets",
    "Weight Loss Strategies",
    "Strength Training",
    "Vegan & Plant-Based Diets",
    "Biohacking",
    "Herbal Remedies",
    "Immune System Boosting",
    "Sleep Science",
    "Mindfulness Practices",
    "Exercise Science",
    "Sports Medicine",
    "Brain Health",
    "Politics & Society",
    "Elections",
    "Political Debates",
    "Government Policies",
    "Social Justice",
    "Freedom of Speech",
    "Civil Rights",
    "Public Policy",
    "Diplomacy",
    "Geopolitics",
    "International Relations",
    "Whistleblowers",
    "Censorship",
    "Lawmaking",
    "Public Opinion",
    "War & Peace",
    "Economic Policies",
    "Campaign Strategies",
    "Protests & Movements",
    "Political Corruption",
    "Education & Learning",
    "Self-Improvement",
    "Public Speaking",
    "Critical Thinking",
    "Problem-Solving",
    "Financial Literacy",
    "Philosophy",
    "History",
    "Psychology",
    "Linguistics",
    "Personal Development",
    "Online Learning",
    "STEM Education",
    "Teaching Strategies",
    "Memory Improvement",
    "Study Hacks",
    "Cognitive Science",
    "Leadership Skills",
    "Journaling",
    "Current Affairs & News",
    "Breaking News",
    "World Events",
    "Climate Change",
    "Space Exploration",
    "Economic Trends",
    "Diplomatic Relations",
    "Humanitarian Crises",
    "Stock Market News",
    "Science & Innovation",
    "Global Conflicts",
    "Art & Design",
    "Graphic Design",
    "Interior Design",
    "Fashion Design",
    "Illustration",
    "Typography",
    "Calligraphy",
    "Photography",
    "Abstract Art",
    "Digital Art",
    "Architecture",
    "Street Art",
    "Comic Art",
    "Color Theory",
    "Sculpting",
    "3D Animation",
    "Concept Art",
    "Creative Writing",
    "Music & Performing Arts",
    "Hip-Hop",
    "Jazz",
    "Classical Music",
    "Electronic Dance Music",
    "Rock Music",
    "Music Festivals",
    "Indie Music",
    "Songwriting",
    "Music Production",
    "Sound Engineering",
    "Movie Soundtracks",
    "Opera",
    "Folk Music",
    "Reggae",
    "Instrumental Music",
    "Voice Acting",
    "Film & TV",
    "Hollywood",
    "Netflix Shows",
    "Movie Reviews",
    "TV Show Analysis",
    "Classic Cinema",
    "Sci-Fi Films",
    "Horror Movies",
    "Independent Films",
    "Anime & Manga",
    "Screenwriting",
    "Directing",
    "Bollywood",
    "Streaming Services",
    "Gaming & eSports",
    "Video Games",
    "Game Reviews",
    "Gaming News",
    "Retro Gaming",
    "Twitch Streaming",
    "Game Design",
    "Esports Tournaments",
    "Role-Playing Games",
    "Multiplayer Games",
    "VR Gaming",
    "Board Games",
    "Battle Royale Games",
    "Mobile Games",
    "Speedrunning",
    "Modding",
    "Travel & Adventure",
    "Backpacking",
    "Solo Travel",
    "Luxury Travel",
    "Budget Travel",
    "Travel Hacks",
    "Hidden Destinations",
    "Cultural Immersion",
    "Road Trips",
    "National Parks",
    "Adventure Travel",
    "Eco-Tourism",
    "Travel Photography",
    "Digital Nomad Lifestyle",
    "Travel Vlogging",
    "Food & Cooking",
    "Vegan Recipes",
    "International Cuisine",
    "Street Food",
    "Home Cooking",
    "Baking",
    "Healthy Recipes",
    "Food Science",
    "Fine Dining",
    "Food Photography",
    "Meal Prep",
    "Michelin-Star Restaurants",
    "BBQ & Grilling",
    "Business & Finance",
    "Entrepreneurship",
    "Investing",
    "Stock Market",
    "Real Estate",
    "Personal Finance",
    "Side Hustles",
    "Financial Independence",
    "E-commerce",
    "Marketing Strategies",
    "Passive Income",
    "Wealth Management",
    "Time Management",
    "Goal Setting",
    "Self-Discipline",
    "Confidence Building",
    "Mindset Training",
    "Habit Formation",
    "Visualization",
    "Success Stories",
    "Science & Nature",
    "Astronomy",
    "Physics",
    "Chemistry",
    "Biology",
    "Neuroscience",
    "Environmental Science",
    "Astrobiology",
    "Renewable Energy",
    "Green Technology",
    "Wildlife Conservation",
    "Ocean Exploration",
    "Astrophysics",
    "Meteorology",
    "Sustainable Living",
    "Books & Literature",
    "Classic Literature",
    "Sci-Fi Books",
    "Fantasy Novels",
    "Historical Fiction",
    "Mystery & Thriller Books",
    "Self-Help Books",
    "Biography & Memoirs",
    "Poetry",
    "Book Reviews"
];