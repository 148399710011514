import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SideBar from '../../Components/Sidebar'
import Pageheader from "../Container/PageHeader/Pageheader"
import "./Profile.css"
import LeftArrow from "../../Assests/Chevron.svg"
import Tick from "../../Assests/Tick.svg"
import xIcon from "../../Assests/X.svg"
import editIcon from "../../Assests/edit.svg"
import Inputbox from '../../Components/Inputbox'
import Switch from "../../Components/switch"
import SearchBar from "../../Components/Searchbar";
import Insta from "../../Assests/Insta.svg"
import LinkedIn from "../../Assests/Linkedin.svg"
import Facebook from "../../Assests/Facebook.svg"
import Spotify from "../../Assests/Spotify.svg"
import Youtube from "../../Assests/Youtube.svg"
import Link from "../../Assests/Link.svg"
import { InterestTopics } from "../../Constants/InterestTopics"
import { setDataValue, clearData } from '../../redux/dataSlice'
import LinkDialog from '../../Components/linkDialog'
import { information, getUserProfileDetails } from '../../Services/ShowsApi'
import { useNavigate } from 'react-router-dom'
import { API_STATUS } from '../../utils/constant'
import { setData } from '../../redux/userSlice'
import { useLoader } from '../../Components/LoaderContext'

const Profile = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tempData = useSelector((state) => state.data);
    const { triggerLoader, hideLoader } = useLoader();

    const handleSave = async () => {
        const { item_id, ...payload } = tempData;
        triggerLoader("Updating profile...");
        let res = await information(payload);
        if (res?.status == API_STATUS.success) {
            const { data } = await getUserProfileDetails("UserID");
            let { Data } = data?.body
            dispatch(setData(Data))
            navigate(-1);
            hideLoader();
        }
    }

    const handleCancel = () => {
        navigate(-1);
    }

    return (
        <>
            <div className='theme-bg clr-white height-100' style={({ display: "flex" })}>
                <SideBar></SideBar>
                <div className='container-fluid overflow-x-none ml-80p'>
                    <div className='row'>
                        <div className="container" style={{ minHeight: "100vh", color: "#fff" }}>
                            <div>
                                <Pageheader pageTitle="Your Profile" />
                            </div>
                            <div className='profilepage_container'>
                                <div className='profilepage_container_edit'>
                                    <div className='profilepage_container_editTitle'>
                                        <img src={LeftArrow} onClick={handleCancel} />
                                        <span>Editing</span>
                                    </div>
                                    <div className='profilepage_container_editButton'>
                                        <div className='profilepage_container_cancelButton' onClick={handleCancel}>
                                            <span>Cancel</span>
                                            <img src={xIcon} />
                                        </div>
                                        <div className='profilepage_container_saveButton' onClick={handleSave}>
                                            <span>Save</span>
                                            <img src={Tick} />
                                        </div>
                                    </div>

                                </div>
                                <div className='profilepage_container_main'>
                                    <div className='profilepage_container_info'>
                                        <UserInfo />
                                        <UserBio InterestTopics={InterestTopics} />
                                        <UserSocial />
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>

    )
}

const UserInfo = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.user || []);
    const userProfile = user?.[0] || {};
    const {
        UserID,
        FirstName, LastName,
        Collaborate, connectionCount,
        connectionProfile,
        FacebookLink,
        InstagramLink,
        LinkedinLink,
        SpotifyLink,
        YoutubeLink,
        ProfileImageKey,
        Bio,
        Topics,
        Rate } = userProfile;

    const [isOpenToCollaborate, setIsOpenToCollaborate] = useState(Collaborate === 1);
    const [userRole, setUserRole] = useState("Podcast Creater")
    const [userName, setUserName] = useState(`${FirstName} ${LastName}` || "")
    const [image, setImage] = useState(ProfileImageKey || null);
    const [rate, setRate] = useState(Rate || "00");

    const handleUserNameChange = (value) => {
        setUserName(value);
        const parts = value.split(" ");
        dispatch(setDataValue({
            FirstName: parts[0] || FirstName,
            LastName: parts.slice(1).join(" ") || LastName
        }));
    };

    const handleUserRoleChange = (value) => {
        setUserRole(value)
    }

    const toggleSwitch = async () => {
        dispatch(setDataValue({ Collaborate: !isOpenToCollaborate }));
        setIsOpenToCollaborate(!isOpenToCollaborate)
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            let prefix = "data:image/png;base64,";

            reader.onloadend = () => {
                setImage(reader.result);
                // dispatch(setDataValue({ ProfileImage: reader.result }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRateChange = (value) => {
        if (!isNaN(value)) {
            setRate(value);
            dispatch(setDataValue({ Rate: value }));
        }
    }

    useEffect(() => {

        let prefix = "data:image/png;base64,";
        if (image?.startsWith("data:image/jpeg")) {
            prefix = "data:image/jpeg;base64,";
        }
        const base64 = image?.substring(prefix.length);

        if (base64) {
            dispatch(setDataValue({ ProfileImage: base64 }));
        }
    }, [image]);

    useEffect(() => {
        dispatch(clearData());
        dispatch(setDataValue({ UserID: UserID }));
    }, [])


    return (
        <div className='userinfo_container'>
            <div className='userinfo_img_container'>
                <div className='userinfo_profileImage_container'>
                    <label htmlFor="profile-upload" className="profileimg_edit">
                        <img src={image} />
                        <div className='userinfo_profileImage_edit'>
                            <img src={editIcon} />
                        </div>
                    </label>
                    <input
                        type="file"
                        id="profile-upload"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: "none" }} // Hide default file input
                    />
                </div>
            </div>
            <div className='userinfo_data_container'>
                <div className='userinfo_data_containerTop'>
                    <div className='userinfo_data_containerTop_edit'>
                        <Inputbox
                            label="Full Name"
                            value={userName}
                            onChange={(e) => handleUserNameChange(e.target.value)}
                        />
                    </div>
                    <div className='userinfo_data_containerTop_edit'>
                        <Inputbox
                            label="Podcaster Tag"
                            value={userRole}
                            onChange={(e) => handleUserRoleChange(e.target.value)}
                        />
                    </div>
                </div>
                <div className='userinfo_data_containerBottom'>
                    <div className='userinfo_data_containerBottom_left'>
                        <div className='userinfo_data_containerBottom_left_top'>
                            <span className='user_profileinfo_title'>Thinking about switching up how you collaborate?</span>
                            <span className='user_profileinfo_subtitle'>Change this preference anytime to show how you like to collaborate!</span>
                        </div>
                        <div className='userinfo_data_containerBottom_collab'>
                            <Switch isOn={isOpenToCollaborate} toggleSwitch={toggleSwitch} />
                            <span>{isOpenToCollaborate ? "Open" : "Close"} to Collaborate</span>
                        </div>
                    </div>
                    <div className='userinfo_data_containerBottom_right'>
                        <div className='userinfo_data_containerBottom_right_top'>
                            <span className='user_profileinfo_title'>Change your collaboration rate</span>
                            <span className='user_profileinfo_subtitle'>Feel free to adjust the collaboration rate anytime!</span>
                        </div>
                        <div className='userinfo_data_containerBottom_rate'>
                            <span>Hourly rate</span>
                            <div className='userinfo_data_containerBottom_rate_data'>
                                <span>$</span>
                                <div className='userinfo_data_containerBottom_rate_value'>
                                    <div >
                                        <input value={rate} onChange={(e) => handleRateChange(e.target.value)} />
                                    </div>
                                    <span>/Hr</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const UserBio = ({ InterestTopics }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.user || []);
    const userProfile = user?.[0] || {};
    console.log("userProfileTest", userProfile)
    const {
        UserID,
        FirstName, LastName,
        Collaborate, connectionCount,
        connectionProfile,
        FacebookLink = "",
        InstagramLink,
        LinkedinLink,
        SpotifyLink,
        YoutubeLink,
        ProfileImageKey,
        Bio,
        Topics } = userProfile;
    const parsedTopics = useMemo(() => {
        try {
            if (Array.isArray(Topics)) {
                return Topics;
            }
            if (typeof Topics === 'string') {
                const cleanedTopics = Topics
                    .replace(/^'|'$/g, '')
                    .replace(/'/g, '"');

                return JSON.parse(cleanedTopics);
            }
            return [];
        } catch (error) {
            console.error('Error parsing topics:', error);
            return [];
        }
    }, [Topics]);

    const [selectedTopics, setSelectedTopics] = useState(parsedTopics);
    const [searchTerm, setSearchTerm] = useState("");
    const [bio, setBio] = useState(Bio || "");
    const [customTopics, setCustomTopics] = useState([]);

    let count = selectedTopics ? selectedTopics?.length : 0;

    const handleChange = (e) => {
        if (e.target.value.length <= 300) {
            setBio(e.target.value);
        }
    };

    const removeSelectedTopic = (topic) => {
        setSelectedTopics((prev) => prev.filter((item) => item !== topic));
    };

    const toggleTopicSelection = (topic) => {
        setSelectedTopics(
            (prev) =>
                prev.includes(topic)
                    ? prev.filter((t) => t !== topic)
                    : prev.length < 5
                        ? [...prev, topic]
                        : prev
        );
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            const newTopic = searchTerm.trim();
            if (
                newTopic &&
                !selectedTopics.includes(newTopic) &&
                !customTopics.includes(newTopic) &&
                selectedTopics.length < 5
            ) {
                setCustomTopics((prev) => [...prev, newTopic]);
                setSelectedTopics((prev) => [...prev, newTopic]);
            }
            setSearchTerm("");
        }
    };

    useEffect(() => {
        dispatch(setDataValue({ Topics: selectedTopics || JSON.parse(Topics) }));
    }, [selectedTopics]);
    useEffect(() => {
        if (bio.trim() === "") {
            dispatch(setDataValue({ Bio: Bio }));
        } else {
            dispatch(setDataValue({ Bio: bio }));
        }
    }, [bio]);



    return (
        <div className='userbio_container'>
            <div className='userbio_sub_container'>
                <div className='userbio_sub_container_top'>
                    <span className='user_profileinfo_title'>Update your bio</span>
                    <span className='user_profileinfo_subtitle'>You can easly update this bio whenever you needed.</span>
                </div>
                <div className='userbio_sub_container_bio'>
                    <textarea
                        className="userbio_data"
                        value={bio}
                        onChange={handleChange}
                        placeholder="Write your bio..."
                    />
                    <div className="userbio_data_length">{bio?.length}/300</div>
                </div>
            </div>
            <div className='userbio_sub_container'>
                <div>
                    <span className='user_profileinfo_title'>Modify your selected topics here.</span>
                </div>
                <div className='userbio_sub_container_topics'>
                    <div className='userbio_sub_container_selected_topics'>
                        <div className='userbio_sub_container_topics_title'>
                            <span>Your Selected Topics</span>
                            <span>{count}/5</span>
                        </div>
                        <div className="userbio_sub_container_topics_search" >
                            <SearchBar
                                placeholder="Search"
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                                handleKeyDown={handleKeyDown}
                            />
                        </div>
                        <div className='userbio_sub_container_selected_topics_items'>
                            {selectedTopics?.map((topic, index) => (
                                <div key={index} className="selected_topic">
                                    <span className="topic" onClick={() => removeSelectedTopic(topic)}>{topic}</span>
                                </div>
                            ))}

                        </div>

                    </div>
                    <div className='userbio_sub_container_rec_topics'>
                        <span className='userbio_sub_container_topics_title'>
                            Recommended for You
                        </span>
                        <div className='userbio_sub_container_rec_topics_items'>
                            {InterestTopics.map((topic, index) => (
                                <button
                                    className='selectedTag'
                                    key={index}
                                    onClick={() => toggleTopicSelection(topic)}
                                >
                                    <div className="rec_topic">{topic}</div>
                                </button>
                            ))}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

const UserSocial = () => {

    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [platform, setPlatform] = useState({ name: "", key: "", link: "" });

    const user = useSelector((state) => state?.user || []);
    const userProfile = user?.[0] || {};

    const [socialPlatforms, setSocialPlatforms] = useState([
        { name: "Facebook", key: "FacebookLink", icon: Facebook, link: "" },
        { name: "Spotify", key: "SpotifyLink", icon: Spotify, link: "" },
        { name: "Instagram", key: "InstagramLink", icon: Insta, link: "" },
        { name: "LinkedIn", key: "LinkedinLink", icon: LinkedIn, link: "" },
        { name: "YouTube", key: "YoutubeLink", icon: Youtube, link: "" },
    ]);

    const handleSelect = (name, key, link) => {
        setPlatform({ name, key, link });
        setIsModalOpen(true);
    };

    const handleSave = () => {
        if (platform.link.trim() !== "") {
            dispatch(setDataValue({ [platform.key]: platform.link }));
        }
        setIsModalOpen(false);
    };

    const handlePlatformLink = (value) => {

        setPlatform((prev) => ({ ...prev, link: value }));
        setSocialPlatforms((prevPlatforms) =>
            prevPlatforms.map((item) =>
                item.name === platform.name ? { ...item, link: value } : item
            )
        );

    }


    return (
        <>
            <div className='usersocial_container'>
                <div className='usersocial_sub_container'>
                    <span className='user_profileinfo_title' >Which social media accounts would you like to link or unlink?</span>
                    <span className='usersocial_subTitle' >
                        you can connect additional social media accounts or remove existing one to help collaborators discover your work more easly.
                        Feel free to make changes anytime in your profile settings.
                    </span>
                </div>
                <div className='usersocial_profile_container'>

                    {socialPlatforms?.map(({ name, key, icon, link }) => (
                        <div key={name} className="usersocial_profile_container_item" onClick={() => handleSelect(name, key, link || userProfile[key])}>
                            <div className="usersocial_profile_item_img">
                                <img src={icon} alt={`${name} icon`} />
                            </div>
                            <div className="usersocial_profile_status_button">
                                <div className={userProfile[key] ? "usersocial_profile_unlink" : "usersocial_profile_item_status"}>
                                    <span>{userProfile[key] ? "Unlink" : "Link"}</span>
                                    {!userProfile[key] && <img src={Link} alt="link icon" />}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>

            <LinkDialog
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onConfirm={handleSave}
                title={`Edit ${platform?.name} Link`}
                inputValue={platform?.link}
                setInputValue={(value) => handlePlatformLink(value)}
            />
        </>

    )
}
export default Profile
