import React, { useRef, useEffect } from "react";

const LinkDialog = ({ open, onClose, onConfirm, title, inputValue, setInputValue }) => {
  const dialogRef = useRef(null);

  useEffect(() => {
    const dialog = dialogRef.current;
    if (open) {
      dialog.showModal();
    } else if (dialog?.open) {
      dialog.close();
    }
  }, [open]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [onClose]);

  const handleConfirm = () => {
    onConfirm(inputValue);
  };

  return (
    <>
      {open && <div className="dialog-overlay" onClick={onClose}></div>}
      <dialog ref={dialogRef} className="dialog-container2" onCancel={onClose}>
        <div className="dialog-content">
          <h2 className="dialog-title" style={{ padding: "5px 0px"}}>{title}</h2>
          <input
            type="url"
            placeholder="Enter your link"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            required
            autoFocus
            style={{ width: "100%", padding: "10px", fontSize: "16px" }} // Inline styles to make the input box bigger
          />
          <div className="dialog-actions" style={{ padding: "15px 0px"}}>
            <button onClick={onClose} className="dialog-button dialog-cancel">
              Cancel
            </button>
            <button
              onClick={handleConfirm}
              className="dialog-button dialog-confirm2 join-btn"
            >
              Save
            </button>
          </div>
        </div>
      </dialog>
    </>
  );
};

export default LinkDialog;
