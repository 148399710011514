import React, { useEffect, useState } from "react";
import ConnectionCard from "../../Components/ConnectionCard";
import SideBar from "../../Components/Sidebar";
import { useSelector } from "react-redux";
import "../../Styles/ConnectionPage.css";
import { getConnections } from "../../Services/Connection";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CommunityProfileCard from "../Container/Community/CommunityProfileCard";
import ViewMore from "./../../Assests/View More.svg"

const styles = {
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 16px",
    backgroundColor: "rgba(41, 104, 254, 0.8)",
    color: "#fff",
    border: "none",
    borderRadius: 16,
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    transition: "all 0.3s ease",
    marginLeft: 'auto',
    gap: 8,
    "&:hover": {
      backgroundColor: "#0056b3",
    },
    "&:active": {
      backgroundColor: "#004080",
    },
    "&:disabled": {
      backgroundColor: "rgba(150, 150, 150, 0.5)",
      cursor: "not-allowed",
    },
  },
}

const ConnectionPage = ({ viewAll, setViewAll }) => {
  const info = useSelector((state) => state.info);
  const [connections, setConnections] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const [viewAll, setViewAll] = useState(false); // Controls "View All" behavior
  const limit = viewAll ? 9 : 3; // Dynamic limit based on viewAll state

  useEffect(() => {
    const fetchConnections = async () => {
      const userId = localStorage.getItem("user_id");
      if (!userId) {
        setError("User is not logged in");
        return;
      }
      try {
        const response = await getConnections(userId, currentPage, limit);
        if (response && response.result) {
          setConnections(response.result);
          setTotalPages(Math.max(1, Math.ceil(response.totalCount / limit)));
        }
      } catch (err) {
        console.error("Error fetching connections:", err);
        setError("Failed to fetch connections.");
      }
    };
    fetchConnections();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage, limit]);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const generatePageNumbers = () => {
    const pages = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      let startPage = Math.max(2, currentPage - 1);
      let endPage = Math.min(totalPages - 1, currentPage + 1);

      if (currentPage <= 3) {
        endPage = Math.min(4, totalPages - 1);
      }

      if (currentPage >= totalPages - 2) {
        startPage = Math.max(2, totalPages - 3);
      }

      if (startPage > 2) {
        pages.push('...');
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage < totalPages - 1) {
        pages.push('...');
      }

      if (totalPages > 1) {
        pages.push(totalPages);
      }
    }

    return pages;
  };

  const handlePageChange = (page) => {
    if (page !== '...') {
      setCurrentPage(page);
    }
  };

  const scrollToTop = () => {
    const container = document.querySelector('.container-fluid');
    if (container) {
      container.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    }
  };

  const handleViewAll = () => {
    setViewAll(true);
  };

  const goBack = () => {
    setViewAll(false);
  };

  if (error) {
    return <div className="error-container">{error}</div>;
  }

  return (
    <div>
      <div className="container-fluid">
        <div className="connections-page-layout">
          {/* Header */}
          <div className="connection-header">
            {viewAll && (
              <div className="connection-left-arrow" onClick={goBack}>
                <ArrowBackIosIcon />
              </div>
            )}
            <h2>Your Connections</h2>
            <button
              className="view-all-button"
              // style={{ marginLeft: 'auto' }}
              style={styles.button}
              onClick={handleViewAll}>
              View All
              <img src={ViewMore} alt="icon" className="view-all-icon" />
            </button>
          </div>

          {/* Cards */}
          <div className="connections-grid">
            <div className="connection-row">
              {connections.length > 0 ? (
                connections.map((connection) => (
                  <CommunityProfileCard
                    key={connection.Email}
                    userConnection={true}
                    creater={connection}
                  />
                ))
              ) : (
                <p className="no-connections">No connections available</p>
              )}
            </div>
          </div>

          {/* View All Button (Only shown initially) */}
          {/* {!viewAll && connections.length > 3 && ( */}
          {/* <button className="view-all-button" onClick={handleViewAll}>
              View All
            </button> */}
          {/* )} */}

          {/* Pagination (Only visible if viewAll is true) */}
          {viewAll && (
            <div className="pagination-controls">
              <button
                className="pagination-button"
                onClick={handlePrev}
                disabled={currentPage === 1}
                title="Previous Page"
              >
                &lt;
              </button>
              {generatePageNumbers().map((page, index) =>
                page === '...' ? (
                  <span key={`ellipsis-${index}`} className="pagination-ellipsis">...</span>
                ) : (
                  <button
                    key={`page-${page}`}
                    className={`pagination-button page-number ${currentPage === page ? 'active' : ''}`}
                    onClick={() => handlePageChange(page)}
                    title={`Page ${page}`}
                  >
                    {page}
                  </button>
                )
              )}
              <button
                className="pagination-button"
                onClick={handleNext}
                disabled={currentPage === totalPages}
                title="Next Page"
              >
                &gt;
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Scroll-to-top Button */}
      <button
        onClick={scrollToTop}
        className="scroll-top-button"
        title="Scroll to top"
      >
        <ArrowCircleUpIcon fontSize="large" />
      </button>
    </div>
  );
};

export default ConnectionPage;
