import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const SocialMediaLI = () => {
    const [pageParams] = useSearchParams();
    const [code, setCode] = useState('');
    const navigate = useNavigate();
    const [shouldNavigate, setShouldNavigate] = useState(false);
    const previousPage_li = localStorage.getItem('previousPage_li') || '/shows';

    useEffect(() => {
        const authCode = pageParams.get('code');
        const error = pageParams.get('error');
        if (error) {
            navigate(previousPage_li, { state: { showLIPopup: false } });
            return;
        }
        if (authCode) {
            setCode(authCode);
            localStorage.setItem('code_li', authCode);
            setShouldNavigate(true);
        }
    }, [pageParams, navigate, previousPage_li]);

    useEffect(() => {
        if (shouldNavigate) {
            navigate(previousPage_li, { state: { showLIPopup: true } });
        }
    }, [shouldNavigate, navigate]);

    return <div />;
};

export default SocialMediaLI;
