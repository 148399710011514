import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    show: false,
    profileShow:false,
    profileBarShow: false,
    count: 0
}

const notificationSlice = createSlice({
    name: 'info',
    initialState,
    reducers: {
        setShow: (state, action) => {
            state.show = action.payload
        },
        setCount: (state, action) => {
            state.count = action.payload
        },
        incrementCount: (state) => {
            state.count += 1; // Increments count
        },
        resetCount: (state) => {
            state.count = 0; // Resets count to zero
        },
        decrementCount:(state)=>{
            state.count-=1;
        },
        setProfileShow: (state, action) => {
            state.profileShow = action.payload
        },
        setProfileBarShow: (state, action) => {
            state.profileBarShow = action.payload
        },
    }
});
export const { setShow, incrementCount,resetCount,decrementCount,setCount,setProfileShow,setProfileBarShow } = notificationSlice.actions;
export default notificationSlice.reducer;