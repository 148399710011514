import React, { useState, useRef } from "react";
import { FaPlay, FaPause } from "react-icons/fa"; 
import backgroundMusic from "../Assests/sampleAudio.mp3";
import { usePubSub } from "@videosdk.live/react-sdk";

const BGMPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null); // Create a ref to store the audio element
  const { publish } = usePubSub("UPDATE_MUSIC");

  const togglePlay = () => {
    if (!audioRef.current) {
      audioRef.current = new Audio(backgroundMusic); // Create the audio element only once
    }

    const audio = audioRef.current;

    if (isPlaying) {
      audio.pause();
      publish({ action: "pause", url: backgroundMusic }); // Publish pause action
      setIsPlaying(false);
    } else {
      audio.play();
      publish({ action: "play", url: backgroundMusic }); // Publish play action
      setIsPlaying(true);
    }
  };

  return (
    <div className="flex items-center space-x-4 text-gray-300">
      <button
        onClick={togglePlay}
        className="relative w-16 h-16 bg-blue-800 rounded-full flex items-center justify-center transition-all duration-300 pr-4"
        style={{ outline: "none", border: "none", backgroundColor: "transparent" }}
      >
        {isPlaying ? (
          <FaPause className="text-3xl text-white z-10" />
        ) : (
          <FaPlay className="text-3xl text-white z-10" />
        )}
      </button>
      <span className="text-lg font-medium text-gray-400">Celestial Tunes</span>
    </div>
  );
};

export default BGMPlayer;