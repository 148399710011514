import React from "react";

const ProgressBar = ({ steps, currentStep }) => {
  return (
    <div style={styles.progressBar}>
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          {/* Progress bar segment */}
          <div
            style={{
              ...styles.progressBarSegment,
              ...(step < currentStep && styles.completedSegment), // Completed segments
              ...(step === currentStep && styles.inProgressSegment), // In-progress segment
            }}
          >
            <div
              style={{
                ...styles.progressBarFill,
                ...(step < currentStep && styles.completedFill), // Fill for completed segments
              }}
            />
          </div>

          {/* Progress state (circle with number and status) */}
          <div
            style={{
              ...styles.progressState,
              ...(step < currentStep && styles.completedState), // Completed steps
              ...(step === currentStep && styles.inProgressState), // In-progress step
            }}
          >
            {/* Step number inside the circle */}
            <div style={styles.stepNumber}>{index + 1}</div>

            {/* Success indicator for completed steps */}
            {step < currentStep && <div style={styles.success} />}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

// Inline CSS styles
const styles = {
  progressBar: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  progressBarSegment: {
    flex: 1,
    height: "4px",
    backgroundColor: "#e0e0e0", // Default color for incomplete segments
    position: "relative",
  },
  completedSegment: {
    backgroundColor: "#4caf50", // Color for completed segments
  },
  inProgressSegment: {
    backgroundColor: "#4caf50", // Color for in-progress segment
  },
  progressBarFill: {
    height: "100%",
    backgroundColor: "#4caf50", // Green for completed steps
    width: "0",
    transition: "width 0.3s ease",
  },
  completedFill: {
    width: "100%",
  },
  progressState: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    backgroundColor: "#e0e0e0", // Default color for incomplete steps
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    margin: "0 8px", // Spacing between steps
  },
  completedState: {
    backgroundColor: "#4caf50", // Green for completed steps
  },
  inProgressState: {
    backgroundColor: "#4caf50", // Green for in-progress step
    boxShadow: "0 0 10px 3px rgba(76, 175, 80, 0.8)", // Glow effect for in-progress step
  },
  success: {
    width: "12px",
    height: "12px",
    backgroundColor: "#4caf50", // Green for completed steps
    borderRadius: "50%",
    position: "absolute",
  },
  stepNumber: {
    fontSize: "20px",
    color: "#ffffff", // White text for step numbers
    zIndex: 1, // Ensure the number is above the success indicator
  },
};

export default ProgressBar;