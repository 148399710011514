import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import SideBar from "../Components/Sidebar";
import "./Pages.css";
import { Button, Tooltip, IconButton, Typography, Box } from "@mui/material";
import { getScheduledPodcasts, deletePodcast } from "../Services/SchedulesApi";
import Loader from "../Components/Loader";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import WarningDialog from "../Components/WarningDialog";
import { getUserProfileDetails } from "../Services/ShowsApi";
import { useLoader } from "../Components/LoaderContext";
import edit from "../Assests/edit.svg";
import { commonConstants } from "../utils/constant";

const Schedules = () => {
  const navigate = useNavigate();
  const [podcasts, setPodcasts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedScheduleId, setSelectedScheduleId] = useState(null);
  const [selectedPodcast, setSelectedPodcast] = useState(null); // State to hold the selected podcast
  const [userProfile, setUserProfile] = useState({
    firstName: "",
    lastName: "",
  });
  const { triggerLoader, hideLoader } = useLoader();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000); // Simulate loading for 3 seconds
  }, []);

  // Fetch the podcasts data and update the state
  useEffect(() => {
    const fetchPodcasts = async () => {
      try {
        // Fetch podcast details
        const response = await getScheduledPodcasts(); // API call to fetch podcasts
        setPodcasts(response?.data?.Data); // Update podcasts data

        // Fetch user profile details
        const profileResponse = await getUserProfileDetails("UserID");
        const profileData = profileResponse?.data?.body?.Data?.[0] || {};
        setUserProfile({
          firstName: profileData.FirstName || "",
          lastName: profileData.LastName || "",
        });
        localStorage.setItem(
          "fullName",
          `${profileData.FirstName} ${profileData.LastName}`
        );
      } catch (error) {
        console.error("Error fetching podcasts:", error); // Error handling
      } finally {
        setLoading(false); // End loading after the fetch is complete
      }
    };

    fetchPodcasts();
  }, []);

  // Handle button click to navigate to the create schedule page
  const handleSchedule = () => {
    navigate("/shows/create"); // Navigate to create page
  };

  // Handle click on an existing podcast to edit
  const handleEditPodcast = (podcast) => {
    navigate("/shows/create", { state: { podcast } }); // Pass selected podcast data to the create/edit form
  };

  const handleDelete = (scheduleId, podcast, event) => {
    event.stopPropagation();
    setSelectedScheduleId(scheduleId);
    setSelectedPodcast(podcast); // Set the selected podcast
    setOpenDialog(true);
  };

  const confirmDelete = async () => {
    setOpenDialog(false);
    triggerLoader("Deleting Podcast...");
    if (selectedScheduleId) {
      try {
        await deletePodcast(selectedScheduleId); // Call to delete the podcast
        setPodcasts(
          podcasts.filter(
            (podcast) => podcast.ScheduleID !== selectedScheduleId
          )
        );
      } catch (error) {
        console.error("Error deleting podcast:", error);
      } finally {
        hideLoader();
      }
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedScheduleId(null);
    setSelectedPodcast(null); // Clear the selected podcast
  };

  const handleJoinStudio = (podcast) => {
    if (podcast?.guest) {
      console.log("using Guest stage");
      navigate(
        `/meeting?meetingId=${podcast?.MeetingID}&showName=${podcast?.Title}&guest=${podcast.guest ? "true" : "false"
        }&token=${podcast?.HostMeetingToken}`
      );
    } else {
      console.log("using Host stage");
      navigate(
        `/meeting?meetingId=${podcast?.MeetingID}&showName=${podcast?.Title}&token=${podcast?.HostMeetingToken}`
      );
    }
    // navigate(`/meeting?meetingId=${podcast.MeetingID}&showName=${title}&token=${HostMeetingToken}`);
  };

  return (
    <div
      className="theme-bg clr-white"
      style={{
        display: "flex",
        maxHeight: "75vh",
        overflowY: "auto",
        maxWidth: "96%",
        marginLeft: "2%",
      }}
    >
      <div className="container-fluid overflow-x-none">
        <div className="row">
          <div
            className="container"
            style={{ minHeight: "70vh", color: "#fff" }}
          >
            <div className="row pt-2">
              <div className="col-6 mb-4">
                {/* <h2>Podcast Schedules</h2> */}
              </div>
              <div className="col-6">
                {/* <Button className="btn fl-r schedule-btn">
                                    <span className='text-trans-none'>Record</span>
                                </Button> */}
                <Button
                  className="btn fl-r schedule-btn join-btn"
                  style={{ marginRight: "10px", marginBottom: "5px" }}
                  onClick={handleSchedule}
                >
                  <span className="text-trans-none">Schedule</span>
                </Button>
              </div>
            </div>
            {loading ? (
              <Loader message="Fetching your schedules" />
            ) : (
              <div
                className="row justify-content-center"
                style={{ overflowY: "auto" }}
              >
                <div className="col-md-12">
                  {podcasts?.length === 0 ? (
                    <div className="col-12" style={{ textAlign: "center" }}>
                      <h4>No podcasts Found</h4>
                    </div>
                  ) : (
                    podcasts?.map((podcast, index) => (
                      <div
                        key={index}
                        className="card mb-3 pointer"
                        style={{
                          backgroundColor: "rgb(25 25 62)",
                          color: "#fff",
                          borderRadius: "10px",
                          border: "none",
                        }}
                      >
                        <div
                          className="row card-body"
                          style={{
                            justifyContent: "space-between",
                            paddingRight: "4%",
                          }}
                        >
                          <div
                            style={{
                              width: "328px",
                              minWidth: "160px",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "flex-start",
                              gap: "8px",
                              display: "inline-flex",
                            }}
                          >
                            <div
                              style={{
                                alignSelf: "stretch",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                gap: "16px",
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  alignSelf: "stretch",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "flex-start",
                                  gap: "8px",
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "24px",
                                    letterSpacing: "1.92px",
                                    wordWrap: "break-word",
                                    fontWeight: "bolder",
                                    width: "100%",
                                  }}
                                >
                                  {podcast.Title}
                                </div>
                              </div>
                              <div
                                style={{
                                  alignSelf: "stretch",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "flex-start",
                                  gap: "8px",
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    color: "var(--Grays-White, white)",
                                    fontSize: "12px",
                                    // fontFamily: "Nexa",
                                    fontWeight: "100",
                                    lineHeight: "16.80px",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  Host: {podcast.Host}
                                  <br />
                                  Date/Time:{" "}
                                  {new Date(podcast.StartDttm).toLocaleString(
                                    "en-US",
                                    {
                                      hour12: true,
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      year: "numeric",
                                      month: "numeric",
                                      day: "numeric",
                                    }
                                  )}
                                </div>
                                <div
                                  style={{
                                    color: "var(--Grays-White, white)",
                                    fontSize: "12px",
                                    // fontFamily: "Nexa",
                                    fontWeight: "100",
                                    lineHeight: "16.80px",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  Guests:{" "}
                                  {JSON.parse(podcast?.Guests || "[]")
                                    ?.slice(0, 2)
                                    .map((guest, idx) => (
                                      <span key={idx}>
                                        {guest}
                                        {idx < 1 && ", "}
                                      </span>
                                    ))}
                                  {JSON.parse(podcast?.Guests || "[]")?.length >
                                    2 && (
                                      <Tooltip
                                        title={JSON.parse(podcast?.Guests || "[]")
                                          ?.slice(2)
                                          .join(", ")}
                                        arrowplacement="top"
                                      >
                                        <span className="more-guests">
                                          {` +${JSON.parse(podcast?.Guests || "[]")
                                            ?.length - 2
                                            }`}
                                        </span>
                                      </Tooltip>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              // flex: "1 1 0",
                              width: "50%",
                              alignSelf: "stretch",
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                              gap: "23px",
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                // flex: "1 1 0",
                                width: "100%",
                                alignSelf: "stretch",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                gap: "8px",
                                display: "inline-flex",
                              }}
                            >
                              <div
                                style={{
                                  alignSelf: "stretch",
                                  color: "white",
                                  fontSize: "16px",
                                  fontWeight: "bolder",
                                  letterSpacing: "1.28px",
                                  wordWrap: "break-word",
                                }}
                              >
                                Agenda
                              </div>
                              <div
                                style={{
                                  alignSelf: "stretch",
                                  color: "white",
                                  fontSize: "16px",
                                  // fontFamily: 'Nexa',
                                  fontWeight: "100",
                                  letterSpacing: "0.32px",
                                  wordWrap: "break-word",
                                }}
                              >
                                {podcast?.Agenda ? (
                                  <Tooltip
                                    arrow
                                    title={
                                      <Box
                                        sx={{
                                          maxWidth: "400px", // Adjust width as needed
                                          whiteSpace: "pre-wrap",
                                          overflowWrap: "break-word",
                                          p: 1,
                                        }}
                                        dangerouslySetInnerHTML={{ __html: podcast.Agenda }}
                                      />
                                    }
                                  >
                                    <Box
                                      sx={{
                                        display: "-webkit-box",
                                        WebkitLineClamp: 3, // Limits to 3 lines
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "pre-wrap",
                                        cursor: "pointer", // Indicates more content is available
                                        maxWidth: "400px",
                                      }}
                                      dangerouslySetInnerHTML={{ __html: podcast.Agenda }}
                                    />
                                  </Tooltip>
                                ) : (
                                  commonConstants.NO_AGENDA
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-1">
                            {!podcast.guest ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column", // Stack children vertically
                                  justifyContent: "space-between", // Add space between buttons
                                  height: "100%",
                                }}
                              >
                                <IconButton
                                  size="small"
                                  color="secondary"
                                  style={{
                                    color: "#fff",
                                    fontSize: "0.9rem",
                                    width: "120px",
                                    borderRadius: "16px",
                                    backgroundColor: "rgba(41, 104, 254, 0.8)",
                                    height: "40px",
                                    flexShrink: 0,
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "8px 16px",
                                    gap: "8px",
                                    display: "flex",
                                  }}
                                  onClick={(event) =>
                                    handleJoinStudio(podcast)
                                  }
                                >
                                  Join Now
                                </IconButton>
                                <IconButton
                                  size="small"
                                  color="secondary"
                                  style={{
                                    color: "#fff",
                                    fontSize: "0.9rem",
                                    width: "120px",
                                    borderRadius: "16px",
                                    // backgroundColor: "rgba(41, 104, 254, 0.8)",
                                    border: "1px solid #fff",
                                    height: "40px",
                                    flexShrink: 0,
                                    flexDirection: "row",
                                    alignItems: "center",
                                    // justifyContent: "space-between",
                                    padding: "8px 16px",
                                    gap: "8px",
                                    display: "flex",
                                  }}
                                  onClick={() => handleEditPodcast(podcast)}
                                >
                                  Edit
                                  <img
                                    src={edit}
                                    alt="Home"
                                    style={{ width: "16px", height: "16px" }}
                                  />
                                </IconButton>
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <WarningDialog
        open={openDialog}
        onClose={handleDialogClose}
        onConfirm={confirmDelete}
        title="Delete Schedule"
        description={
          selectedPodcast?.Title
            ? `Are you sure you want to delete this schedule: ${selectedPodcast.Title}?`
            : "Are you sure you want to delete this schedule?"
        }
      />
    </div>
  );
};

export default Schedules;

const Schedules_old = () => {
  const navigate = useNavigate();
  const [podcasts, setPodcasts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedScheduleId, setSelectedScheduleId] = useState(null);
  const [selectedPodcast, setSelectedPodcast] = useState(null); // State to hold the selected podcast
  const [userProfile, setUserProfile] = useState({
    firstName: "",
    lastName: "",
  });
  const { triggerLoader, hideLoader } = useLoader();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000); // Simulate loading for 3 seconds
  }, []);

  // Fetch the podcasts data and update the state
  useEffect(() => {
    const fetchPodcasts = async () => {
      try {
        // Fetch podcast details
        const response = await getScheduledPodcasts(); // API call to fetch podcasts
        setPodcasts(response?.data?.Data); // Update podcasts data

        // Fetch user profile details
        const profileResponse = await getUserProfileDetails("UserID");
        const profileData = profileResponse?.data?.body?.Data?.[0] || {};
        setUserProfile({
          firstName: profileData.FirstName || "",
          lastName: profileData.LastName || "",
        });
        localStorage.setItem(
          "fullName",
          `${profileData.FirstName} ${profileData.LastName}`
        );
      } catch (error) {
        console.error("Error fetching podcasts:", error); // Error handling
      } finally {
        setLoading(false); // End loading after the fetch is complete
      }
    };

    fetchPodcasts();
  }, []);

  // Handle button click to navigate to the create schedule page
  const handleSchedule = () => {
    navigate("/schedules/create"); // Navigate to create page
  };

  // Handle click on an existing podcast to edit
  const handleEditPodcast = (podcast) => {
    navigate("/schedules/create", { state: { podcast } }); // Pass selected podcast data to the create/edit form
  };

  const handleDelete = (scheduleId, podcast, event) => {
    event.stopPropagation();
    setSelectedScheduleId(scheduleId);
    setSelectedPodcast(podcast); // Set the selected podcast
    setOpenDialog(true);
  };

  const confirmDelete = async () => {
    setOpenDialog(false);
    triggerLoader("Deleting Podcast...");
    if (selectedScheduleId) {
      try {
        await deletePodcast(selectedScheduleId); // Call to delete the podcast
        setPodcasts(
          podcasts.filter(
            (podcast) => podcast.ScheduleID !== selectedScheduleId
          )
        );
      } catch (error) {
        console.error("Error deleting podcast:", error);
      } finally {
        hideLoader();
      }
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedScheduleId(null);
    setSelectedPodcast(null); // Clear the selected podcast
  };

  return (
    <div className="theme-bg clr-white height-100" style={{ display: "flex" }}>
      <SideBar />
      <div className="container-fluid overflow-x-none ml-80p">
        <div className="row">
          <div
            className="container px-5"
            style={{ minHeight: "100vh", color: "#fff" }}
          >
            <div className="row pt-5">
              <div className="col-6 mb-4">
                <h2>Podcast Schedules</h2>
              </div>
              <div className="col-6">
                {/* <Button className="btn fl-r schedule-btn">
                                    <span className='text-trans-none'>Record</span>
                                </Button> */}
                <Button
                  className="btn fl-r schedule-btn join-btn"
                  style={{ marginRight: "10px" }}
                  onClick={handleSchedule}
                >
                  <span className="text-trans-none">Schedule</span>
                </Button>
              </div>
            </div>
            {loading ? (
              <Loader message="Fetching your schedules" />
            ) : (
              <div
                className="row justify-content-center"
                style={{ height: "80vh", overflowY: "auto" }}
              >
                <div className="col-md-12">
                  {podcasts?.length === 0 ? (
                    <div className="col-12" style={{ textAlign: "center" }}>
                      <h4>No podcasts Found</h4>
                    </div>
                  ) : (
                    podcasts?.map((podcast, index) => (
                      <div
                        key={index}
                        className="card mb-3 pointer"
                        style={{
                          backgroundColor: "rgb(25 25 62)",
                          color: "#fff",
                          borderRadius: "10px",
                          border: "none",
                        }}
                        onClick={() => handleEditPodcast(podcast)}
                      >
                        <div className="row card-body">
                          <div className="col-6">
                            <h5
                              className="card-title"
                              style={{ fontSize: "20px" }}
                            >
                              {podcast.Title}
                            </h5>
                          </div>
                          <div className="col-3">
                            <p className="card-text">
                              <strong>Host:</strong> {podcast.Host}
                              <br />
                              <strong>Date and Time:</strong>{" "}
                              {new Date(podcast.StartDttm).toLocaleString(
                                "en-US",
                                {
                                  hour12: true,
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                }
                              )}
                              <br />
                            </p>
                          </div>
                          <div className="col-2">
                            <p className="card-text guests-list">
                              <strong>Guests: </strong>
                              <span className="guests-list">
                                {JSON.parse(podcast?.Guests || "[]")
                                  ?.slice(0, 2)
                                  .map((guest, idx) => (
                                    <span key={idx}>
                                      {guest}
                                      {idx < 1 && ", "}
                                    </span>
                                  ))}
                                {JSON.parse(podcast?.Guests || "[]")?.length >
                                  2 && (
                                    <Tooltip
                                      title={JSON.parse(podcast?.Guests || "[]")
                                        ?.slice(2)
                                        .join(", ")}
                                      arrow
                                      placement="top"
                                    >
                                      <span className="more-guests">
                                        {` +${JSON.parse(podcast?.Guests || "[]")
                                          ?.length - 2
                                          }`}
                                      </span>
                                    </Tooltip>
                                  )}
                              </span>
                            </p>
                          </div>

                          <div className="col-1">
                            {!podcast.guest ? (
                              <IconButton
                                size="small"
                                color="secondary"
                                onClick={(event) =>
                                  handleDelete(
                                    podcast.ScheduleID,
                                    podcast,
                                    event
                                  )
                                }
                              >
                                <DeleteOutlineIcon
                                  fontSize="small"
                                  style={{ color: "#fff", fontSize: "1.5rem" }}
                                />
                              </IconButton>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <WarningDialog
        open={openDialog}
        onClose={handleDialogClose}
        onConfirm={confirmDelete}
        title="Delete Schedule"
        description={
          selectedPodcast?.Title
            ? `Are you sure you want to delete this schedule: ${selectedPodcast.Title}?`
            : "Are you sure you want to delete this schedule?"
        }
      />
    </div>
  );
};
