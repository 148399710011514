import React, { useState } from "react";

const Switch = ({toggleSwitch,isOn}) => {
  // const [isOn, setIsOn] = useState(false);

  // const toggleSwitch = () => {
  //   setIsOn(!isOn);
  // };

  const styles = {
    switchContainer: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      cursor: "pointer",
    },
    switchTrack: {
      width: "45px",
      height: "25px",
      borderRadius: "15px",
      backgroundColor: isOn ? "#4caf50" : "gray",
      position: "relative",
      transition: "background-color 0.3s ease",
    },
    switchThumb: {
      width: "21px",
      height: "21px",
      borderRadius: "50%",
      backgroundColor: "white",
      position: "absolute",
      top: "2px",
      left: "2px",
      transform: isOn ? "translateX(20px)" : "translateX(0px)",
      transition: "transform 0.3s ease",
    },
  };

  return (
    <div style={styles.switchContainer} onClick={toggleSwitch}>
      <div style={styles.switchTrack}>
        <div style={styles.switchThumb} />
      </div>
      {/* <div style={styles.switchLabel}>Open to Collaborate</div> */}
    </div>
  );
};

export default Switch;
