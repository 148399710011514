import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import SideBar from "../Components/Sidebar";
import {
  Button,
  Menu,
  MenuItem,
  Chip,
  Tooltip,
  TextField,
  Pagination,
} from "@mui/material";
import { getShows } from "../Services/ShowsApi";
import Loader from "../Components/Loader";
import ProfileUpdate from "./ProfileUpdate";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SortIcon from "@mui/icons-material/Sort"; // Import the SortIcon
import { getUserProfileDetails, updateShowDetails } from "../Services/ShowsApi";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Snackbar from "@mui/material/Snackbar";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PageHeader from "./Container/PageHeader/Pageheader";
import { setShow } from "../redux/notificationSlice";
import { useDispatch, useSelector } from "react-redux";
import useClickOutside from "../Hook/useClickOutside";
import Schedules from "./Schedules";
import MissedSchedules from "./MissedSchedules";

const Shows = () => {
  const [shows, setShows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState({});
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1); // Track current page
  const [limit] = useState(8); // Set limit per page
  const [totalCount, setTotalCount] = useState(0); // Track total count of shows
  const [sortOrder, setSortOrder] = useState({
    field: "CreatedDttm",
    direction: "desc",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(",")[1]); // Extract Base64 string
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  // const handleEditImage = async (e, show) => {
  //     const file = e.target.files[0];

  //     try {
  //         // Convert file to Base64
  //         const base64String = await readFileAsBase64(file);

  //         // Construct payload
  //         const payload = {
  //             UserID: show.UserID,
  //             ShowName: show.ShowName,
  //             RoomID: show.RoomID,
  //             thumbnailBase64: base64String
  //         };

  //         console.log('Payload:', payload);
  //         await updateShowDetails(payload);
  //         setSnackBarMessage("Image uploaded successfully!");
  //         const response = await getShows(searchQuery, page, limit);
  //         setShows(response?.data?.Data);
  //         setTotalCount(response?.data?.totalCount);

  //     } catch (error) {
  //         console.error('Error processing the file:', error);
  //     }
  // };

  // Fetch shows from API with pagination
  useEffect(() => {
    const fetchShows = async () => {
      setLoading(true); // Set loading state
      try {
        const response = await getShows(searchQuery, page, limit); // Pass page and limit to the API
        console.log("getshows response: ", response);
        setShows(response?.data?.Data);
        setTotalCount(response?.data?.totalCount); // Set total count from API response
        const profileStatus = await getUserProfileDetails("UserID");
        if (
          profileStatus?.data?.body?.Data?.length > 0 &&
          profileStatus.data.body.Data[0]?.isNameEmpty === 1
        ) {
          setOpenModal(true);
        } else {
          setOpenModal(false);
        }
      } catch (error) {
        console.error("Error fetching shows:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchShows();
  }, [page, limit, searchQuery]); // Trigger the API call when page, limit, or searchQuery changes

  // Handle sorting change (by date or name)
  const handleSortChange = (field, direction) => {
    setSortOrder({ field, direction });
    handleClose(); // Close the menu after selection
  };

  // Handle the search input change
  const handleSearchChange = async (event) => {
    setSearchQuery(event.target.value); // Update search query
    setPage(1); // Reset to first page when search query changes
  };
  

  // Filter and sort shows based on search query and sort order
  const filteredShows =
    shows && shows
      ? shows.sort((a, b) => {
          if (sortOrder.field === "CreatedDttm") {
            const dateA = new Date(a.CreatedDttm);
            const dateB = new Date(b.CreatedDttm);
            return sortOrder.direction === "asc"
              ? dateA - dateB
              : dateB - dateA;
          } else if (sortOrder.field === "ShowName") {
            const nameA = a.ShowName.toLowerCase();
            const nameB = b.ShowName.toLowerCase();
            return sortOrder.direction === "asc"
              ? nameA.localeCompare(nameB)
              : nameB.localeCompare(nameA);
          }
          return 0;
        })
      : [];

  // Handle the page change in pagination
  const handleChange = (event, value) => {
    setPage(value); // Change the page when pagination item is clicked
  };

  // Calculate total pages based on totalCount and limit
  const totalPages = Math.ceil(totalCount / limit);

  const dispatch = useDispatch();
  const info = useSelector((state) => state.info);

  const [activeSection, setActiveSection] = useState(0);

  const containerStyle = {
    width: "94%",
    height: "7.9%",
    padding: "16px",
    position: "relative",
    background: "rgba(255, 255, 255, 0.08)",
    borderRadius: "16px",
    outline: "1px rgba(175.31, 175.31, 175.31, 0.50) solid",
    outlineOffset: "-1px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "24px",
    marginLeft: "3%",
  };

  const overlayStyle = {
    width: "33.3%",
    height: "100%",
    position: "absolute",
    left: `calc(${activeSection * 100}% / 3)`, // Move overlay based on active section
    top: "1px",
    background: "rgba(71, 45, 219, 0.50)",
    borderRadius: "16px",
    // backdropFilter: 'blur(8px)',
    transition: "left 0.3s ease", // Smooth transition for overlay movement
  };

  const textStyle = {
    color: "white",
    fontSize: "17px",
    // fontFamily: 'Nexa',
    fontWeight: "bolder",
    letterSpacing: "1.92px",
    wordWrap: "break-word",
    cursor: "pointer", // Make text clickable
    rdWrap: "break-word",
  };

  const flexItemStyle = {
    flex: "1 1 0",
    padding: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    height: "100%",
  };

  const sections = [
    { title: "Upcoming Podcasts" },
    { title: "Recorded Shows" },
    { title: "Missed Schedules" },
  ];

  // Function to render each section
  const handleSectionClick = (index) => {
    setActiveSection(index);
  };

  // Function to render each section
  const renderSection = (section, index) => (
    <div
      key={index}
      style={flexItemStyle}
      onClick={() => handleSectionClick(index)} // Make section clickable
    >
      <div style={textStyle}>{section}</div>
    </div>
  );

  const renderContent = () => {
    switch (activeSection) {
      case 0: // Upcoming Podcasts
        return <Schedules/>;
      case 1:
        return (
          <div
            className="container-fluid"
            style={{ color: "#fff", maxWidth: "96%", marginLeft: "2%" }}
          >
            <div className="row">
              {/* <div className="col-6 my-4">
                    <h2>Shows</h2>
                </div> */}
              <div
                className="col-6 my-4 d-flex justify-content-end"
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {/* Search Bar */}
                <TextField
                  label="Search Shows"
                  variant="filled"
                  size="small"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  style={{ marginRight: "20px", width: "40%" }}
                  InputProps={{ style: { color: "white" } }}
                  InputLabelProps={{ style: { color: "white" } }}
                  sx={{ bgcolor: "rgba(255, 255, 255, 0.1)", borderRadius: 1 }}
                />

                <Button
                  id="sort-button"
                  aria-controls={open ? "sort-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  variant="outlined"
                  sx={{
                    bgcolor: "rgba(255, 255, 255, 0.1)",
                    border: "none",
                  }}
                >
                  <FilterAltIcon style={{ color: "white" }} />
                </Button>

                <Menu
                  id="sort-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "sort-button",
                  }}
                >
                  <MenuItem
                    onClick={() => handleSortChange("CreatedDttm", "desc")}
                  >
                    <ArrowDownwardIcon style={{ marginRight: "10px" }} /> Date
                    (Descending)
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleSortChange("CreatedDttm", "asc")}
                  >
                    <ArrowUpwardIcon style={{ marginRight: "10px" }} /> Date
                    (Ascending)
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleSortChange("ShowName", "desc")}
                  >
                    <ArrowDownwardIcon style={{ marginRight: "10px" }} /> Name
                    (Descending)
                  </MenuItem>
                  <MenuItem onClick={() => handleSortChange("ShowName", "asc")}>
                    <ArrowUpwardIcon style={{ marginRight: "10px" }} /> Name
                    (Ascending)
                  </MenuItem>
                </Menu>
              </div>
            </div>

            {loading ? (
              <Loader message="Fetching shows" />
            ) : (
              <div
                className="row"
                style={{ height: "63vh", overflowY: "auto" }}
              >
                {filteredShows.length === 0 && (
                  <div className="col-12" style={{ textAlign: "center" }}>
                    <h4>No Shows Found</h4>
                  </div>
                )}
                {filteredShows.map((show, index) => (
                  <div
                    key={index}
                    className="col-md-3 d-flex justify-content-center mb-4"
                  >
                    <div
                      className="position-relative pointer"
                      style={{
                        width: "100%",
                        maxWidth: "300px",
                        height: "fit-content",
                      }}
                      onClick={() => navigate(`/shows/details/${show.ShowID}`)}
                    >
                      {/* Display loader until image is loaded */}
                      {!imageLoaded[index] && (
                        <div className="image-loader-overlay">
                          {show.ThumbnailURL ? (
                            <Loader />
                          ) : (
                            <div className="image-loader-overlay">
                              <span>No Thumbnail present</span>
                            </div>
                          )}
                        </div>
                      )}

                      <img
                        src={show.ThumbnailURL}
                        alt={show.ShowName}
                        style={{
                          width: "100%",
                          height: "175px",
                          borderRadius: "8px",
                          objectFit: "cover",
                          display: imageLoaded[index] ? "block" : "none",
                        }}
                        onLoad={() =>
                          setImageLoaded((prev) => ({
                            ...prev,
                            [index]: true,
                          }))
                        }
                      />

                      {/* Label for file input, triggers file upload dialog */}
                      {/* <label
                                        style={{
                                            position: "absolute",
                                            top: "10px",
                                            right: "10px",
                                            background: "#fff",
                                            borderRadius: "50%",
                                            padding: "5px",
                                            cursor: "pointer",
                                            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                                            zIndex: 1, // Ensure the label is clickable on top of other elements
                                        }}
                                        onClick={(e) => e.stopPropagation()} // Prevent image click event from firing
                                    >
                                        <ModeEditIcon style={{ color: "#333" }} />
                                        <input
                                            type="file"
                                            style={{ display: "none" }} // Hide the input
                                            accept="image/*"
                                            onChange={(e) => handleEditImage(e, show)} // Handle file selection
                                        />
                                    </label> */}

                      <Chip
                        style={{
                          position: "absolute",
                          bottom: "20%",
                          height: "auto",
                          right: "10px",
                          background: "#633B48",
                          color: "#fff",
                          borderRadius: "20px",
                          fontSize: "0.7em",
                        }}
                        label={show.RunTime}
                      />

                      <div
                        style={{
                          width: "100%",
                          color: "#fff",
                          padding: "6px 12px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          borderRadius: "0 0 8px 8px",
                        }}
                      >
                        <Tooltip title={show.ShowName} arrow placement="top">
                          <span
                            style={{ fontWeight: "bold" }}
                            className="ellipsis"
                          >
                            {show.ShowName}
                          </span>
                        </Tooltip>
                        <span style={{ fontSize: "0.9em" }}>
                          {new Date(show.CreatedDttm).toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}

                <div
                  className="d-flex justify-content-center"
                  style={{ alignSelf: "self-end", marginBottom: "1%" }}
                >
                  <Pagination
                    count={totalPages} // Use totalPages calculated from totalCount and limit
                    page={page}
                    onChange={handleChange}
                    sx={{
                      "& .MuiPaginationItem-root.Mui-selected": {
                        backgroundColor: "rgb(133, 133, 133)",
                      },
                      "& .MuiPaginationItem-root:hover": {
                        backgroundColor: "rgb(100, 100, 100)", // Hover background color
                      },
                    }}
                  />
                </div>
              </div>
            )}

            <ProfileUpdate open={openModal} onClose={handleCloseModal} />
          </div>
        );
      case 2: // Missed Schedules
        return <MissedSchedules/>;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="theme-bg clr-white height-100" style={{ display: "flex", overflow: 'hidden' }}>
        <SideBar />
        <div className="container-fluid overflow-x-none ml-80p">
          {/* <div className="row px-5"> */}
          <PageHeader pageTitle="My Shows" />
          <div style={containerStyle}>
            <div style={overlayStyle}></div>
            {sections.map((section, index) =>
              renderSection(section.title, index)
            )}
          </div>
          {/* <div className="container-fluid theme-bg-light my-5" style={{ minHeight: "80vh", color: "#fff" }}> */}
          {renderContent()}
          {/* </div> */}
        </div>
        <Snackbar
          open={!!snackBarMessage}
          autoHideDuration={5000}
          onClose={() => setSnackBarMessage("")}
          message="Image uploaded successfully!"
          style={{ backgroundColor: "white", color: "black" }}
        />
      </div>
    </>
  );
};

export default Shows;
