import React, { useEffect, useState } from "react";
import noprofile from "../../../Assests/noprofile.svg";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import  SearchBar  from "../../../Components/Searchbar";

function ChatList({setChatArea,setUserName,setProfileImage,setMessages,setUID,}) {
  const [friends, setFriends] = useState([]);
  const [selectedFriend, setSelectedFriend] = useState(null);
  const [unreadCounts, setUnreadCounts] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(null);
  const [lastMessages, setLastMessages] = useState({});

const appID = process.env.REACT_APP_COMETCHAT_APP_ID;
const region = process.env.REACT_APP_COMETCHAT_APP_REGION;
const authKey = process.env.REACT_APP_COMETCHAT_AUTH_KEY;
const uid = localStorage.getItem("user_id");

  useEffect(() => {
    if (!uid) {
      setError("No user ID found in local storage.");
      return;
    }

    const initializeChat = async () => {
      try {
        if (!CometChat.isInitialized()) {
          await CometChat.init(
            appID,
            new CometChat.AppSettingsBuilder()
              .subscribePresenceForAllUsers()
              .setRegion(region)
              .build()
          );
          console.log("CometChat initialized successfully");
        }

        const loggedInUser = await CometChat.getLoggedinUser();
        if (!loggedInUser) {
          try {
            const user = await CometChat.login(uid, authKey);
            console.log("User logged in successfully:", user);
          } catch (loginError) {
            if (loginError.code === "ERR_UID_NOT_FOUND") {
              console.log("User not found. Creating user...");
              const newUser = new CometChat.User(uid);
              newUser.setName(uid);
              await CometChat.createUser(newUser, authKey);
              console.log("User created. Logging in...");
              const user = await CometChat.login(uid, authKey);
              console.log("User logged in successfully after creation:", user);
            } else {
              throw loginError;
            }
          }
        }
        fetchFriends();
        fetchLastMessages();
        setupMessageListener();
      } catch (error) {
        setError("Error initializing CometChat: " + error.message);
      }
    };

    initializeChat();
    return () => {
      CometChat.removeMessageListener("unreadListener");
    };
  }, []);

const formatLastSeen = (timestamp) => {
  const date = new Date(timestamp * 1000); // Convert from seconds to milliseconds
  const now = new Date();
  
  const isToday = date.toDateString() === now.toDateString();

  if (isToday) {
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }); // Show only time if today
  } else {
    return date.toLocaleDateString(); // Show only date if not today
  }
};


  const fetchFriends = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DYRECT_API_URL}connections?UserID=${uid}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch friends");
      }

      const data = await response.json();
      console.log("Raw Data:", data?.result);

      const formattedFriends = Array.isArray(data?.result)
        ? data?.result
            .map((friend) => {
              const friendUID =
                friend.ReceiverID === uid ? friend.SenderID : friend.ReceiverID;
              return {
                uid: friendUID,
                name: `${friend.FirstName} ${friend.LastName}`.trim(),
                avatar: friend.ProfileImageKey || noprofile,
                lastActiveAt: friend.UpdatedAt
                  ? new Date(friend.UpdatedAt).getTime() / 1000
                  : null,
              };
            })
            .filter((friend) => friend.uid && friend.uid !== uid)
        : [];

      setFriends(formattedFriends);
      console.log("Filtered Friends list:", formattedFriends);
    } catch (error) {
      setError("Error fetching friends: " + error.message);
      setFriends([]);
    }
  };

  const fetchLastMessages = async () => {
    try {
      const conversationsRequest = new CometChat.ConversationsRequestBuilder()
        .setLimit(50)
        .build();

      const conversations = await conversationsRequest.fetchNext();
      console.log("Conversations:", conversations);

      const lastMessagesData = {};
      conversations.forEach((conversation) => {
        const lastMessage = conversation.lastMessage;
        lastMessagesData[conversation.conversationWith.uid] =
          lastMessage.text || "[Unsupported Message Type]";
      });

      setLastMessages(lastMessagesData);
    } catch (error) {
      console.error("Error fetching last messages:", error);
    }
  };

  const setupMessageListener = () => {
    const messageListener = new CometChat.MessageListener({
      onTextMessageReceived: (message) => {
        console.log("New message received:", message);

        setMessages((prevMessages) => [...prevMessages, message]);
      },
    });

    CometChat.addMessageListener("messageListener", messageListener);
  };
const getMessagesBetweenUsers = async (receiverUID) => {
  const limit = 100;

  try {
    let receiver;
    try {
      // Try fetching the user
      receiver = await CometChat.getUser(receiverUID);
    } catch (error) {
      if (error.code === "ERR_UID_NOT_FOUND") {
        console.log("Receiver user does not exist. Creating user...");

        // Create new user in CometChat
        const newUser = new CometChat.User(receiverUID);
        newUser.setName(receiverUID); // Default name, update as needed

        await CometChat.createUser(newUser, authKey);
        console.log("Receiver user created successfully.");

        // Fetch the newly created user
        receiver = await CometChat.getUser(receiverUID);
      } else {
        throw error;
      }
    }

    if (receiver) {
      // Fetch chat messages
      const messagesRequest = new CometChat.MessagesRequestBuilder()
        .setUID(receiverUID)
        .setLimit(limit)
        .build();

      const messages = await messagesRequest.fetchPrevious();
      console.log("Messages fetched successfully:", messages);
      setMessages(messages);
    }
  } catch (error) {
    console.error("Error fetching messages or creating user:", error);
  }
};

  const handleChatArea = (friend) => {
    setChatArea(true);
    setUserName(friend.name);
    setProfileImage(friend.avatar || noprofile);
    setUID(friend.uid);
    setSelectedFriend(friend.uid);
    getMessagesBetweenUsers(friend.uid);
console.log(friend.uid,selectedFriend);
  };

  const filteredFriends = friends.filter((friend) =>
    friend.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="chatlist">
      {error && <div className="error-message">{error}</div>}
      <SearchBar 
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      placeholder="Search" 
      />
      <div className="contact-box">
        <div className="users-list">
          {filteredFriends.length > 0 ? (
            filteredFriends.map((friend) => (
              <div
                key={friend.uid}
                className={`user-card ${selectedFriend === friend.uid ? "selected" : ""}`}
                onClick={() => handleChatArea(friend)}
              >
                <img src={friend.avatar || noprofile} alt={friend.name} className="user-avatar" />
                <div className="user-info">
                  <h4 className="user-name">{friend.name}</h4>
                  <p className="user-description">{lastMessages[friend.uid] || "No messages yet"}</p>
		</div>
		<div className="message-info">
			{unreadCounts[friend.uid]>0&&(
		<span className="unread-count">{unreadCounts[friend.uid]}</span>
		)}

<span className="last-seen">
  {friend.lastActiveAt ? formatLastSeen(friend.lastActiveAt) : ""}
</span>
                </div>
              </div>
            ))
          ) : (
            <p style={{textAlign:"center"}}>No friends found</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChatList;
