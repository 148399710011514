import React, { useState, useEffect } from 'react';
import CommunityProfileCard from './CommunityProfileCard';
import { getSearchCreators } from '../../../Services/ShowsApi';
import { useSelector } from 'react-redux';
import { updateConnects } from '../../../Services/ShowsApi';
import { API_STATUS,SEARCH_TYPE } from '../../../utils/constant';
import LeftArrow from "../../../Assests/Chevron.svg"
import { useDispatch } from 'react-redux';
import { setValue } from '../../../redux/valueSlice';

const CommunitySearch = () => {
    
    const dispatch=useDispatch();
    const value = useSelector((state) => state?.value)
    const [searchType, setSearchType] = useState('Podcaster')
    const [searchData, setSearchData] = useState([])

    const fetchData = async () => {
        try {
            let filterType = searchType === 'Podcaster' ? 'podcasters' : 'topics';
            let filterValue = value ? value?.filterValue : "";

            if (filterValue) {
                const { data } = await getSearchCreators(filterType, filterValue);
                let { Data } = data?.body
                setSearchData(Data)
                console.log("searchResult", data?.body);
            }

        } catch (error) {
            console.error("Error fetching search:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [searchType, value])

    const handleClickViewProfile = (userID) => {
        alert("User Profile View Feature coming soon...")
    }

    const handleConnectionClick = async (userID) => {
        const data = {
            SenderID: localStorage.getItem("user_id"),
            ReceiverID: userID,
        };

        let res = await updateConnects(data)

        if (res.status === API_STATUS.SUCCESS) {
            fetchData();
        }

    }

    const handleClickBack=()=>{
        dispatch(setValue({ profileSearch: false }))
    }


    return (
        <div>
            <div className='community_search_type'>
                <div className='community_search_type_backbutton' onClick={handleClickBack}>
                    <img src={LeftArrow}/>
                </div>
                <div className='community_search_type_selecter'>
                    {
                        SEARCH_TYPE?.map(({ id, type }) => (
                            <>
                                <div
                                    key={id}
                                    className={`community_search_type_item ${searchType === type ? "selected_Type" : ""}`}
                                    onClick={() => setSearchType(type)}
                                >
                                    <span>{type}</span>
                                </div>
                                {id !== SEARCH_TYPE.length - 1 && <div className='search_type_separator'></div>}
                            </>
                        ))
                    }
                </div>
            </div>
            <div className='community_searchresult'>
                <div className='community_searchr_cards'>
                    {
                        searchData?.map((item, index) => (
                            <div className='community_searchr_cards_container'>
                                <CommunityProfileCard
                                    userConnection={false}
                                    creater={item}
                                    handleClickViewProfile={handleClickViewProfile}
                                    handleConnectionClick={handleConnectionClick}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>

        </div>
    )
}

export default CommunitySearch
