import React, { useEffect, useState } from 'react'
import AddSharpIcon from '@mui/icons-material/AddSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import NotificationIcon from "../../../Assests/Notification3.svg"
import { useDispatch, useSelector } from 'react-redux'
import { resetCount, decrementCount, setCount, setShow } from '../../../redux/notificationSlice';
import {
  getScheduledPodcasts,
  getConnectionRequestsByUserId,
  deleteAllConnectionRequests,
  rejectConnectionRequests,
  acceptConnectionRequests,
  clearNotification,
  getScheduledPodcastsByMeetingId
} from '../../../Services/SchedulesApi';
import { useNavigate } from 'react-router-dom';
import { Tooltip, Dialog } from "@mui/material";
import "./Notification.css"

const NotificationTab = ({ notificationTabRef }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const info = useSelector((state) => state.info)

  const [notification, setNotification] = useState([])
  const userId = localStorage.getItem('user_id')

  useEffect(() => {
    const fetchPodcasts = async () => {
      try {

        const [connResponse] = await Promise.all([
          getConnectionRequestsByUserId(userId),
        ]);

        const newNotifications = [
          ...(connResponse?.result || []),
        ];
        dispatch(setCount(newNotifications?.length));
        setNotification(newNotifications)

      } catch (error) {
        console.error("Error fetching podcasts:", error);
      }
    };
    fetchPodcasts();

  }, [])

  const handleClearAll = async () => {
    try {
      await deleteAllConnectionRequests(userId);
      setNotification([])
      dispatch(resetCount())
      dispatch(setShow(false))

    } catch (error) {
      console.error("Error deleting podcasts:", error);
    }
  }
  const declineRequest = async (index) => {
    let receiverId = notification[index].SenderID
    if (notification[index].NotificationID) {
      const payload = {
        "SenderID": receiverId,
        "ReceiverID": userId,
        "Status": "REJECTED"
      };
      await rejectConnectionRequests(payload)
    }
    dispatch(decrementCount())
    setNotification((prev) => prev.filter((_, i) => i !== index));
  };

  const removeNotification = async (index) => {
    let NotificationID = notification[index].NotificationID
    await clearNotification(NotificationID)
    dispatch(decrementCount())
    setNotification((prev) => prev.filter((_, i) => i !== index));
  };

  const acceptRequest = async (index) => {
    let receiverId = notification[index].SenderID
    const payload = {
      "SenderID": receiverId,
      "ReceiverID": userId,
      "Status": "ACCEPTED"
    };
    await acceptConnectionRequests(payload)
    dispatch(decrementCount())
    setNotification((prev) => prev.filter((_, i) => i !== index));
  }


  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Extract day, month, and time
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "long" });
    const time = date.toLocaleString("en-US", { hour: "numeric", minute: "2-digit", hour12: true });

    // Add ordinal suffix (st, nd, rd, th)
    const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return "th"; // Covers 4th-20th
      switch (day % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
      }
    };

    return `${day}${getOrdinalSuffix(day)} ${month}, ${time}`;
  };

  const getTimeDifference = (notifiedDateTime) => {
    const givenDate = new Date(notifiedDateTime);
    const currentDate = new Date();
    const diffInSeconds = Math.floor((currentDate - givenDate) / 1000);

    if (diffInSeconds < 60) return "less than a minute ago";
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) return `${diffInMinutes} min ago`;
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return `${diffInHours} hr${diffInHours > 1 ? "s" : ""} ago`;
    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
    const diffInWeeks = Math.floor(diffInDays / 7);
    if (diffInWeeks < 4) return `${diffInWeeks} week${diffInWeeks > 1 ? "s" : ""} ago`;
    const diffInMonths = Math.floor(diffInDays / 30);
    if (diffInMonths < 12) return `${diffInMonths} month${diffInMonths > 1 ? "s" : ""} ago`;
    const diffInYears = Math.floor(diffInMonths / 12);
    return `${diffInYears} year${diffInYears > 1 ? "s" : ""} ago`;
  }

  const viewNotification = async (index) => {
    const { MeetingID } = notification[index];
    const { data } = await getScheduledPodcastsByMeetingId(MeetingID);
    const podcast = data?.Data?.[0];
    navigate('/shows/create', { state: { podcast } });
  };

  return (
    <Dialog open={info?.show}
      sx={{
        "& .MuiDialog-paper": {
          position: "absolute",
          top: "12%",
          right: info?.show ? 0 : "-100%",
          transition: "right 0.8s ease-in-out",
        },
      }}
    >
      <div className='notification-tab' ref={notificationTabRef}>
        <div className='notification-tab__header'>
          <span>Notifications</span>
          <button className='notification-tab__header__button' onClick={handleClearAll}>Clear All</button>
        </div>
        <div className="notification-tab__content">
          {notification?.map((item, index) => (
            <div key={index} className="notification-tab__item">
              {item?.NotificationType === "CONNECTION" ? (
                <>
                  <div className="notification-tab__item__head">
                    <div className="notification-tab__item__icon">
                      <img className='notification-tab__item__icon_pic' src={item?.ProfileImageKey} alt="profile-picture" />
                    </div>
                    <div className="notification-tab__item__content">
                      <span className="notification-tab__item__user">{item?.Username || "User Name"}<span className="notification-tab__item__message"> wants to connect with you!</span></span>
                      <span className="notification-tab__item__time">{getTimeDifference(item.CreatedAt)}</span>
                    </div>
                  </div>
                  <div className="notification-tab__item_footer">
                    <div className="notification-tab__item_button pointer" onClick={() => declineRequest(index)}>
                      <span>Decline</span>
                      <CloseSharpIcon />
                    </div>
                    <div className="notification-tab__item_button2 pointer" onClick={() => acceptRequest(index)}>
                      <span>Accept</span>
                      <CheckSharpIcon />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="notification-tab__item__top">
                    <div className="notification-tab__item__head">
                      <img className="notification-tab__item__icon2" src={NotificationIcon} alt="Notification Icon" />
                      <div className="notification-tab__item__content">
                        <span className="notification-tab__item__message2">Your Scheduled Podcast Is Coming Up!</span>
                        <span className="notification-tab__item__time">{getTimeDifference(item.CreatedAt)}</span>
                      </div>
                    </div>
                    <div>
                      <span className="notification-tab__item__message">
                        Your Podcast episode {item.Title} is scheduled for {formatDate(item?.StartDttm)}. Get Ready!
                      </span>
                    </div>
                  </div>
                  <div className="notification-tab__item_footer">
                    <div className="notification-tab__item_button pointer" onClick={() => removeNotification(index)}>
                      <span>Clear</span>
                    </div>
                    <div className="notification-tab__item_button2 pointer" onClick={() => viewNotification(index)}>
                      <span>View Details</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
          {
            notification?.length === 0 && (
              <div>
                <span className="notification-tab__item__message">No new notifications!</span>
              </div>
            )
          }
        </div>

      </div>
    </Dialog>
  )
}

export default NotificationTab
