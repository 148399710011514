import React from 'react';
import './Components.css'

const LoaderMessage = ({ loaderMessage }) => {
    console.log('LoaderMessage rendered');
    return (
        <div className="loader-container">
            <div className="loader"></div>
            <span style={{ color: 'white' }}>{loaderMessage}</span>
        </div>
    );
};

export default LoaderMessage;
