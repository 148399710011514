import React, { useEffect, useState, useRef, useCallback } from "react";
import SideBar from "../Components/Sidebar";
import { Breadcrumbs, Button, Link } from "@mui/material";
import {
  deleteShow,
  getShowDetails,
  updateShowDetails,
} from "../Services/ShowsApi";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import Loader from "../Components/Loader";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import EditShowModal from "./EditShowDetails";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import WarningDialog from "../Components/WarningDialog";
import { useSnackbar } from "../Components/SnackbarContext";
import SocialMediaShare from "../Pages/SocialMediaShare";
import YTPopup from "./YTPopup";
import LIPopup from "./LIPopup";
import ReactPlayer from "react-player";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";
import { TrimModal } from "../Components/trimDialogue";
import CheckIcon from '@mui/icons-material/Check';
import { useLoader } from "../Components/LoaderContext";
import Pageheader from "./Container/PageHeader/Pageheader";
import { useDispatch,useSelector } from "react-redux";
import { setShow } from "../redux/notificationSlice";
import useClickOutside from "../Hook/useClickOutside";

const ShowDetails = () => {
  const [activeTab, setActiveTab] = useState("summary");
  const location = useLocation();
  const [yTPopupOpen, setYTPopupOpen] = useState(false);
  const [lIPopupOpen, setLIPopupOpen] = useState(false);
  const [showDetails, setShowDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const { ShowID } = useParams();
  const [isPlaying, setIsPlaying] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [trimModalOpen, setTrimModalOpen] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const playerRef = useRef(null);
  const navigate = useNavigate();
  const { triggerSnackbar } = useSnackbar();
  const [roomID, setRoomID] = useState("");
  const [mp4File, setMp4File] = useState("");
  const [dragging, setDragging] = useState(null);
  const [isCopied, setIsCopied] = useState({
    transcript: false,
    agenda: false,
    guestDetails: false,
    summary: false,
    tags: false,
  });
  const [bucketName, setBucketName] = useState(null);
  const { triggerLoader, hideLoader } = useLoader();

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (!dragging || !duration) return;

      const sliderWidth = document.querySelector(
        ".frame-slider-container"
      ).offsetWidth;
      const sliderLeft = document
        .querySelector(".frame-slider-container")
        .getBoundingClientRect().left;
      const newTime = ((event.clientX - sliderLeft) / sliderWidth) * duration;

      if (dragging === "start") {
        setStartTime(Math.min(newTime, endTime - 1)); // Ensure start time is less than end time
      } else if (dragging === "end") {
        setEndTime(Math.max(newTime, startTime + 1)); // Ensure end time is greater than start time
      }
    };

    const handleMouseUp = () => setDragging(null);

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging, duration, startTime, endTime]);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.seekTo(startTime, "seconds");
    }
  }, [startTime]);

  useEffect(() => {
    console.log("Seeking to startTime:", startTime);
    if (playerRef.current) {
      playerRef.current.seekTo(startTime, "seconds");
      console.log("Seeked to:", startTime);
    }
  }, [startTime]);

  const handleCopy = async (type, content) => {
    await navigator.clipboard.writeText(content);
    setIsCopied((prev) => ({
      ...prev,
      [type]: true,
    }));
    setTimeout(() => {
      setIsCopied((prev) => ({
        ...prev,
        [type]: false,
      }));
    }, 2000);
  };

  const handleDownload = (content, filename) => {
    const blob = new Blob([content], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  };

  const handlePlayClick = () => {
    setIsPlaying(true);
  };

  const handleTrimButtonClick = () => {
    setTrimModalOpen(true);
    setIsPlaying(false);
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="white" href="/shows">
      <h3>Shows</h3>
    </Link>,
    <Link underline="hover" key="2" color="white">
      <h3 className="ellipsis">{showDetails?.ShowName}</h3>
    </Link>,
  ];

  const handleEditClick = () => {
    setModalOpen(true);
  };

  const handleDeleteClick = async () => {
    setOpenDialog(true);
  };

  const closeYTPopup = () => {
    setYTPopupOpen(false);
  };

  const closeLIPopup = () => {
    setLIPopupOpen(false);
  };

  const confirmDelete = async () => {
    setOpenDialog(false);
    if (ShowID) {
      try {
        triggerLoader("Deleting podcast...")
        await deleteShow(ShowID);
        navigate("/shows");
        triggerSnackbar("Podcast deleted successfully!");
      } catch (error) {
        console.error("Error deleting podcast:", error);
      } finally {
        hideLoader();
      }
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSave = async (updatedShowDetails) => {
    let modifiedShowDetails = { ...updatedShowDetails };
    let prefix = "data:image/png;base64,";

    if (
      modifiedShowDetails.Thumbnail &&
      modifiedShowDetails.Thumbnail.startsWith("https://")
    ) {
      modifiedShowDetails.Thumbnail = null;
    } else {
      modifiedShowDetails.Thumbnail = modifiedShowDetails.Thumbnail.substring(
        prefix.length
      );
    }
    setShowDetails(updatedShowDetails);
    await updateShowDetails(modifiedShowDetails);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const fetchShowDetails = useCallback(async () => {
    try {
      const response = await getShowDetails(ShowID);
      setShowDetails(response?.data?.Data);
      console.log(response?.data?.Data);
      setRoomID(response?.data?.Data?.RoomID);
      setMp4File(response?.data?.Data?.Mp4File);
      setBucketName(response?.data?.Data?.bucketName);
    } catch (error) {
      console.error("Error fetching shows:", error);
    } finally {
      setLoading(false);
    }
  }, [ShowID]); // Include ShowID as a dependency

  useEffect(() => {
    fetchShowDetails(); // Call the function inside useEffect
  }, [fetchShowDetails]); // Add fetchShowDetails as a dependency

  useEffect(() => {
    setYTPopupOpen(location?.state?.showYTPopup);
  }, [location?.state?.showYTPopup]);

  useEffect(() => {
    console.log("popup status", yTPopupOpen);
  }, [yTPopupOpen]);

  useEffect(() => {
    console.log("popup location", location?.state?.showLIPopup);
    setLIPopupOpen(location?.state?.showLIPopup);
  }, [location?.state?.showLIPopup]);

  const onDuration = (duration) => {
    setDuration(duration);
    setEndTime(duration);
  };

  const onProgress = (state) => {
    if (playerRef.current && isPlaying) {
      if (state.playedSeconds >= endTime) {
        setIsPlaying(false);
        playerRef.current.seekTo(startTime, "seconds");
      }
    }
  };

  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  }

  const dispatch = useDispatch()
  const info = useSelector((state) => state.info)


  return (
    <>
      <div className="theme-bg clr-white height-100" style={{ display: "flex" }}>
        <SideBar />
        <div className="container-fluid overflow-x-none ml-80p" style={{ overflowY: 'hidden' }}>
          <Pageheader pageTitle="My Shows" path='/shows' nextPage={showDetails?.ShowName} />
          {/* <div className="row px-5"> */}
          <div
            className="container-fluid theme-bg-light"
            style={{ color: "#fff", minHeight: "80vh" }}
          >
            {loading ? (
              <Loader message="Fetching your Show Details" />
            ) : (
              <div className="row">
                <div className="row col-12 my-4" style={{ marginLeft: "auto", display: "flex", justifyContent: "flex-end" }}>
                  {/* <div className="col-9">
                    <Breadcrumbs
                      separator={<NavigateNextIcon fontSize="large" style={{ color: "white" }} />}
                      aria-label="breadcrumb"
                      sx={{
                        '& .MuiBreadcrumbs-li': {
                          maxWidth: '80%'  // Apply width to each breadcrumb item
                        }
                      }}
                    >
                      <Link underline="hover" key="1" color="white" href="/shows">
                        <h3>Shows</h3>
                      </Link>
                      <Link underline="hover" key="2" color="white">
                        <h3 className="ellipsis" style={{ lineHeight: 'normal' }}>{showDetails?.ShowName}</h3>
                      </Link>
                    </Breadcrumbs>
                  </div> */}
                  <div className="col-3">
                    <Button
                      variant="contained"
                      color="primary"
                      className="join-btn fl-r"
                      onClick={handleEditClick}
                    >
                      <EditRoundedIcon />
                      <span className="text-trans-none">Edit</span>
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className="join-btn fl-r mr-2"
                      onClick={handleDeleteClick}
                    >
                      <DeleteRoundedIcon />
                      <span className="text-trans-none">Delete</span>
                    </Button>
                  </div>
                </div>
                <div className="col-12">
                  <div
                    className="row"
                    style={{ maxHeight: "75vh", overflowY: "auto" }}
                  >
                    <div className="col-6">
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          height: "350px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // marginTop: "3.7rem",
                        }}
                      >
                        {!isPlaying ? (
                          <>
                            {showDetails?.Thumbnail ? (
                              <img
                                src={showDetails.Thumbnail}
                                alt="Show Details"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "8px",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "#d3d3d3",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "8px",
                                  color: "#555",
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                  textAlign: "center",
                                }}
                              >
                                Thumbnail not available
                              </div>
                            )}
                            {showDetails?.ShowURL === null && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                                  color: "#fff",
                                  padding: "10px 20px",
                                  borderRadius: "5px",
                                  textAlign: "center",
                                  zIndex: 2,
                                }}
                              >
                                Video is processing. Please come back in a
                                while.
                              </div>
                            )}
                            {showDetails?.ShowURL && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <button
                                  onClick={handlePlayClick}
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    padding: "10px 20px",
                                    backgroundColor: "darkgray",
                                    color: "#fff",
                                    borderRadius: "5px",
                                    border: "none",
                                    cursor: "pointer",
                                    zIndex: 1,
                                  }}
                                >
                                  <PlayArrowRoundedIcon
                                    style={{ fontSize: "xx-large" }}
                                  ></PlayArrowRoundedIcon>
                                </button>
                              </div>
                            )}
                          </>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <ReactPlayer
                              ref={playerRef}
                              playing={isPlaying}
                              controls
                              url={showDetails?.ShowURL}
                              config={{
                                file: {
                                  tracks: [
                                    {
                                      kind: "subtitles",
                                      src: showDetails?.subtitlesUrl,
                                      srcLang: "en",
                                      default: true,
                                    },
                                  ],
                                },
                              }}
                              onDuration={onDuration}
                              onProgress={onProgress}
                            />
                          </div>
                        )}
                      </div>
                      <div className="mt-3 d-flex align-items-center">
                        <span>
                          Recorded {formatDate(showDetails?.CreatedAt)}
                        </span>
                        <Button
                          variant="contained"
                          color="primary"
                          className="ml-3 join-btn"
                          onClick={handleTrimButtonClick}
                        >
                          <span className="text-trans-none">
                            Trim Video
                          </span>
                        </Button>
                      </div>
                    </div>

                    <div className="col-6 custom-tabs-container">
                      <ul
                        className="nav nav-tabs nav-justified remove-space"
                        role="tablist"
                      >
                        {[
                          { id: "summary", label: "Summary" },
                          { id: "transcript", label: "Transcript" },
                          { id: "agenda", label: "Agenda" },
                          { id: "guestDetails", label: "Guest Details" },
                          { id: "tags", label: "Tags" },
                        ].map((tab) => (
                          <li
                            className="nav-item"
                            role="presentation"
                            key={tab.id}
                          >
                            <button
                              className={`nav-link custom-tab-unique ${activeTab === tab.id ? "active" : ""
                                }`}
                              type="button"
                              onClick={() => handleTabClick(tab.id)}
                            >
                              {tab.label}
                            </button>
                          </li>
                        ))}
                      </ul>

                      <div
                        className="tab-content"
                        style={{
                          height: "245px",
                          overflowY: "auto",
                          paddingTop: "10px",
                        }}
                      >
                        {activeTab === "summary" && (
                          <div id="summary" role="tabpanel">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                gap: "20px",
                                marginBottom: "10px",
                                position: "relative",
                              }}
                            >
                              {/* Download Button */}
                              <button
                                className="icon-button"
                                onClick={() =>
                                  handleDownload(showDetails?.summary.replace(/<[^>]+>/g, "") || "Summary not available at the moment.", "summary.txt")
                                }
                              >
                                <DownloadIcon />
                                <span className="tooltip">Download</span>
                              </button>

                              {/* Copy Button */}
                              <button
                                className="icon-button"
                                onClick={() => handleCopy("summary", showDetails?.summary.replace(/<[^>]+>/g, "") || "Summary not available at the moment.")}
                              >
                                {isCopied.summary ? <CheckIcon /> : <ContentCopyIcon />}
                                <span className="tooltip">{isCopied.summary ? "Copied" : "Copy"}</span>
                              </button>
                            </div>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: showDetails?.summary
                                  ? showDetails?.summary.replace(/\n/g, "<br />")
                                  : "Summary not available at the moment.",
                              }}
                            />
                          </div>
                        )}
                        {activeTab === "transcript" && (
                          <div id="transcript" role="tabpanel">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                gap: "20px",
                                marginBottom: "10px",
                                position: "relative",
                              }}
                            >
                              {/* Download Button */}
                              <button
                                className="icon-button"
                                onClick={() => handleDownload(showDetails?.Transcript || "", "transcript.txt")}
                              >
                                <DownloadIcon />
                                <span className="tooltip">Download</span>
                              </button>

                              {/* Copy Button */}
                              <button
                                className="icon-button"
                                onClick={() => handleCopy("transcript", showDetails?.Transcript || "")}
                              >
                                {isCopied.transcript ? <CheckIcon /> : <ContentCopyIcon />}
                                <span className="tooltip">{isCopied.transcript ? "Copied" : "Copy"}</span>
                              </button>
                            </div>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: showDetails?.summary && !showDetails?.Transcript
                                  ? "Transcript is being generated and should be available in a few minutes."
                                  : showDetails?.Transcript
                                    ? showDetails?.Transcript.replace(/\n/g, "<br />")
                                    : "Transcript is not available at the moment.",
                              }}
                            />
                          </div>
                        )}

                        {activeTab === "agenda" && (
                          <div id="agenda" role="tabpanel">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                gap: "20px",
                                marginBottom: "10px",
                                position: "relative",
                              }}
                            >
                              {/* Download Button */}
                              <button
                                className="icon-button"
                                onClick={() =>
                                  handleDownload(showDetails?.Agenda.replace(/<[^>]+>/g, "") || "No agenda has been included for this podcast.", "agenda.txt")
                                }
                              >
                                <DownloadIcon />
                                <span className="tooltip">Download</span>
                              </button>

                              {/* Copy Button */}
                              <button
                                className="icon-button"
                                onClick={() => handleCopy("agenda", showDetails?.Agenda.replace(/<[^>]+>/g, "") || "No agenda has been included for this podcast.")}
                              >
                                {isCopied.agenda ? <CheckIcon /> : <ContentCopyIcon />}
                                <span className="tooltip">{isCopied.agenda ? "Copied" : "Copy"}</span>
                              </button>
                            </div>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: showDetails?.Agenda
                                  ? showDetails?.Agenda.replace(/\n/g, "<br />")
                                  : "No agenda has been included for this podcast.",
                              }}
                            />
                          </div>
                        )}
                        {activeTab === "guestDetails" && (
                          <div id="guestDetails" role="tabpanel">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                gap: "20px",
                                marginBottom: "10px",
                                position: "relative",
                              }}
                            >
                              {/* Download Button */}
                              <button
                                className="icon-button"
                                onClick={() =>
                                  handleDownload(showDetails?.GuestInfo.replace(/<[^>]+>/g, "") || "No guest details were included for this podcast", "guestDetails.txt")
                                }
                              >
                                <DownloadIcon />
                                <span className="tooltip">Download</span>
                              </button>

                              {/* Copy Button */}
                              <button
                                className="icon-button"
                                onClick={() => handleCopy("guestDetails", showDetails?.GuestInfo.replace(/<[^>]+>/g, "") || "No guest details were included for this podcast")}
                              >
                                {isCopied.guestDetails ? <CheckIcon /> : <ContentCopyIcon />}
                                <span className="tooltip">{isCopied.guestDetails ? "Copied" : "Copy"}</span>
                              </button>
                            </div>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: showDetails?.GuestInfo
                                  ? showDetails?.GuestInfo.replace(/\n/g, "<br />")
                                  : "No guest details were included for this podcast",
                              }}
                            />
                          </div>
                        )}
                        {activeTab === "tags" && (
                          <div id="tags" role="tabpanel">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                gap: "20px",
                                marginBottom: "10px",
                                position: "relative",
                              }}
                            >
                              {/* Download Button */}
                              <button
                                className="icon-button"
                                onClick={() =>
                                  handleDownload(
                                    showDetails?.Tags && showDetails?.Tags.length > 0
                                      ? showDetails.Tags.join(", ")
                                      : "No tags found.",
                                    "tags.txt"
                                  )
                                }
                              >
                                <DownloadIcon />
                                <span className="tooltip">Download</span>
                              </button>

                              {/* Copy Button */}
                              <button
                                className="icon-button"
                                onClick={() =>
                                  handleCopy(
                                    "tags",
                                    showDetails?.Tags && showDetails?.Tags.length > 0
                                      ? showDetails.Tags.join(", ")
                                      : "No tags found."
                                  )
                                }
                              >
                                {isCopied.tags ? <CheckIcon /> : <ContentCopyIcon />}
                                <span className="tooltip">{isCopied.tags ? "Copied" : "Copy"}</span>
                              </button>
                            </div>
                            {showDetails?.Tags && showDetails?.Tags.length > 0 ? (
                              <div className="tags-container">
                                {showDetails.Tags.map((tag, index) => (
                                  <span key={index} className="tag-chip">
                                    {tag}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <p>No tags found.</p>
                            )}
                          </div>
                        )}
                      </div>
                      <SocialMediaShare />
                    </div>
                    <div className="col-12 mt-3 mb-3">
                      <h3>Edited clips</h3>
                      {showDetails?.PromoVideos.length > 0 ? (
                        <div className="row">
                          {showDetails?.PromoVideos.map((video, index) => (
                            <div
                              className="col-lg-3 col-md-4 col-sm-12 mb-3"
                              key={index}
                            >
                              <video
                                controls
                                style={{ width: "100%", height: "200px" }}
                              >
                                <source src={video.url} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                              {video.type === "AI" && (
                                <div className="text-end mt-1">
                                  <AutoAwesomeIcon
                                    style={{ fontSize: "20px", color: "white" }}
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div>
                          <span>No clips available at the moment.</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {yTPopupOpen && (
              <YTPopup data={showDetails} closePopup={closeYTPopup} />
            )}
            {lIPopupOpen && (
              <LIPopup data={showDetails} closePopup={closeLIPopup} />
            )}
          </div>
          {/* </div> */}
          <EditShowModal
            open={modalOpen}
            handleClose={handleCloseModal}
            showDetails={showDetails}
            handleSave={handleSave}
          />
          <WarningDialog
            open={openDialog}
            onClose={handleDialogClose}
            onConfirm={confirmDelete}
            title="Delete Podcast"
            description={"Are you sure you want to delete this podcast?"}
          />
          {/* Trim Modal */}
          <TrimModal
            open={trimModalOpen}
            setTrimModalOpen={setTrimModalOpen}
            playerRef={playerRef}
            showURL={showDetails?.ShowURL}
            isPlaying={isPlaying}
            onDuration={onDuration}
            onProgress={(state) => {
              if (state.playedSeconds >= endTime) {
                playerRef.current.seekTo(startTime, "seconds");
                setIsPlaying(false);
              }
              if (state.playedSeconds > endTime) {
                playerRef.current?.getInternalPlayer()?.pause();
              }
            }}
            startTime={startTime}
            setStartTime={setStartTime}
            endTime={endTime}
            setEndTime={setEndTime}
            duration={duration}
            setDragging={setDragging}
            roomID={roomID}
            mp4File={mp4File}
            onVideoSaved={fetchShowDetails}
            dragging={dragging}
            bucketName={bucketName}
          />
        </div>
      </div>
    </>

  );
};

export default ShowDetails;
