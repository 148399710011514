import { useMeeting } from "@videosdk.live/react-sdk";
import React, { useEffect, useMemo, useState, useRef } from "react";
import ParticipantViewer from "./ParticipantViewer";
import PresenterView from "./PresenterView";
import {
  getGridRowsAndColumns,
  getGridForMainParticipants,
  calcQuality,
  localAndPinnedOnTop,
  meetingResolutions,
} from "../../utils/common";
import useIsMobile from "../../utils/useIsMobile";
import useIsTab from "../../utils/useIsTab";
import useIsSMDesktop from "../../utils/useIsSMDesktop";
import useIsLGDesktop from "../../utils/useIsLGDesktop";
import {
  meetingLayouts,
  appThemes,
  useMeetingAppContext,
} from "../../MeetingAppContextDef";
import { useSpring, animated } from 'react-spring';
import { useTheme } from "@mui/material";
import useResponsiveSize from "../../utils/useResponsiveSize";
import { useMediaQuery } from "react-responsive";
import WhiteboardContainer, {
  convertHWAspectRatio,
} from "../../components/whiteboard/WhiteboardContainer";
import MeetingStyles from "../MeetingStyles";
import { usePubSub, Constants } from "@videosdk.live/react-sdk";
import { useDispatch, useSelector } from "react-redux";
import { setIsLogoDragging, setLogoMargin } from "../../../redux/logoSlice";

const MemoizedParticipant = React.memo(
  ParticipantViewer,
  (
    { participantId, quality, useVisibilitySensor },
    {
      participantId: oldParticipantId,
      quality: oldQuality,
      useVisibilitySensor: oldUseVisibilitySensor,
    }
  ) =>
    participantId === oldParticipantId &&
    quality === oldQuality &&
    useVisibilitySensor === oldUseVisibilitySensor
);

const MotionParticipant = ({
  participantId,
  gutter,
  quality,
  relativeHeight,
  relativeWidth,
  relativeTop,
  relativeLeft,
  useVisibilitySensor,
  meetingOverlay
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setTimeout(() => setMounted(true), 100);

    return () => {
      setTimeout(() => setMounted(false), 100);
    };
  }, []);

  const { animationsEnabled } = useMeetingAppContext();


  const animeConfig = { tension: 180, friction: 22 };

  const animatedProps = useSpring({
    top: relativeTop,
    left: relativeLeft,
    height: relativeHeight,
    width: relativeWidth,
    scale: mounted ? 1 : (animationsEnabled ? 0 : 0.5),
    config: animeConfig,
  });

  if (!animatedProps) {
    return null;
  }

  return (
    <animated.div
      style={{
        position: 'absolute',
        top: animatedProps.top.interpolate((val) => `${val}%`),
        left: animatedProps.left.interpolate((val) => `${val}%`),
        height: animatedProps.height.interpolate((val) => `${val}%`),
        width: animatedProps.width.interpolate((val) => `${val}%`),
        paddingTop: gutter,
        paddingBottom: gutter,
        paddingRight: gutter,
        paddingLeft: gutter,
        // padding: meetingOverlay && '5% 0%',
        transform: animatedProps.scale.interpolate((val) => `scale(${val})`),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          height: `calc(100% - ${2 * gutter}px)`,
          width: `calc(100% - ${2 * gutter}px)`,
        }}
      >
        <MemoizedParticipant
          participantId={participantId}
          quality={quality}
          useVisibilitySensor={useVisibilitySensor}
        />
      </div>
    </animated.div>
  );
};

const MotionParticipantContainer = ({
  participantId,
  gutter,
  quality,
  relativeHeight: height,
  relativeWidth: width,
  relativeTop: top,
  relativeLeft: left,
  useVisibilitySensor,
}) => {
  const { animationsEnabled } = useMeetingAppContext();
  console.log("animationsEnabled", animationsEnabled);
const [meetingOverlay, setMeetingOverlay] = useState("");

  usePubSub("UPDATE_MEETING_OVERLAY", {
      onMessageReceived: (data) => {
        const { message } = data;
        setMeetingOverlay(message?.OverlayUrl);
        console.log(meetingOverlay);
      },
      onOldMessagesReceived: (messages) => {
        // Check if any old message exists, and if so, set it to the state
        if (messages && messages.length > 0) {
          const latestMessage = messages[messages.length - 1].message.OverlayUrl; // Get the most recent banner message
          console.log(latestMessage)
          setMeetingOverlay(latestMessage);
        }
      },
    });

  return animationsEnabled ? (
    <MotionParticipant
      {...{
        participantId,
        gutter,
        quality,
        relativeHeight: height,
        relativeWidth: width,
        relativeTop: top,
        relativeLeft: left,
        useVisibilitySensor,
        meetingOverlay
      }}
    />
  ) : (
    <div
      style={{
        position: "absolute",
        top: `${top}%`,
        left: `${left}%`,
        height: `${height}%`,
        width: `${width}%`,
        paddingTop: gutter,
        paddingBottom: gutter,
        paddingRight: gutter,
        paddingLeft: gutter,
      }}
    >
      <div
        style={{
          height: `calc(100% - ${2 * gutter}px)`,
          width: `calc(100% - ${2 * gutter}px)`,
        }}
      >
        <MemoizedParticipant
          {...{ participantId, quality, useVisibilitySensor }}
        />
      </div>
    </div>
  );
};

export const MemoizedMotionParticipant = React.memo(
  MotionParticipantContainer,
  (prevProps, nextProps) =>
    prevProps.participantId === nextProps.participantId &&
    prevProps.gutter === nextProps.gutter &&
    prevProps.quality === nextProps.quality &&
    prevProps.relativeHeight === nextProps.relativeHeight &&
    prevProps.relativeWidth === nextProps.relativeWidth &&
    prevProps.relativeTop === nextProps.relativeTop &&
    prevProps.relativeLeft === nextProps.relativeLeft &&
    prevProps.useVisibilitySensor === nextProps.useVisibilitySensor
);

const MainViewContainer = ({
  height,
  width,
  whiteboardToolbarWidth,
  whiteboardSpacing,
  left
}) => {
  const dispatch = useDispatch()
  const mMeeting = useMeeting();

  const logoData = useSelector((state) => state?.logo);
  const sideBarData = useSelector((state) => state?.sidebar);

  console.log("sideBarData", sideBarData)

  const participants = mMeeting?.participants;
  const presenterId = mMeeting?.presenterId;
  const localParticipantId = mMeeting?.localParticipant?.id;
  const pinnedParticipants = mMeeting?.pinnedParticipants;
  const activeSpeakerId = mMeeting?.activeSpeakerId;
  const mainParticipantId = mMeeting?.mainParticipant?.id;
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);
  const [logoWidth, setLogoWidth] = useState('10');
  const { publish } = usePubSub("UPDATE_LOGO");
  const [logo, setLogo] = useState("");
  const [logoPosition, setLogoPosition] = useState({ x: 0, y: 0 });
  const [meetingBackground, setMeetingBackground] = useState("");

  const isMobile = useIsMobile();
  const isTab = useIsTab();
  const isSMDesktop = useIsSMDesktop();
  const isLGDesktop = useIsLGDesktop();

  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });

  const rowSpacing = useResponsiveSize({
    xl: 24,
    lg: 16,
    md: 14,
    sm: 12,
    xs: 8,
  });

  const gutter = 4;

  const {
    mainViewParticipants,
    sideBarMode,
    meetingLayout,
    whiteboardStarted,
    activeSortedParticipants,
    animationsEnabled,
    layoutGridSize,
    hideLocalParticipant,
    sideStackSize,
    reduceEdgeSpacing,
    mode,
    appTheme,
    meetingResolution,
    isRecorder,
  } = useMeetingAppContext();

  const lastActiveParticipantId = useMemo(
    () => activeSortedParticipants[0]?.participantId,
    [activeSortedParticipants]
  );

  const mobilePortrait = isMobile && isPortrait;

  const {
    spotlightParticipantId,
    sideBarPinnedParticipantIds,
    spotlightMediaType
  } = useMemo(() => {
    let pinnedParticipantIds = [...pinnedParticipants.keys()];

    const pinnedPresenter =
      pinnedParticipantIds.findIndex((id) => id === presenterId) !== -1;

    if (isRecorder && pinnedParticipantIds.length > layoutGridSize) {
      if (pinnedPresenter) {
        const pinnedParticipantIndexToBeRemoved =
          pinnedParticipantIds.findIndex((id) => id === presenterId);

        pinnedParticipantIds.splice(pinnedParticipantIndexToBeRemoved, 1);

        pinnedParticipantIds = [pinnedPresenter, ...pinnedParticipantIds];
      }

      pinnedParticipantIds = pinnedParticipantIds.slice(0, layoutGridSize);
    }

    let obj;

    if (pinnedPresenter) {
      obj = {
        spotlightParticipantId: presenterId,
        sideBarPinnedParticipantIds:
          meetingLayout === meetingLayouts.SPOTLIGHT
            ? []
            : pinnedParticipantIds,
        spotlightMediaType: "SHARE",
      };
    } else if (whiteboardStarted) {
      if (meetingLayout === meetingLayouts.SPOTLIGHT) {
        obj = {
          spotlightParticipantId: "white-board-id",
          sideBarPinnedParticipantIds: [],
          spotlightMediaType: "WHITEBOARD",
        };
      } else {
        obj = {
          spotlightParticipantId: "white-board-id",
          sideBarPinnedParticipantIds: pinnedParticipantIds,
          spotlightMediaType: "WHITEBOARD",
        };
      }
    } else {
      if (meetingLayout === meetingLayouts.SPOTLIGHT) {
        obj = {
          spotlightParticipantId: null,
          sideBarPinnedParticipantIds: pinnedParticipantIds,
          spotlightMediaType: null,
        };
      } else {
        const spotlightParticipantId = pinnedParticipantIds[0];
        pinnedParticipantIds.splice(0, 1);

        obj = {
          spotlightParticipantId: spotlightParticipantId,
          sideBarPinnedParticipantIds: pinnedParticipantIds,
          spotlightMediaType: "WEBCAM",
        };
      }
    }

    if (mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR) {
      const splicesActiveParticipants = obj[
        "sideBarPinnedParticipantIds"
      ].splice(0, 4);

      obj["sideBarPinnedParticipantIds"] = splicesActiveParticipants;
    }

    const participantsCount = obj.sideBarPinnedParticipantIds?.length;

    if (participantsCount) {
      const pinnedParticipantsMap = new Map(pinnedParticipants);

      if (obj["spotlightMediaType"] === "WEBCAM") {
        if (obj["spotlightParticipantId"]) {
          pinnedParticipantsMap.delete(obj["spotlightParticipantId"]);
        }
      }

      const gridInfo = getGridRowsAndColumns({
        participantsCount,
        isMobile:
          mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR
            ? false
            : isMobile,
        isTab:
          mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR
            ? false
            : isTab,
        isSMDesktop:
          mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR
            ? false
            : isSMDesktop,
        isLGDesktop:
          mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR
            ? true
            : isLGDesktop,
        isLandscape:
          mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR
            ? true
            : !isPortrait,
        isPresenting:
          mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR
            ? false
            : !!obj.spotlightParticipantId,
      });

      const { singleRow } = getGridForMainParticipants({
        participants: localAndPinnedOnTop({
          localParticipantId,
          participants: [...obj.sideBarPinnedParticipantIds],
          pinnedParticipantIds: [...pinnedParticipantsMap.keys()],
          moveLocalUnpinnedOnTop: false,
        }),
        gridInfo,
      });

      obj["singleRow"] = singleRow;
    } else {
      obj["singleRow"] = [];
    }

    return obj;
  }, [
    mobilePortrait,
    pinnedParticipants,
    meetingLayout,
    presenterId,
    localParticipantId,
    isMobile,
    isTab,
    isSMDesktop,
    isLGDesktop,
    isPortrait,
    whiteboardStarted,
    isRecorder,
    layoutGridSize,
  ]);

  const mainScreenViewActive = useMemo(
    () =>
      presenterId ||
      whiteboardStarted ||
      meetingLayout === meetingLayouts.UNPINNED_SIDEBAR ||
      meetingLayout === meetingLayouts.UNPINNED_SPOTLIGHT,
    [presenterId, whiteboardStarted, meetingLayout]
  );

  const { singleRow, mainLayoutParticipantId } = useMemo(() => {
    let mainLayoutParticipantId;

    let _pinnedParticipants = new Map(pinnedParticipants);

    let mainParticipants = [...mainViewParticipants];

    if (presenterId || whiteboardStarted) {
      const remainingParticipants = [...participants.keys()].filter(
        (pId) => mainParticipants.findIndex((id) => id === pId) === -1
      );

      mainParticipants = [...mainParticipants, ...remainingParticipants];
    }

    if (hideLocalParticipant) {
      mainParticipants = mainParticipants.filter(
        (id) => id !== localParticipantId
      );

      _pinnedParticipants.delete(localParticipantId);
      _pinnedParticipants = new Map(_pinnedParticipants);
    }

    if (meetingLayout === meetingLayouts.UNPINNED_SIDEBAR) {
      if (!(!!presenterId || !!whiteboardStarted)) {
        if (_pinnedParticipants.size === 0) {
          if (activeSpeakerId) {
            mainParticipants = mainParticipants.filter(
              (id) => id !== activeSpeakerId
            );
            mainLayoutParticipantId = activeSpeakerId;
          } else {
            mainParticipants = mainParticipants.filter(
              (id) => id !== lastActiveParticipantId
            );
            mainLayoutParticipantId = lastActiveParticipantId;
          }
        } else {
          if (activeSpeakerId) {
            const pinnedActiveSpeaker =
              _pinnedParticipants.get(activeSpeakerId);

            if (pinnedActiveSpeaker) {
              mainParticipants = mainParticipants.filter(
                (id) => id !== activeSpeakerId
              );

              _pinnedParticipants.delete(activeSpeakerId);
              _pinnedParticipants = new Map(_pinnedParticipants);

              mainLayoutParticipantId = activeSpeakerId;
            } else {
              const firstPinnedParticipantId = _pinnedParticipants
                .keys()
                .next().value;

              mainParticipants = mainParticipants.filter(
                (id) => id !== firstPinnedParticipantId
              );

              _pinnedParticipants.delete(firstPinnedParticipantId);
              _pinnedParticipants = new Map(_pinnedParticipants);

              mainLayoutParticipantId = firstPinnedParticipantId;
            }
          } else {
            const lastActivePinnedParticipant = _pinnedParticipants.get(
              lastActiveParticipantId
            );

            if (lastActivePinnedParticipant) {
              mainParticipants = mainParticipants.filter(
                (id) => id !== lastActiveParticipantId
              );
              _pinnedParticipants.delete(lastActiveParticipantId);
              _pinnedParticipants = new Map(_pinnedParticipants);

              mainLayoutParticipantId = lastActiveParticipantId;
            } else {
              const firstPinnedParticipantId = _pinnedParticipants
                .keys()
                .next().value;

              mainParticipants = mainParticipants.filter(
                (id) => id !== firstPinnedParticipantId
              );

              _pinnedParticipants.delete(firstPinnedParticipantId);
              _pinnedParticipants = new Map(_pinnedParticipants);

              mainLayoutParticipantId = firstPinnedParticipantId;
            }
          }
        }
      } else {
        if (_pinnedParticipants.size === 0) {
          if (activeSpeakerId) {
            let activeIndex = mainParticipants.findIndex(
              (id) => id === activeSpeakerId
            );

            if (activeIndex !== -1) {
              const firstMainParticipantId = mainParticipants[0];
              mainParticipants[0] = activeSpeakerId;
              mainParticipants[activeIndex] = firstMainParticipantId;

              _pinnedParticipants.delete(firstMainParticipantId);
              _pinnedParticipants = new Map(_pinnedParticipants);
            } else {
              const mainGridRemovedParticipantId = mainParticipants.pop();

              mainParticipants[0] = activeSpeakerId;

              _pinnedParticipants.delete(mainGridRemovedParticipantId);
              _pinnedParticipants = new Map(_pinnedParticipants);
            }
          } else {
          }
        } else {
          if (activeSpeakerId) {
          } else {
          }
        }
      }
    } else if (meetingLayout === meetingLayouts.UNPINNED_SPOTLIGHT) {
      if (!!presenterId || !!whiteboardStarted) {
        mainParticipants = [];
      } else {
        mainParticipants = [];
        mainLayoutParticipantId = activeSpeakerId || lastActiveParticipantId;
      }
    }

    let participantsCount = mainParticipants?.length;

    if (participantsCount > layoutGridSize) {
      mainParticipants = mainParticipants.slice(0, layoutGridSize);
      const remainingMainParticipants = mainParticipants.splice(layoutGridSize);

      remainingMainParticipants.forEach((p) => {
        _pinnedParticipants.delete(p);
      });

      _pinnedParticipants = new Map(_pinnedParticipants);

      participantsCount = mainParticipants?.length;
    }

    if (mainScreenViewActive && participantsCount > sideStackSize) {
      mainParticipants = mainParticipants.slice(0, sideStackSize);
      const remainingMainParticipants = mainParticipants.splice(sideStackSize);

      remainingMainParticipants.forEach((p) => {
        _pinnedParticipants.delete(p);
      });

      _pinnedParticipants = new Map(_pinnedParticipants);

      participantsCount = mainParticipants?.length;
    }

    const gridInfo = getGridRowsAndColumns({
      participantsCount,
      isMobile,
      isTab,
      isSMDesktop,
      isLGDesktop,
      isLandscape: !isPortrait,
      isPresenting: !!mainScreenViewActive,
    });

    const { singleRow } = getGridForMainParticipants({
      participants: localAndPinnedOnTop({
        localParticipantId: hideLocalParticipant ? null : localParticipantId,
        participants: mainParticipants,
        pinnedParticipantIds:
          meetingLayout === meetingLayouts.UNPINNED_SIDEBAR ||
            meetingLayout === meetingLayouts.UNPINNED_SPOTLIGHT
            ? []
            : [..._pinnedParticipants.keys()],
        moveLocalUnpinnedOnTop:
          _pinnedParticipants.size && meetingLayout !== meetingLayouts.GRID
            ? false
            : true,
      }),
      gridInfo,
    });

    return { singleRow, mainLayoutParticipantId };
  }, [
    meetingLayout,
    participants,
    mainViewParticipants,
    isPortrait,
    isMobile,
    isTab,
    isSMDesktop,
    isLGDesktop,
    presenterId,
    whiteboardStarted,
    localParticipantId,
    pinnedParticipants,
    activeSpeakerId,
    lastActiveParticipantId,
    mainParticipantId,
    layoutGridSize,
    hideLocalParticipant,
    sideStackSize,
    mainScreenViewActive,
  ]);

  const spacing = reduceEdgeSpacing ? 4 : rowSpacing - gutter;
  console.log("rowSpacing", rowSpacing)
  console.log("spacing", spacing)

  const theme = useTheme();

  const presentingSideBarWidth = useResponsiveSize({
    xl: 320,
    lg: 280,
    md: 260,
    sm: 240,
    xs: 200,
  });

  usePubSub("UPDATE_LOGO", {
    onMessageReceived: (data) => {
      const { message } = data;
      console.log('messageInMainView', message);
      setLogo(message?.LogoUrl);
      setLogoWidth(message?.width);
      if (message.LogoAxisX || message.LogoAxisY) {
        setLogoPosition({ x: message.LogoAxisX, y: message.LogoAxisY });
      }
    },
    onOldMessagesReceived: (messages) => {
      // Check if any old message exists, and if so, set it to the state
      if (messages && messages.length > 0) {
        const latestMessage = messages[messages.length - 1].LogoUrl; // Get the most recent banner message
        setLogo(latestMessage?.LogoUrl);
        setLogoWidth(latestMessage?.width);
      }
    },
  });

  usePubSub("UPDATE_MEETING_BACKGROUND", {
    onMessageReceived: (data) => {
      const { message } = data;
      console.log("setMeetingBackground0987", message?.BackgroundUrl);

      setMeetingBackground(message?.BackgroundUrl);
    },
    onOldMessagesReceived: (messages) => {
      // Check if any old message exists, and if so, set it to the state
      if (messages && messages.length > 0) {
        const latestMessage = messages[messages.length - 1].message.BackgroundUrl; // Get the most recent banner message
        setMeetingBackground(latestMessage);
      }
    },
  });

  const mainContainerHorizontalPadding = useMemo(
    () =>
      reduceEdgeSpacing
        ? 0
        : presenterId ||
          whiteboardStarted ||
          (mainLayoutParticipantId && singleRow.length !== 0)
          ? 0
          : typeof sideBarMode === "string"
            ? 0
            : mainViewParticipants?.length <= 9
              ? isLGDesktop
                ? !mainScreenViewActive && singleRow.length === 2
                  ? 0
                  : 140
                : isSMDesktop
                  ? !mainScreenViewActive && singleRow.length === 2
                    ? 20
                    : 90
                  : isTab && !isPortrait
                    ? 60
                    : 0
              : 0,
    [
      mainScreenViewActive,
      sideBarMode,
      mainViewParticipants,
      isLGDesktop,
      isSMDesktop,
      singleRow,
      isTab,
      isPortrait,
      whiteboardStarted,
      presenterId,
      mainLayoutParticipantId,
      meetingLayout,
      reduceEdgeSpacing,
    ]
  );

  const gridVerticalSpacing = useResponsiveSize({
    xl: 160,
    lg: 90,
    md: 90,
    sm: 60,
    xs: 60,
  });

  const actualPresentingSideBarWidth = useMemo(
    () => (singleRow.length ? presentingSideBarWidth : 0),
    [singleRow, presentingSideBarWidth]
  );

  const [meetingOverlay, setMeetingOverlay] = useState("");

  usePubSub("UPDATE_MEETING_OVERLAY", {
    onMessageReceived: (data) => {
      const { message } = data;
      setMeetingOverlay(message?.OverlayUrl);
      console.log(meetingOverlay);
    },
    onOldMessagesReceived: (messages) => {
      // Check if any old message exists, and if so, set it to the state
      if (messages && messages.length > 0) {
        const latestMessage = messages[messages.length - 1].message.OverlayUrl; // Get the most recent banner message
        console.log(latestMessage)
        setMeetingOverlay(latestMessage);
      }
    },
  });
  const audioRef = useRef(null);
  usePubSub("UPDATE_MUSIC", {
    onMessageReceived: (data) => {
      const { action, url } = data;

      if (!audioRef.current) {
        audioRef.current = new Audio(url); // Create the audio element if it doesn't exist
      }

      const audio = audioRef.current;

      if (action === "play" && url) {
        audio.src = url; // Set the audio URL when playing
        audio.play();
      } else if (action === "pause") {
        audio.pause();
      }
    },

    onOldMessagesReceived: (messages) => {
      if (messages && messages.length > 0) {
        const { action, url } = messages[messages.length - 1].data;

        if (!audioRef.current) {
          audioRef.current = new Audio(url); // Create the audio element if it doesn't exist
        }

        const audio = audioRef.current;

        if (action === "play" && url) {
          audio.src = url;
          audio.play();
        } else if (action === "pause") {
          audio.pause();
        }
      }
    },
  });

  const handleMouseUp = () => {
    setIsDragging(false);
    dispatch(setIsLogoDragging(false))
  };

  const handleMouseMove = (e) => {
    if (!logoData?.isLogoDragging) return;

    const container = containerRef.current;
    const { left, top, width, height } = container.getBoundingClientRect();
    const { offsetX, offsetY } = logoData;

    let logoSize = logoData?.logoSize
    let logosize = parseFloat(logoSize) * 16

    let newX = Math.max(0, Math.min(e.clientX - left - offsetX, width - logosize));
    let newY = Math.max(0, Math.min(e.clientY - top - offsetY, height - logosize));

    newX = (newX / width) * 100;
    newY = (newY / height) * 100;

    dispatch(setLogoMargin({ logoMarginX: `${newX}%`, logoMarginY: `${newY}%` }))

    publish(
      { LogoUrl: logo, LogoAxisX: `${newX}%`, LogoAxisY: `${newY}%`, visibility: true, width: logoWidth },
      { persist: true }
    );
  };


  console.log("actualSideBarWidth", actualPresentingSideBarWidth);
  console.log("main", mainScreenViewActive)
  console.log("PD", mainContainerHorizontalPadding)
  console.log("gridVerticalSpacing", gridVerticalSpacing)


  // return participants ? (
  return (
    <>
      {/* <div
        ref={containerRef}
        style={{
          height,
          width,
          backgroundImage: `url(${meetingBackground})`,
          backgroundSize: 'cover', // Adjust the image size to cover the entire div
          backgroundPosition: 'center', // Center the image
          overflow: 'hidden',
          backgroundColor:
            appTheme === appThemes.DARK
              ? theme.palette.darkTheme.main
              : appTheme === appThemes.LIGHT
                ? theme.palette.lightTheme.main
                : theme.palette.background.default,
          overflow: "hidden",
          transition: `width ${400 * (animationsEnabled ? 1 : 0.5)}ms`,
          transitionTimingFunction: "ease-in-out",
          display: "flex",
          marginLeft: left,
          position: 'relative',
          placeSelf: 'center',
          flexDirection:
            mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR
              ? 'column'
              : 'row',
        }}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      > */}

      {/* 1.OverLay */}
      <div
        style={{
          position: 'absolute', // Absolutely position the background image
          top: '0',
          left: '0',
          // right: guest? '0px': '80px',
          width: '100%',
          height: '100%', // Ensure the div fills its container
          backgroundImage: `url(${meetingOverlay})`,
          backgroundSize: '100% 100%', // Ensures it covers the entire div
          backgroundPosition: 'center', // Centers the image
          backgroundRepeat: 'no-repeat', // Prevents tiling
          zIndex: 1000, // Ensure it's below other elements
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      ></div>

      {
        participants && (
          <div
            style={{
              position: 'absolute',
              // height: height,
              height:'100%',
              // width: width - (6 * spacing),
              width: '100%',
              // width: (spotlightParticipantId ? width : 0) - (singleRow.length === 0 ? 2 * mainContainerHorizontalPadding : 0),
              transition: `all ${800 * (animationsEnabled ? 1 : 0.5)}ms`,
              transitionTimingFunction: "ease-in-out",
              display: "flex",
              flexDirection: "row",
              alignItems:"center",
              justifyContent:"center"

            }}
          >
            <div
              style={{
                // height: height,
                height: '100%',
                // width: width - (8 * spacing),
                width: '100%',
                // width: mobilePortrait
                //   ? width - 2 * spacing
                //   : (spotlightParticipantId
                //     ? '100%'
                //     : 0) - (singleRow.length === 0 ? 2 * mainContainerHorizontalPadding : 0),
                transition: `width ${800 * (animationsEnabled ? 1 : 0.5)}ms`,
                transitionTimingFunction: "ease-in-out",
                borderRadius: theme.spacing(1),
                position: "relative",
                display: "flex", // Add this to create a row layout
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row", // Make sure items align horizontally
                padding: meetingOverlay && '5% 0%',
              }}
            >
              {whiteboardStarted && (
                <WhiteboardContainer
                  {...{
                    ...convertHWAspectRatio({
                      height:
                        height -
                        2 * spacing -
                        (whiteboardToolbarWidth === 0 ? 2 * 16 : 0),
                      width: whiteboardStarted
                        ? width -
                        (isMobile ? 0 : actualPresentingSideBarWidth) -
                        2 * spacing -
                        (whiteboardToolbarWidth + 2 * whiteboardSpacing) -
                        (whiteboardToolbarWidth === 0 ? 2 * 16 : 0)
                        : 0,
                    }),
                    whiteboardToolbarWidth,
                    whiteboardSpacing,
                    originalHeight: height,
                    originalWidth: whiteboardStarted
                      ? width -
                      (isMobile ? 0 : actualPresentingSideBarWidth) -
                      2 * spacing
                      : 0,
                  }}
                />
              )}

              {presenterId && (
                <div
                  style={{
                    position: 'absolute',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: height - spacing,
                    width: width - 9 * spacing,
                  }}>
                  <PresenterView presenterId={presenterId} />
                </div>

              )}

              {!presenterId && !whiteboardStarted && mainLayoutParticipantId && (

                <div
                  style={{
                    position: "relative",
                    transition: `height ${800 * (animationsEnabled ? 1 : 0.5)}ms`,
                    transitionTimingFunction: "ease-in-out",
                    height: '100%',
                    width: meetingLayout === 'UNPINNED_SIDEBAR' && singleRow.length > 0 ? '80%' : '100%',
                  }}
                >
                  <MemoizedMotionParticipant
                    {...{
                      participantId: mainLayoutParticipantId,
                      gutter,
                      quality: meetingResolution
                        ? meetingResolution === meetingResolutions.SD
                          ? "s1t2"
                          : meetingResolution === meetingResolutions.HD && "s2t2"
                        : "s2t2",
                      relativeHeight: 100,
                      relativeWidth: 100,
                      relativeTop: 0,
                      relativeLeft: 0,
                    }}
                    key={`mainLayoutParticipantId_${mainLayoutParticipantId}`}
                  />
                </div>


              )}
              {
                (!presenterId && ((isMobile && mainScreenViewActive) ? null : singleRow.length <=
                  0 ? null : (
                  <div
                    style={{
                      // backgroundColor:
                      //   appTheme === appThemes.DARK
                      //     ? theme.palette.darkTheme.main
                      //     : appTheme === appThemes.LIGHT
                      //       ? theme.palette.lightTheme.main
                      //       : theme.palette.background.default,
                      // overflowX: "hidden",
                      // overflowY: mainScreenViewActive ? "scroll" : "hidden",
                      // width: mainScreenViewActive
                      //   ? actualPresentingSideBarWidth
                      //   : width - 2 * spacing,
                      // height: '90vh',
                      height: '100%',
                      width: mainScreenViewActive
                        ? actualPresentingSideBarWidth
                        : '20%',
                      // width:'20%',
                      // margin: spacing,
                      transition: `all ${800 * (animationsEnabled ? 1 : 0.5)}ms`,
                      transitionTimingFunction: "ease-in-out",
                      paddingLeft:
                        mainContainerHorizontalPadding +
                        (!mainScreenViewActive &&
                          singleRow.length > 12 &&
                          singleRow.length < 17 &&
                          typeof sideBarMode !== "string"
                          ? 160
                          : 0),
                      paddingRight:
                        mainContainerHorizontalPadding +
                        (!mainScreenViewActive &&
                          singleRow.length > 12 &&
                          singleRow.length < 17 &&
                          typeof sideBarMode !== "string"
                          ? 160
                          : 0),
                      paddingTop:
                        singleRow.length === 2 && !mainScreenViewActive && !isMobile
                          ? gridVerticalSpacing
                          : 0,
                      // padding: meetingOverlay && '3% 0%',
                      overflowY: "scroll",
                    }}
                  >
                    <div
                      style={{
                        // height:
                        //   (mainScreenViewActive
                        //     ? (singleRow.length * actualPresentingSideBarWidth * 2) / 4
                        //     : height - 2 * spacing) -
                        //   (singleRow.length === 2 && !mainScreenViewActive && !isMobile
                        //     ? 2 * gridVerticalSpacing
                        //     : 0),
                        height:
                          (mainScreenViewActive
                            ? (meetingOverlay ? (singleRow.length * actualPresentingSideBarWidth * 2) / 4 : (singleRow.length * actualPresentingSideBarWidth * 2) / 3)
                            : height - 2 * spacing) -
                          (singleRow.length === 2 && !mainScreenViewActive && !isMobile
                            ? 2 * gridVerticalSpacing
                            : 0),
                        position: "relative",
                        transition: `height ${800 * (animationsEnabled ? 1 : 0.5)}ms`,
                        transitionTimingFunction: "ease-in-out",
                        zIndex: meetingOverlay ? "900" : "1000",
                      }}
                    >
                      {/* {singleRow.map((c) => ( */}
                      {((meetingLayout === meetingLayouts.UNPINNED_SIDEBAR && meetingOverlay) ? singleRow.slice(0, 4) : singleRow).map((c) => (
                        <MemoizedMotionParticipant
                          quality={
                            meetingResolution
                              ? meetingResolution === meetingResolutions.SD
                                ? "s1t2"
                                : meetingResolution === meetingResolutions.HD && "s2t2"
                              : calcQuality(mainViewParticipants?.length || 1)
                          }
                          key={`main_participant_${c.participantId}`}
                          {...c}
                          gutter={gutter}
                          useVisibilitySensor={mainScreenViewActive ? true : false}
                        />
                      ))}
                    </div>
                  </div>
                )))
              }
            </div>
          </div>
        )
      }

      {/* OLDLOGIC */}
      {/* <div
        style={{
          // width: presenterId
          //   ? width - presentingSideBarWidth
          //   : 0,
          // height: !presenterId ? height / 2 : height,
          transition: `all ${800 * (animationsEnabled ? 1 : 0.5)}ms`,
          transitionTimingFunction: "ease-in-out",
          // paddingLeft: spacing,
          // paddingTop: spacing,
          width: '100%',
          height: '100%',
        }}
      >
        <div
          style={{
            height: height - 2 * spacing,
            // width: mobilePortrait
            //   ? width - 2 * spacing
            //   : (spotlightParticipantId
            //     ? (sideBarPinnedParticipantIds?.length > 0
            //       ? width
            //       : width) -
            //     2 * spacing
            //     : 0) -
            //   (singleRow.length === 0
            //     ? 2 * mainContainerHorizontalPadding
            //     : 0),
            width: mobilePortrait
              ? width - 2 * spacing
              : (spotlightParticipantId
                ? width - 2 * spacing
                : 0) - (singleRow.length === 0 ? 2 * mainContainerHorizontalPadding : 0),
            // height: height,
            // backgroundColor:
            //   presenterId || whiteboardStarted
            //     ? appTheme === appThemes.DARK
            //       ? theme.palette.darkTheme.slightLighter
            //       : appTheme === appThemes.LIGHT
            //         ? theme.palette.lightTheme.two
            //         : theme.palette.background.paper
            //     : undefined,
            // backgroundColor:'red',
            transition: `width ${800 * (animationsEnabled ? 1 : 0.5)}ms`,
            transitionTimingFunction: "ease-in-out",
            borderRadius: theme.spacing(1),
            // overflow: "hidden",
            position: "relative",

          }}
        >
          {whiteboardStarted && (
            <WhiteboardContainer
              {...{
                ...convertHWAspectRatio({
                  height:
                    height -
                    2 * spacing -
                    (whiteboardToolbarWidth === 0 ? 2 * 16 : 0),
                  width: whiteboardStarted
                    ? width -
                    (isMobile ? 0 : actualPresentingSideBarWidth) -
                    2 * spacing -
                    (whiteboardToolbarWidth + 2 * whiteboardSpacing) -
                    (whiteboardToolbarWidth === 0 ? 2 * 16 : 0)
                    : 0,
                }),
                whiteboardToolbarWidth,
                whiteboardSpacing,
                originalHeight: height - 2 * spacing,
                originalWidth: whiteboardStarted
                  ? width -
                  (isMobile ? 0 : actualPresentingSideBarWidth) -
                  2 * spacing
                  : 0,
              }}
            />
          )}

          {presenterId && <PresenterView presenterId={presenterId} />}

          {!presenterId && !whiteboardStarted && mainLayoutParticipantId && (

            <div
              style={{
                // position: "absolute",
                // top: 0,
                // bottom: 0,
                // left:
                //   singleRow.length === 0 ? mainContainerHorizontalPadding : 0,
                // right:
                //   singleRow.length === 0 ? mainContainerHorizontalPadding : 0,
                position: "relative",
                transition: `height ${800 * (animationsEnabled ? 1 : 0.5)}ms`,
                transitionTimingFunction: "ease-in-out",
                height: '100%',
                width: '100%',
                // width: sideBarData.type === true ? '89%' : '100%',
              }}
            >
              <MemoizedMotionParticipant
                {...{
                  participantId: mainLayoutParticipantId,
                  gutter,
                  quality: meetingResolution
                    ? meetingResolution === meetingResolutions.SD
                      ? "s1t2"
                      : meetingResolution === meetingResolutions.HD && "s2t2"
                    : "s2t2",
                  relativeHeight: 100,
                  relativeWidth: 100,
                  relativeTop: 0,
                  relativeLeft: 0,
                }}
                key={`mainLayoutParticipantId_${mainLayoutParticipantId}`}
              />
            </div>


          )}

        </div>
      </div> */}
      {/* {
        isMobile && mainScreenViewActive ? null : singleRow.length <=
          0 ? null : (
          <div
            style={{
              backgroundColor:
                appTheme === appThemes.DARK
                  ? theme.palette.darkTheme.main
                  : appTheme === appThemes.LIGHT
                    ? theme.palette.lightTheme.main
                    : theme.palette.background.default,
              overflowX: "hidden",
              overflowY: mainScreenViewActive ? "scroll" : "hidden",
              width: mainScreenViewActive
                ? actualPresentingSideBarWidth
                : width - 2 * spacing,
              // height: '90vh',
              height: '100%',
              // margin: spacing,
              transition: `all ${800 * (animationsEnabled ? 1 : 0.5)}ms`,
              transitionTimingFunction: "ease-in-out",
              paddingLeft:
                mainContainerHorizontalPadding +
                (!mainScreenViewActive &&
                  singleRow.length > 12 &&
                  singleRow.length < 17 &&
                  typeof sideBarMode !== "string"
                  ? 160
                  : 0),
              paddingRight:
                mainContainerHorizontalPadding +
                (!mainScreenViewActive &&
                  singleRow.length > 12 &&
                  singleRow.length < 17 &&
                  typeof sideBarMode !== "string"
                  ? 160
                  : 0),
              paddingTop:
                singleRow.length === 2 && !mainScreenViewActive && !isMobile
                  ? gridVerticalSpacing
                  : 0,
              marginLeft: "auto",
              border: '1px solid yellow'
            }}
          >
            <div
              style={{
                height:
                  (mainScreenViewActive
                    ? (singleRow.length * actualPresentingSideBarWidth * 2) / 3
                    : height) -
                  2 * spacing -
                  (singleRow.length === 2 && !mainScreenViewActive && !isMobile
                    ? 2 * gridVerticalSpacing
                    : 0),
                position: "relative",
                transition: `height ${800 * (animationsEnabled ? 1 : 0.5)}ms`,
                transitionTimingFunction: "ease-in-out",
                border: '1px solid red'
              }}
            >
              {singleRow.map((c) => (
                <MemoizedMotionParticipant
                  quality={
                    meetingResolution
                      ? meetingResolution === meetingResolutions.SD
                        ? "s1t2"
                        : meetingResolution === meetingResolutions.HD && "s2t2"
                      : calcQuality(mainViewParticipants?.length || 1)
                  }
                  key={`main_participant_${c.participantId}`}
                  {...c}
                  gutter={gutter}
                  useVisibilitySensor={mainScreenViewActive ? true : false}
                />
              ))}
            </div>
          </div>
        )
      } */}
      {/* </div> */}
    </>
  )
  // : null;
};

export default React.memo(
  MainViewContainer,
  (prevProps, nextProps) =>
    prevProps.width === nextProps.width &&
    prevProps.height === nextProps.height &&
    prevProps.whiteboardToolbarWidth === nextProps.whiteboardToolbarWidth &&
    prevProps.whiteboardSpacing === nextProps.whiteboardSpacing
);
