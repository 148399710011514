import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Button, Slider } from "@mui/material";
import { useMeeting, usePubSub } from "@videosdk.live/react-sdk";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLogoFile,setLogoSize } from "../redux/logoSlice";
import { getLogos, createLogo, deleteLogo, updateMeetingLogo, createMeetingLogo } from "../Services/BannersApi";
import { FaEye, FaEyeSlash, FaTrash } from 'react-icons/fa'; // Import Font Awesome icons

const LogosPanel = () => {
    const { publish } = usePubSub("UPDATE_LOGO");
    const mMeeting = useMeeting();
    const dispatch = useDispatch();
    // const logoFile = useSelector((state) => state?.logo);
    const fileInputRef = useRef(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const meetingId = queryParams.get("meetingId");

    const [logos, setLogos] = useState([]);
    const [newLogo, setNewLogo] = useState(null);
    const [isCreatingLogo, setIsCreatingLogo] = useState(false);
    const [selectedLogo, setSelectedLogo] = useState(null);
    const [hoveredLogo, setHoveredLogo] = useState(null);
    const [logoWidth, setLogoWidth] = useState('10'); // Initial width is 10em

    useEffect(() => {
        fetchLogos();
    }, []);

    const fetchLogos = async () => {
        try {
            const response = await getLogos();
            setLogos(response?.Data);
            const selectedLogoId = localStorage.getItem('selectedLogoId');
            if (selectedLogoId) {
                const logo = response?.Data?.find(logo => logo.LogoID === selectedLogoId);
                setSelectedLogo(logo);
            }
        } catch (error) {
            console.error('Error fetching logos:', error);
        }
    };

    const handleLogoClick = async (logo) => {
        if (selectedLogo === logo) {
            setSelectedLogo(null);
            dispatch(setLogoFile(null));
            publish({ LogoId: null, LogoUrl: null, visibility: false, width: null }, { persist: true });
            localStorage.removeItem('selectedLogoId');
            if (mMeeting?.isRecording) {
                await updateMeetingLogo({ LogoID: logo.LogoID, MeetingID: meetingId, EndTime: getCurrentTimestamp() });
            }
        } else {
            setSelectedLogo(logo);
            dispatch(setLogoFile(logo?.LogoUrl));
            publish({ LogoId: logo.LogoID, LogoUrl: logo.LogoUrl, visibility: true, width: `${logoWidth}em` }, { persist: true });
            localStorage.setItem('selectedLogoId', logo.LogoID);
            if (mMeeting?.isRecording) {
                await createMeetingLogo({ LogoID: logo.LogoID, MeetingID: meetingId, StartTime: getCurrentTimestamp() });
            }
        }
    };

    const handleWidthChange = (event, newValue) => {
        dispatch(setLogoSize(newValue));
        setLogoWidth(newValue);
        if (selectedLogo) {
            publish({ LogoId: selectedLogo.LogoID, LogoUrl: selectedLogo.LogoUrl, visibility: true, width: `${newValue}em` }, { persist: true });
        }
    };

    const triggerFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewLogo(file);
            setIsCreatingLogo(true);
            // dispatch(setLogoFile(file));

        }
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleSaveLogo = async () => {
        if (newLogo) {
            try {
                const base64Image = await convertToBase64(newLogo);
                const logoData = {
                    UserID: localStorage.getItem('user_id'),
                    LogoBase64: base64Image.substring("data:image/png;base64,".length),
                };
                const response = await createLogo(logoData);
                if (response && response.data) {
                    const logosResponse = await getLogos();
                    setLogos(logosResponse?.Data);
                }
                setNewLogo(null);
                setIsCreatingLogo(false);
            } catch (error) {
                console.error("Error saving logo:", error);
            }
        }
    };

    const handleDeleteLogo = async (logo) => {
        try {
            if (selectedLogo === logo) {
                setSelectedLogo(null);
                publish({ LogoId: null, LogoUrl: null, visibility: false, width: null }, { persist: true });
            }
            await deleteLogo(logo.LogoID);
            fetchLogos();
        } catch (error) {
            console.error("Error deleting logo:", error);
        }
    };

    function getCurrentTimestamp() {
        return new Date().toISOString();
    }

    return (
        <Box color="white" className="px-3">
            <div>
                <div className="my-2">
                    <Typography
                        variant="h6"
                        onClick={triggerFileInput}
                        style={{
                            cursor: 'pointer',
                            width: '100%',
                            textAlignLast: 'center',
                            padding: '2%',
                            borderRadius: '10px',
                        }}
                        className="schedule-btn"
                    >
                        Add a New Logo
                    </Typography>
                </div>

                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept="image/png"
                    onChange={handleFileChange}
                />

                {isCreatingLogo && newLogo && (
                    <div style={{ marginTop: "20px", textAlign: "center" }}>
                        <img
                            src={URL.createObjectURL(newLogo)}
                            alt="Logo Preview"
                            style={{ width: "3em", height: "3em", objectFit: "contain" }}
                        />
                        <div className="py-3">
                            <Button variant="contained" color="primary" onClick={handleSaveLogo}>
                                Save Logo
                            </Button>
                        </div>
                    </div>
                )}


                <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>

                    {logos?.map((logo, index) => (
                        <div
                            key={index}
                            className="logoData pointer"
                            onMouseEnter={() => setHoveredLogo(logo)}
                            onMouseLeave={() => setHoveredLogo(null)}
                            onClick={() => handleLogoClick(logo)}
                            style={{
                                position: "relative",
                                width: "45%",
                                marginBottom: "10px",
                                backgroundColor:
                                    selectedLogo === logo
                                        ? "#ffffff50"
                                        : hoveredLogo === logo
                                            ? "#ffffff30"
                                            : "transparent",
                            }}
                        >
                            <img
                                src={logo.LogoUrl}
                                alt={`Logo ${index}`}
                                style={{ width: "100%", height: "100px", objectFit: "contain" }}
                            />

                            {/* The buttons are visible when hovered */}
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                    color: 'white',
                                    padding: '10px 20px',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    visibility: selectedLogo === logo || hoveredLogo === logo ? 'visible' : 'hidden',
                                    display: 'flex', // Use flexbox for horizontal alignment
                                    // gap: '10px', // Add space between icons
                                    transition: 'transform 0.3s ease-out, opacity 0.3s ease-out',
                                }}
                            >
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleLogoClick(logo);
                                    }}
                                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                >
                                    {selectedLogo === logo ? <FaEyeSlash /> : <FaEye />} {/* Show or hide icon */}
                                    <FaTrash
                                        style={{
                                            marginLeft: '10px',
                                            cursor: 'pointer',
                                            color: 'red',
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteLogo(logo);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
                {selectedLogo && (
                    <div style={{ marginTop: "20px", width: "100%" }}>
                        <Typography variant="h6">Adjust Logo Width</Typography>
                        <Slider
                            value={logoWidth}
                            min={1}
                            max={50}
                            step={1}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) => `${value}em`}
                            onChange={handleWidthChange}
                            style={{ width: "80%", marginLeft: "10px" }}
                        />
                    </div>
                )}
            </div>
        </Box>
    );
};

export default LogosPanel;
