import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setProfileShow, setProfileBarShow } from '../../../redux/notificationSlice';
import './Profileinfo.css'
import ProfileIcon from "../../../Assests/Group.svg"

const ProfileOverview = ({ profileTabRef }) => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state?.user || []);
    const userProfile = user?.[0] || {};
    const { connectionCount } = userProfile;

    const handleView = () => {
        dispatch(setProfileBarShow(false));
        dispatch(setProfileShow(true));
    };

    return (
        <div className="profile-overview_container" ref={profileTabRef}>
            <div className="followers-count">
                <div className="connections_title">Connections</div>
                <div className="connections_count">{connectionCount}</div>
            </div>
            <div className='section_line' />
            <div className='profile-overview-button' onClick={handleView}>
                <div className="text">View Profile</div>
                <img alt="" src={ProfileIcon} />
            </div>
        </div>
    )
}

export default ProfileOverview
