import React from 'react'
import SearchIcon from "../Assests/Search.svg"
import "./Components.css"

const Searchbar = ({ searchTerm, setSearchTerm, handleKeyDown, placeholder, handleSearhItem }) => {
    return (
        <div className="search_Container">
            <div className="search-bar">
                <input
                    type="text"
                    className="search-input"
                    placeholder={placeholder}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <img src={SearchIcon} alt="Search" className="search-icon" onClick={handleSearhItem} />
            </div>
        </div>
    )
}

export default Searchbar
