import React, { createContext, useState, useContext } from 'react';
import LoaderMessage from './LoaderMessage';

// Create a Context for the Loader
const LoaderContext = createContext();

// Create a custom hook to use the Loader context
export const useLoader = () => {
  return useContext(LoaderContext);
};

// LoaderProvider component that provides the context
export const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState('');

  // Function to trigger the Loader with a message
  const triggerLoader = (message) => {
    console.log('trigger called')
    setLoaderMessage(message);
    setIsLoading(true);
  };

  // Function to hide the Loader
  const hideLoader = () => {
    setIsLoading(false);
    setLoaderMessage('');
  };

  return (
    <LoaderContext.Provider value={{ triggerLoader, hideLoader }}>
      {children}
      {isLoading && <LoaderMessage loaderMessage={loaderMessage} />}
    </LoaderContext.Provider>
  );
};
