import { createSlice } from '@reduxjs/toolkit'

export const sideBarSlice = createSlice({
  name: 'data',
  initialState: { item_id: [] },
  reducers: {
    setSideBarState: (state, action) => {
      return { ...state, ...action.payload }
    },
    clearData: (state, action) => {
      state.item_id = state.item_id.filter(
        (item) => item.participantId !== action.payload.participantId
      );
    },
  },
})

export const { setSideBarState,clearData } = sideBarSlice.actions

export default sideBarSlice.reducer