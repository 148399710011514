import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, Typography, Button, Tooltip, IconButton } from "@mui/material";
import { useMeeting, usePubSub } from "@videosdk.live/react-sdk";
import { useLocation } from "react-router-dom";

// Import default images for the backgrounds
import light1 from './../Assests/light-1.jpg';
import dark1 from './../Assests/dark-1.jpg';
import { createStageBackground, deleteStageBackground, getStageBackgrounds } from "../Services/BannersApi";
import Accordion from "../Components/Accordion";
import MeetingOverlayPanel from './MeetingOverlayPanel'
import BannerPanel from "./BannerPanel";
import LogosPanel from "./LogosPanel";
import BGMPlayer from "./BGMPlayer";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { FaEye, FaEyeSlash, FaTrash } from "react-icons/fa";

const MeetingBackgroundPanel = () => {
    const { publish } = usePubSub("UPDATE_MEETING_BACKGROUND");  // PubSub to update background
    const mMeeting = useMeeting();

    // Initialize the backgrounds with default images
    const [backgrounds, setBackgrounds] = useState([
        {
            StageBackgroundID: 'default-light',
            StageBackgroundUrl: light1,
            isDefault: true
        },
        {
            StageBackgroundID: 'default-dark',
            StageBackgroundUrl: dark1,
            isDefault: true
        }
    ]);

    const [newBackground, setNewBackground] = useState(null);  // Stores the new background file
    const [isCreatingBackground, setIsCreatingBackground] = useState(false);  // Flag for background creation
    const [selectedBackground, setSelectedBackground] = useState(null);  // Selected background
    const [hoveredBackground, setHoveredBackground] = useState(null);  // Hovered background
    const fileInputRef = useRef(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const meetingId = queryParams.get("meetingId");

    useEffect(() => {
        fetchStageBackgrounds();
    }, []); // Empty dependency array to run only once when the component is mounted

    // Fetch backgrounds from the API and merge with the defaults
    const fetchStageBackgrounds = async () => {
        try {
            const response = await getStageBackgrounds();
            const apiBackgrounds = response?.Data || []; // Fallback if API returns empty data

            // Merge API backgrounds with default backgrounds
            const allBackgrounds = [
                { StageBackgroundID: 'default1', StageBackgroundUrl: light1, isDefault: true },  // Light default
                { StageBackgroundID: 'default2', StageBackgroundUrl: dark1, isDefault: true },   // Dark default
                ...apiBackgrounds.map(bg => ({ ...bg, isDefault: false }))
            ];

            // Update the backgrounds state with the merged data
            setBackgrounds(allBackgrounds);

            // Get the selected background from localStorage if available
            const selectedStageBackgroundID = localStorage.getItem('selectedStageBackgroundID');
            if (selectedStageBackgroundID) {
                const selectedBg = allBackgrounds.find(bg => bg.StageBackgroundID === selectedStageBackgroundID);
                setSelectedBackground(selectedBg?.StageBackgroundID);  // Set the selected background
            }
        } catch (error) {
            console.error('Error fetching backgrounds:', error);
        }
    };



    // Trigger file input to upload background
    const triggerFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Trigger file input click
        }
    };

    // Handle file change (selecting new background)
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewBackground(file);
            setIsCreatingBackground(true); // Start background creation process
        }
    };

    // Convert image file to base64
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => resolve(reader.result);  // Resolve with base64 data
            reader.onerror = (error) => reject(error);  // Reject if there's an error
        });
    };

    // Handle saving the new background
    const handleSaveBackground = async () => {
        if (newBackground) {
            try {
                // Convert the new background to base64
                const base64Image = await convertToBase64(newBackground);
                let prefix = "data:image/png;base64,";
                const backgroundData = {
                    UserID: localStorage.getItem('user_id'),
                    StageBackgroundBase64: base64Image.substring(prefix.length),
                };
                await createStageBackground(backgroundData);
                await fetchStageBackgrounds();  // Fetch the updated backgrounds including the new one

                setNewBackground(null);  // Reset the new background state
                setIsCreatingBackground(false);  // Reset the creation state
            } catch (error) {
                console.error("Error saving background:", error);
            }
        }
    };

    const handleDeleteBackground = async (background) => {
        try {
            // Prevent deletion of default backgrounds
            if (background.isDefault) {
                alert("Default backgrounds cannot be deleted.");
                return;
            }

            if (selectedBackground === background.StageBackgroundID) {
                setSelectedBackground(null);
                publish({ BackgroundId: null, BackgroundUrl: null, visibility: false }, { persist: true });
                localStorage.removeItem('selectedBackgroundId');  // Remove from localStorage
            }

            // Delete the background from the database
            await deleteStageBackground(background.StageBackgroundID);

            // Fetch updated backgrounds list after deletion
            fetchStageBackgrounds();

        } catch (error) {
            console.error("Error deleting background:", error);
        }
    };

    // Handle selecting a background (show or hide)
    const handleBackgroundClick = (backgroundId) => {
        localStorage.setItem('selectedStageBackgroundID', backgroundId);

        if (selectedBackground === backgroundId) {
            // Deselect the background
            setSelectedBackground(null);
            publish({ BackgroundId: null, BackgroundUrl: null, visibility: false }, { persist: true });
            localStorage.removeItem('selectedBackgroundId');  // Remove from localStorage
        } else {
            // Select the new background
            setSelectedBackground(backgroundId);
            const background = backgrounds.find(b => b.StageBackgroundID === backgroundId);  // Get the background by id
            publish({ BackgroundId: backgroundId, BackgroundUrl: background.StageBackgroundUrl, visibility: true }, { persist: true });
            localStorage.setItem('selectedBackgroundId', backgroundId);  // Store the selected background ID
        }
    };

    return (

        <Box
            color="white"
            className="px-3"
            style={{ maxHeight: "80vh", overflowY: "auto", paddingTop: "5px", marginTop: "10px" }}
        >
            
            <Accordion title={
                    <div className="flex items-center gap-2">
                        Background
                        <Tooltip
                            title={
                                <div style={{fontSize: '15px'}}>
                                    Background images serve as the backdrop for all elements added to the stage.
                                    <br />
                                    <strong>Recommended size:</strong> 1920 × 1080
                                    <br />
                                    <strong>Max file size:</strong> 4MB
                                </div>
                            }
                            arrow
                        >
                            <IconButton size="small">
                                <HelpOutlineIcon fontSize="small" style={{ color: "white" }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                }>
                <div>
                    <div className="my-2">
                        <Typography
                            variant="h6"
                            onClick={triggerFileInput} // Trigger file input click
                            style={{
                                cursor: 'pointer',
                                width: '100%',
                                textAlignLast: 'center',
                                padding: '2%',
                                borderRadius: '10px',
                            }}
                            className="schedule-btn"
                        >
                            Add Background Image
                        </Typography>
                    </div>

                    {/* File input for selecting background */}
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        accept="image/png, image/jpeg"  // Updated to accept both PNG and JPG/JPEG
                        onChange={handleFileChange}
                    />

                    {/* Preview and Save button for the new background */}
                    {isCreatingBackground && newBackground && (
                        <div style={{ marginTop: "20px", textAlign: "center" }}>
                            <img
                                src={URL.createObjectURL(newBackground)} // Preview selected image
                                alt="Background Preview"
                                style={{ width: "20em", height: "10em", objectFit: "contain" }}
                            />
                            <div className="py-3">
                                <Button variant="contained" color="primary" onClick={handleSaveBackground}>
                                    Save Background
                                </Button>
                            </div>
                        </div>
                    )}
                    {/* Display available backgrounds */}
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                        {backgrounds?.map((background) => (
                            <div
                                key={background.StageBackgroundID}
                                className="backgroundData pointer"
                                onMouseEnter={() => setHoveredBackground(background.StageBackgroundID)}
                                onMouseLeave={() => setHoveredBackground(null)}
                                onClick={() => handleBackgroundClick(background.StageBackgroundID)} // Handle background selection by id
                                style={{
                                    position: "relative",
                                    width: "45%", // Display backgrounds side by side
                                    marginBottom: "10px",
                                    backgroundColor: selectedBackground === background.StageBackgroundID ? '#ffffff50' : hoveredBackground === background.StageBackgroundID ? '#ffffff30' : 'transparent',
                                }}
                            >
                                <img
                                    src={background.StageBackgroundUrl} // Background image URL
                                    alt={`Background ${background.StageBackgroundID}`}
                                    style={{ width: "100%", height: "100px", objectFit: "contain" }}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: selectedBackground === background.StageBackgroundID || hoveredBackground === background.StageBackgroundID
                                            ? 'translate(-50%, -50%) scale(1)'  // Full size when active
                                            : 'translate(-50%, -50%) scale(0)', // Starts at 0 (hidden)
                                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                        color: 'white',
                                        padding: '5px 10px',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                        transition: 'transform 0.3s ease-out, opacity 0.3s ease-out',
                                        opacity: selectedBackground === background.StageBackgroundID || hoveredBackground === background.StageBackgroundID ? 1 : 0,
                                        pointerEvents: selectedBackground === background.StageBackgroundID || hoveredBackground === background.StageBackgroundID ? 'auto' : 'none', // Prevents interaction when hidden
                                    }}
                                >
                                    <div
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleBackgroundClick(background.StageBackgroundID);
                                        }}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: '5px 10px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {/* Eye Icon: Show/Hide */}
                                        <FaEyeSlash
                                            style={{
                                                display: selectedBackground === background.StageBackgroundID ? 'none' : 'inline',
                                                cursor: 'pointer',
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleBackgroundClick(background.StageBackgroundID);
                                            }}
                                        />
                                        <FaEye
                                            style={{
                                                display: selectedBackground === background.StageBackgroundID ? 'inline' : 'none',
                                                cursor: 'pointer',
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleBackgroundClick(background.StageBackgroundID);
                                            }}
                                        />

                                        {/* Trash Icon: Delete */}
                                        <FaTrash
                                            style={{
                                                marginLeft: '10px',
                                                cursor: 'pointer',
                                                color: 'red',
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteBackground(background);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Accordion>
            <Accordion
                title={
                    <div className="flex items-center gap-2">
                        Overlay
                        <Tooltip
                            title={
                                <div style={{fontSize: '15px'}}>
                                    Overlays are graphics on top of your content. You can use either an opaque or transparent image.
                                    <br />
                                    <strong>Recommended size:</strong> 1920 × 1080
                                    <br />
                                    <strong>Max file size:</strong> 4MB
                                </div>
                            }
                            arrow
                        >
                            <IconButton size="small">
                                <HelpOutlineIcon fontSize="small" style={{ color: "white" }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                }
            >
                <MeetingOverlayPanel />
            </Accordion>
            <BannerPanel />
            <Accordion title="Logos">
                <LogosPanel />
            </Accordion>
            <Accordion title="Background Music">
                <BGMPlayer />
                {/* <div className="my-2">
                    Under Development - Coming Soon
                </div> */}
            </Accordion>
            <Accordion title="Video Clips">
                <div className="my-2">
                    Under Development - Coming Soon
                </div>
            </Accordion>
        </Box>
    );
};

export default MeetingBackgroundPanel;