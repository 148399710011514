import { useMeeting, usePubSub } from "@videosdk.live/react-sdk";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLogoOffset, setIsLogoDragging } from "../../redux/logoSlice"


// const MeetingStyles = ({ handleMouseUp, handleMouseMove }) => {
const MeetingStyles = () => {
    const audioRef = useRef(null);
    const dispatch = useDispatch();
    const logoData = useSelector((state) => state?.logo);
    const [bannerName, setBannerName] = useState('Default Banner');
    const [backgroundColor, setBackgroundColor] = useState('#00002910');
    const [fontSize, setFontSize] = useState('20px');
    const [logo, setLogo] = useState("");
    const [meetingBackground, setMeetingBackground] = useState("");
    const [meetingOverlay, setMeetingOverlay] = useState("");
    const [bannerTheme, setBannerTheme] = useState(0)

    const [logoPosition, setLogoPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
    const containerRef = useRef(null);
    const [logoWidth, setLogoWidth] = useState('10');  
    const { publish } = usePubSub("UPDATE_LOGO");

    const [isVisible, setIsVisible] = useState(false);
    const [font, setFont] = useState();
    const position = 'bl';
    const meeting = useMeeting();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const guest = queryParams.get("guest");

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 100);
        return () => clearTimeout(timer);
    }, []);

    const bannerType = [
        {
            transform: isVisible ? 'translateX(0)' : 'translateX(-100%)',
            borderRadius: '5px',
        },
        {
            marginLeft: '10px',
        }, {
            marginLeft: '10px',
            borderRadius: '100px',
        }, {
            clipPath: isVisible
                ? 'polygon(0% 100%, 98% 100%, 100% 0%, 0% 0%)'
                : 'polygon(0% 100%, 0% 100%, 0% 0%, 0% 0%)',
            borderTopRightRadius: '10px',
        }
    ]


    usePubSub("UPDATE_BANNER", {
        onMessageReceived: (data) => {
            const { message } = data;
            console.log('message', message);
            setBannerName(message?.message);  // Update bannerName state here
            setBackgroundColor(message?.message ? message?.backgroundColor : 'transparent');
            setFontSize(message?.fontSize);
            setBannerTheme(message?.bannerStyle ? message?.bannerStyle : 0)
            setFont(message?.font);
            // enqueueSnackbar(`New Banner - ${message}`);
        },
        onOldMessagesReceived: (messages) => {
            // Check if any old message exists, and if so, set it to the state
            console.log("banner", messages)
            if (messages && messages.length > 0) {
                const latestMessage = messages[messages.length - 1].message; // Get the most recent banner message
                setBannerName(latestMessage?.message);  // Update bannerName state
                setBackgroundColor(latestMessage?.message ? latestMessage?.backgroundColor : 'transparent'); // Update background color
                setFontSize(latestMessage?.fontSize); // Update font size
                setBannerTheme(latestMessage?.bannerStyle ? latestMessage?.bannerStyle : 0)
            }
        },
    });

    usePubSub("UPDATE_LOGO", {
        onMessageReceived: (data) => {
            const { message } = data;
            console.log('message', message);
            setLogo(message?.LogoUrl);
            setLogoWidth(message?.width);
            if (message.LogoAxisX || message.LogoAxisY) {
                setLogoPosition({ x: message.LogoAxisX, y: message.LogoAxisY });
            }
        },
        onOldMessagesReceived: (messages) => {
            // Check if any old message exists, and if so, set it to the state
            console.log("logo", messages)
            if (messages && messages.length > 0) {
                const latestMessage = messages[messages.length - 1].message; // Get the most recent banner message
                setLogo(latestMessage?.LogoUrl);
                setLogoWidth(latestMessage?.width);
            }
        },
    });

    usePubSub("UPDATE_MEETING_BACKGROUND", {
        onMessageReceived: (data) => {
            const { message } = data;
            setMeetingBackground(message?.BackgroundUrl);
        },
        onOldMessagesReceived: (messages) => {
            // Check if any old message exists, and if so, set it to the state
            if (messages && messages.length > 0) {
                const latestMessage = messages[messages.length - 1].message.BackgroundUrl; // Get the most recent banner message
                setMeetingBackground(latestMessage);
            }
        },
    });

    usePubSub("UPDATE_MEETING_OVERLAY", {
        onMessageReceived: (data) => {
            const { message } = data;
            setMeetingOverlay(message?.OverlayUrl);
            console.log(meetingOverlay);
        },
        onOldMessagesReceived: (messages) => {
            // Check if any old message exists, and if so, set it to the state
            if (messages && messages.length > 0) {
                const latestMessage = messages[messages.length - 1].message.OverlayUrl; // Get the most recent banner message
                console.log(latestMessage)
                setMeetingOverlay(latestMessage);
            }
        },
    });

    usePubSub("UPDATE_MUSIC", {
        onMessageReceived: (data) => {
          const { action, url } = data;
    
          if (!audioRef.current) {
            audioRef.current = new Audio(url); // Create the audio element if it doesn't exist
          }
    
          const audio = audioRef.current;
    
          if (action === "play" && url) {
            audio.src = url; // Set the audio URL when playing
            audio.play();
          } else if (action === "pause") {
            audio.pause();
          }
        },
    
        onOldMessagesReceived: (messages) => {
          if (messages && messages.length > 0) {
            const { action, url } = messages[messages.length - 1].data;
    
            if (!audioRef.current) {
              audioRef.current = new Audio(url); // Create the audio element if it doesn't exist
            }
    
            const audio = audioRef.current;
    
            if (action === "play" && url) {
              audio.src = url;
              audio.play();
            } else if (action === "pause") {
              audio.pause();
            }
          }
        },
      });
    const bannerStyles = {
        ...bannerType[bannerTheme],
        position: 'absolute',
        // left: position === 'cc' ? '50%' : '0',
        // transform: position === 'cc' ? 'translateX(-50%)' : 'none',
        // transition: 'all 0.9s ease-out',
        opacity: isVisible ? 1 : 0,
        backgroundColor: backgroundColor,
        color: 'white',
        fontSize: fontSize,
        padding: '10px 10px',
        // textAlign: 'center',
        zIndex: 1000,
        height: 'auto',
        minHeight: '45px',
        lineHeight: '1.2',
        bottom: '0px',
        overflow: 'hidden',
        wordWrap: 'break-word',
        // color: 'White',
        paddingLeft: '3%',
        paddingRight: '3%',
        fontWeight: '600',
        // left: position === 'cc' ? '50%' : (guest ? '0' : '80px'),
        left: position === 'cc' ? '50%' : '0',
        transform: position === 'cc' ? 'translateX(-50%)' : 'none',
        fontFamily: font
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
        const rect = e.currentTarget.getBoundingClientRect();
        setDragOffset({
            x: e.clientX - rect.left,
            y: e.clientY - rect.top
        });
        setIsDragging(true);
        dispatch(setLogoOffset({
            offsetX: e.clientX - rect.left,
            offsetY: e.clientY - rect.top
        }))
        dispatch(setIsLogoDragging(true))
    };

    return (
        <div>
            {
                (bannerName !== 'Default Banner' || !bannerName) && (
                    <div
                        style={bannerStyles}
                    >
                        {bannerName}
                    </div>
                )
            }
            {
                (logo !== null && logo !== "") && (
                    <div
                        style={{
                            position: 'absolute',
                            left: logoPosition.x,
                            top: logoPosition.y,
                            cursor: isDragging ? 'grabbing' : 'grab',
                            touchAction: 'none',
                            width: '130px',
                            height: '130px',
                            zIndex: 1000,
                        }}
                        onMouseDown={guest ? null : handleMouseDown}
                    >
                        <img
                            src={logo}
                            style={{
                                width: logoWidth ? logoWidth : '10em',
                                objectFit: 'cover'
                            }}
                            draggable={false}
                        />

                    </div>
                )
            }
        </div>
    )
}

export default MeetingStyles;