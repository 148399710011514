import React from 'react'
import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Switch from "../../../Components/switch";
import MessageIcon from "../../../Assests/MSG (1).svg";
import SearchBar from "../../../Components/Searchbar";
import Avatar from "../../../Assests/avatar.svg";
import { information, getUserProfileDetails } from '../../../Services/ShowsApi';
import { API_STATUS } from '../../../utils/constant';
import { setData } from '../../../redux/userSlice';
import BeatLoader from "react-spinners/BeatLoader";
import {useNavigate} from "react-router-dom";
import { setValue } from '../../../redux/valueSlice';

const CommunityTop = () => {
    const dispatch = useDispatch()
    const user = useSelector((state) => state?.user || []);
    const navigate = useNavigate();

    const userProfile = user?.[0] || {};
    const {
        UserID,
        FirstName, LastName,
        Collaborate, connectionCount,
        connectionProfile,
        FacebookLink,
        InstagramLink,
        LinkedinLink,
        SpotifyLink,
        YoutubeLink,
        ProfileImageKey,
        Bio,
        Topics } = userProfile;

    const [isOpenToCollaborate, setIsOpenToCollaborate] = useState(Collaborate === 1);
    const [searchTerm, setSearchTerm] = useState("");
    const [toggle, setToggle] = useState(false);

    const toggleSwitch = async () => {
        setToggle(true)

        const payload = {
            UserID: UserID,
            Collaborate: Collaborate ? false : true,
        };
        const res = await information(payload)

        if (res?.status == API_STATUS.success) {
            setToggle(false)
            setIsOpenToCollaborate(!isOpenToCollaborate)
            const { data } = await getUserProfileDetails("UserID");
            let { Data } = data?.body
            dispatch(setData(Data))
        }

    };

    useEffect(() => {
        setIsOpenToCollaborate(Collaborate === 1);
    }, [Collaborate]);

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            dispatch(setValue({ filterValue: event.target.value, profileSearch: true }))
        }
    };

    const handleSearhItem = () => {
        dispatch(setValue({ filterValue: searchTerm, profileSearch: true }))
        // dispatch(setValue({ filterValue: searchTerm, profileSearch: false }))
    }

    function UpdateLoader() {
        return (
            <div className="sweet-loading" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <span>Updating</span>
                <BeatLoader
                    color="rgba(41, 104, 254, 0.8)"
                    loading={true}
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
        );
    }

    return (
        <div id='community-top_container'>
            <div className='community_user'>
                <div className='community_userImg'>
                    <img src={ProfileImageKey || Avatar} />
                </div>
                <div className='community_userInfo'>
                    <div className='community_userInfo_name'>
                        <span>{FirstName} {LastName}</span>
                    </div>
                    <div className='community_userInfo_status'>
                        <Switch isOn={isOpenToCollaborate} toggleSwitch={toggleSwitch} />
                        <span>{toggle ? <UpdateLoader /> : isOpenToCollaborate ? "Open  to Collaborate" : "Close  to Collaborate"}</span>

                    </div>

                </div>

            </div>
            <div className='community_user_searchMsg'>
                <div className='community_user_search'>
                    <SearchBar placeholder="Search"
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        handleKeyDown={handleKeyDown}
                        handleSearhItem={handleSearhItem}
                    />
                </div>
                <div className='community_user_message' onClick={()=>navigate('/chat')}>
                    <img src={MessageIcon} />
                    <span>5</span>
                </div>
            </div>
        </div>
    )
}

export default CommunityTop

