import React, { useState, useEffect } from "react";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useNavigate, useLocation } from "react-router-dom";
import ProfileUpdate from "../Pages/ProfileUpdate";
import "./Components.css";
import { setProfileShow, setProfileBarShow } from "../redux/notificationSlice";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileDetails } from "../Services/ShowsApi";
import { setData } from "../redux/userSlice";

const Header = ({headerTabRef}) => {
  const info = useSelector((state) => state.info)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openProfileDialog, setOpenProfileDialog] = React.useState(false);
  const [profileImg, setProfileImg] = useState("")
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const open = Boolean(anchorEl);

  // const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClick = () => {
    info?.profileBarShow ? dispatch(setProfileBarShow(false)) : dispatch(setProfileBarShow(true));
    console.log("ClickOnProfileIcon")

  }
  const handleClose = () => setAnchorEl(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const { data } = await getUserProfileDetails("UserID");
      console.log("userinformation",data)
      let { Data } = data?.body
      setProfileImg(Data[0]?.ProfileImageKey)
      dispatch(setData(Data))
    };

    fetchUserProfile();

  }, [])

  const handleLogout = () => {
    handleClose();
    localStorage.clear();
    navigate("/login");
  };

  const handleProfile = () => {
    setOpenProfileDialog(true);
    handleClose();
  };

  const isAuthenticated = !!localStorage.getItem("user_id");
  const excludedPaths = ["/meeting", "/signin", "/login", "/signup", "/left-meeting", "/setup-mfa", "/"];

  if (!isAuthenticated || excludedPaths.includes(location.pathname)) {
    return null;
  }

  return (
    <header className="basic-header" ref={headerTabRef}>
      <div className="header-content">
        <div className="header-right" onClick={handleClick}>
          {profileImg ? (
            <img src={profileImg} />
          ) : (
            <PersonRoundedIcon className="profile-icon" />
          )}

          {/* <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleProfile}>
              <ListItemIcon>
                <ManageAccountsIcon fontSize="small" style={{ color: "#fff" }} />
              </ListItemIcon>
              <ListItemText primary="Manage Profile" />
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" style={{ color: "#fff" }} />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
          </Menu> */}
        </div>
      </div>

      {/* <ProfileUpdate open={openProfileDialog} onClose={() => setOpenProfileDialog(false)} /> */}
    </header>

  );
};

export default Header;