import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, MenuItem, Select, InputLabel, FormControl, Typography } from '@mui/material';
import { updateUserProfile, getUserProfileDetails } from "../Services/ShowsApi";
import countries from '../Data/countries.json'; // Adjust path if necessary
import { useSnackbar } from '../Components/SnackbarContext';
import { useLoader } from '../Components/LoaderContext';

const ProfileUpdate = ({ open, onClose }) => {
    // State to handle form fields
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [country, setCountry] = useState('');
    const [originalProfile, setOriginalProfile] = useState({});
    const [noChangesMessage, setNoChangesMessage] = useState(''); // State for no changes message
    const { triggerLoader, hideLoader } = useLoader();

    // Validate whether the Save button should be enabled
    const isSaveEnabled = firstName && lastName;
    const { triggerSnackbar } = useSnackbar();

    // Fetch user profile data when the dialog opens
    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const result = await getUserProfileDetails("UserID");
                if (result?.data?.body?.Data?.length > 0) {
                    const profileData = result.data.body.Data[0];
                    console.log("profileData", profileData);
                    setOriginalProfile(profileData);
                    setFirstName(profileData.FirstName);
                    setLastName(profileData.LastName);
                    setCountry(profileData.Country);
                }
            } catch (error) {
                console.error("Error fetching profile data:", error);
            }
        };

        if (open) {
            fetchProfile();
        }
    }, [open]); // Dependency array ensures the effect runs only when the dialog is opened

    // Handle form submission
    const handleSave = async () => {
        onClose(); // Close the dialog

        // Check if any changes have been made
        const isChanged = firstName !== originalProfile.FirstName || lastName !== originalProfile.LastName || country !== originalProfile.Country;
        console.log("isChanged", isChanged, firstName, lastName, country, originalProfile)
        if (!isChanged) {
            setNoChangesMessage('No changes made to the profile.');
            return; // Prevent save if no changes
        }

        try {
            triggerLoader("Updating profile...");
            const payload = {
                "UserID": localStorage.getItem('user_id'),
                "FirstName": firstName !== originalProfile.firstName ? firstName : undefined,
                "LastName": lastName !== originalProfile.lastName ? lastName : undefined,
                "Country": country !== originalProfile.country ? country : undefined
            };

            await updateUserProfile(payload); // Update profile
            console.log("Profile updated:", payload);
            hideLoader();
            triggerSnackbar('Profile updated successfully!');
            setNoChangesMessage('');
        } catch (error) {
            console.error("Error updating profile:", error);
            alert("An error occurred while updating the profile. Please try again.");
        }
    };

    const handleCancel = () => {
        onClose(); // Close the dialog on cancel
        setNoChangesMessage('')
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth
            sx={{
                '& .MuiPaper-root': {
                    backgroundColor: 'var(--theme-bg)',
                    color: 'white',
                },
            }}>
            <DialogTitle>Update Profile</DialogTitle>
            <DialogContent>
                <TextField
                    label="First Name"
                    variant="filled"
                    InputProps={{ style: { color: 'white' } }}
                    InputLabelProps={{ style: { color: 'white' } }}
                    sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 1 }}
                    fullWidth
                    margin="normal"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                />
                <TextField
                    label="Last Name"
                    variant="filled"
                    InputProps={{ style: { color: 'white' } }}
                    InputLabelProps={{ style: { color: 'white' } }}
                    sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 1 }}
                    fullWidth
                    margin="normal"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                />
                <FormControl
                    variant="filled"
                    fullWidth
                    margin="normal"
                    sx={{
                        bgcolor: 'rgba(255, 255, 255, 0.1)',
                        borderRadius: 1,
                        '.MuiInputLabel-root': {
                            color: 'white'
                        },
                        '.Mui-focused .MuiInputLabel-root': {
                            color: 'white', // Prevent label from turning blue on focus
                        },
                    }}
                >
                    <InputLabel sx={{ color: 'white' }}>Country</InputLabel>
                    <Select
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        sx={{
                            color: 'white',
                            '.MuiSelect-icon': { color: 'white' },
                            '&:focus': {
                                backgroundColor: 'transparent',
                            },
                        }}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 150, // Reduced max height
                                    width: 250,
                                },
                            },
                        }}
                    >
                        {countries.map((country) => (
                            <MenuItem key={country.code} value={country.name}>
                                {country.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {noChangesMessage && (
                    <Typography color="orange" sx={{ mt: 1, fontSize: 12 }} textAlign={'center'}>
                        {noChangesMessage}
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSave}
                    sx={{
                        bgcolor: !isSaveEnabled
                            ? '#B0B0B0' // Gray color for disabled state
                            : 'linear-gradient(to right, #472DDB, #0985FB, #00D7FD)', // Gradient background for enabled state
                        color: 'white',
                        '&:hover': {
                            bgcolor: !isSaveEnabled ? '#B0B0B0' : 'linear-gradient(to right, #472DDB, #0985FB, #00D7FD)', // Keep same background on hover
                        }
                    }}
                    disabled={!isSaveEnabled} // Save button disabled unless both first and last name are filled
                >
                    Save
                </Button>
                <Button onClick={handleCancel} sx={{ color: 'white', border: '1px solid white' }}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProfileUpdate;
