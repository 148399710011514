import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import SideBar from "../Components/Sidebar";
import "./Pages.css";
import { Button, Tooltip, IconButton } from "@mui/material";
import { getScheduledPodcasts, deletePodcast } from "../Services/SchedulesApi";
import Loader from "../Components/Loader";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import WarningDialog from "../Components/WarningDialog";
import { getUserProfileDetails } from "../Services/ShowsApi";
import { useLoader } from "../Components/LoaderContext";
// import DeleteIcon from "./../Assests/Delete.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import { commonConstants } from "../utils/constant";

const MissedSchedules = () => {
  const navigate = useNavigate();
  const [podcasts, setPodcasts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedScheduleId, setSelectedScheduleId] = useState(null);
  const [selectedPodcast, setSelectedPodcast] = useState(null); // State to hold the selected podcast
  const [userProfile, setUserProfile] = useState({
    firstName: "",
    lastName: "",
  });
  const { triggerLoader, hideLoader } = useLoader();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000); // Simulate loading for 3 seconds
  }, []);

  // Fetch the podcasts data and update the state
  useEffect(() => {
    const fetchPodcasts = async () => {
      try {
        // Fetch podcast details
        const response = await getScheduledPodcasts("missed"); // API call to fetch podcasts
        setPodcasts(response?.data?.Data); // Update podcasts data

        // Fetch user profile details
        const profileResponse = await getUserProfileDetails("UserID");
        const profileData = profileResponse?.data?.body?.Data?.[0] || {};
        setUserProfile({
          firstName: profileData.FirstName || "",
          lastName: profileData.LastName || "",
        });
        localStorage.setItem(
          "fullName",
          `${profileData.FirstName} ${profileData.LastName}`
        );
      } catch (error) {
        console.error("Error fetching podcasts:", error); // Error handling
      } finally {
        setLoading(false); // End loading after the fetch is complete
      }
    };

    fetchPodcasts();
  }, []);

  // Handle button click to navigate to the create schedule page
  const handleSchedule = () => {
    navigate("/shows/create"); // Navigate to create page
  };

  // Handle click on an existing podcast to edit
  const handleEditPodcast = (podcast) => {
    navigate("/shows/create", { state: { podcast } }); // Pass selected podcast data to the create/edit form
  };

  const handleDelete = (scheduleId, podcast, event) => {
    event.stopPropagation();
    setSelectedScheduleId(scheduleId);
    setSelectedPodcast(podcast); // Set the selected podcast
    setOpenDialog(true);
  };

  const handleReschedule = () => {
    alert("Reschedule feature is not available yet.");
  };

  const confirmDelete = async () => {
    setOpenDialog(false);
    triggerLoader("Deleting Podcast...");
    if (selectedScheduleId) {
      try {
        await deletePodcast(selectedScheduleId); // Call to delete the podcast
        setPodcasts(
          podcasts.filter(
            (podcast) => podcast.ScheduleID !== selectedScheduleId
          )
        );
      } catch (error) {
        console.error("Error deleting podcast:", error);
      } finally {
        hideLoader();
      }
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedScheduleId(null);
    setSelectedPodcast(null); // Clear the selected podcast
  };

  return (
    <div
      className="theme-bg clr-white"
      style={{
        display: "flex",
        maxHeight: "73vh",
        overflowY: "auto",
        maxWidth: "96%",
        marginLeft: "2%",
        marginTop: "2%",
      }}
    >
      <div className="container-fluid overflow-x-none">
        <div className="row">
          <div
            className="container"
            style={{ minHeight: "70vh", color: "#fff" }}
          >
            {loading ? (
              <Loader message="Fetching your schedules" />
            ) : (
              <div
                className="row justify-content-center"
                style={{ overflowY: "auto" }}
              >
                <div className="col-md-12">
                  {podcasts?.length === 0 ? (
                    <div className="col-12" style={{ textAlign: "center" }}>
                      <h4>No podcasts Found</h4>
                    </div>
                  ) : (
                    podcasts?.map((podcast, index) => (
                      <div
                        key={index}
                        className="card mb-3 pointer"
                        style={{
                          backgroundColor: "rgb(25 25 62)",
                          color: "#fff",
                          borderRadius: "10px",
                          border: "none",
                        }}
                      // onClick={() => handleEditPodcast(podcast)}
                      >
                        <div className="row card-body" style={{
                          justifyContent: "space-between",
                          paddingRight: "4%",
                        }}>
                          <div
                            style={{
                              width: "328px",
                              minWidth: "160px",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "flex-start",
                              gap: "8px",
                              display: "inline-flex",
                            }}
                          >
                            <div
                              style={{
                                alignSelf: "stretch",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                gap: "16px",
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  alignSelf: "stretch",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "flex-start",
                                  gap: "8px",
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "24px",
                                    fontWeight: "bolder",
                                    letterSpacing: "1.92px",
                                    wordWrap: "break-word",
                                    width: "100%",
                                  }}
                                >
                                  {podcast.Title}
                                </div>
                              </div>
                              <div
                                style={{
                                  alignSelf: "stretch",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "flex-start",
                                  gap: "8px",
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    color: "var(--Grays-White, white)",
                                    fontSize: "12px",
                                    // fontFamily: "Nexa",
                                    fontWeight: "100",
                                    lineHeight: "16.80px",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  Host: {podcast.Host}
                                  <br />
                                  Date/Time:{" "}
                                  {new Date(podcast.StartDttm).toLocaleString(
                                    "en-US",
                                    {
                                      hour12: true,
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      year: "numeric",
                                      month: "numeric",
                                      day: "numeric",
                                    }
                                  )}
                                </div>
                                <div
                                  style={{
                                    color: "var(--Grays-White, white)",
                                    fontSize: "12px",
                                    // fontFamily: "Nexa",
                                    fontWeight: "100",
                                    lineHeight: "16.80px",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  Guests:{" "}
                                  {JSON.parse(podcast?.Guests || "[]")
                                    ?.slice(0, 2)
                                    .map((guest, idx) => (
                                      <span key={idx}>
                                        {guest}
                                        {idx < 1 && ", "}
                                      </span>
                                    ))}
                                  {JSON.parse(podcast?.Guests || "[]")?.length >
                                    2 && (
                                      <Tooltip
                                        title={JSON.parse(podcast?.Guests || "[]")
                                          ?.slice(2)
                                          .join(", ")}
                                        arrowplacement="top"
                                      >
                                        <span className="more-guests">
                                          {` +${JSON.parse(podcast?.Guests || "[]")
                                            ?.length - 2
                                            }`}
                                        </span>
                                      </Tooltip>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              //   flex: "1 1 0",
                              width: "50%",
                              alignSelf: "stretch",
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                              gap: "23px",
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                // flex: "1 1 0",
                                width: "100%",
                                alignSelf: "stretch",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                gap: "8px",
                                display: "inline-flex",
                              }}
                            >
                              <div
                                style={{
                                  alignSelf: "stretch",
                                  color: "white",
                                  fontSize: "16px",
                                  fontWeight: "bolder",
                                  letterSpacing: "1.28px",
                                  wordWrap: "break-word",
                                }}
                              >
                                Agenda
                              </div>
                              <div
                                style={{
                                  alignSelf: "stretch",
                                  color: "white",
                                  fontSize: "16px",
                                  //   fontFamily: 'Nexa',
                                  fontWeight: "100",
                                  letterSpacing: "0.32px",
                                  wordWrap: "break-word",
                                }}
                              >
                                {podcast?.Agenda ? (
                                  <div dangerouslySetInnerHTML={{ __html: podcast.Agenda }} />
                                ) : (
                                  commonConstants.NO_AGENDA
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-1">
                            {!podcast.guest ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column", // Stack children vertically
                                  justifyContent: "space-between", // Add space between buttons
                                  height: "100%",
                                }}
                              >
                                <Tooltip title="Reschedule feature is not available yet.">
                                  <IconButton
                                    size="small"
                                    color="secondary"
                                    style={{
                                      color: "#fff",
                                      fontSize: "0.9rem",
                                      width: "120px",
                                      borderRadius: "16px",
                                      backgroundColor: "rgba(41, 104, 254, 0.8)",
                                      height: "40px",
                                      flexShrink: 0,
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      padding: "8px 16px",
                                      gap: "8px",
                                      display: "flex",
                                    }}
                                  //   onClick={(event) =>
                                  //     handleReschedule()
                                  //   }
                                  >
                                    Reschedule
                                  </IconButton>
                                </Tooltip>
                                <IconButton
                                  size="small"
                                  color="secondary"
                                  style={{
                                    color: "#fff",
                                    fontSize: "0.9rem",
                                    width: "120px",
                                    borderRadius: "16px",
                                    border: "1px solid #fff",
                                    height: "40px",
                                    flexShrink: 0,
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "8px 16px",
                                    gap: "8px",
                                    display: "flex",
                                  }}
                                  onClick={(event) => handleDelete(podcast.ScheduleID, podcast, event)}
                                >
                                  Remove
                                  <DeleteIcon style={{ fontSize: "1rem", color: "#fff" }} />
                                </IconButton>
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <WarningDialog
        open={openDialog}
        onClose={handleDialogClose}
        onConfirm={confirmDelete}
        title="Delete Schedule"
        description={
          selectedPodcast?.Title
            ? `Are you sure you want to delete this schedule: ${selectedPodcast.Title}?`
            : "Are you sure you want to delete this schedule?"
        }
      />
    </div>
  );
};

export default MissedSchedules;
