import React, { useEffect, useState } from "react";
import light from "../../Assests/Light.svg";
import skip from "../../Assests/X.svg";
import { getRecommendations } from "../../Services/ShowsApi";
import profileIcon from "../../Assests/profile.png";
import insta from "../../Assests/Insta.svg";
import facebook from "../../Assests/Facebook.svg";
import spotify from "../../Assests/Spotify.svg";
import linkedin from "../../Assests/Linkedin.svg";
import youtube from "../../Assests/Youtube.svg";
import DollarCoin from "../../Assests/Dollar Coins.svg";
import { updateConnects } from "../../Services/ShowsApi";
import { API_STATUS } from '../../utils/constant'
import { useLoader } from "../../Components/LoaderContext";
import { Tooltip } from "@mui/material";
// Styles
const styles = {
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 16,
    display: "inline-flex",
  },
  cardContainer: {
    flex: "1 1 0",
    height: 440,
    position: "relative",
  },
  card: {
    position: "absolute",
    padding: 16,
    background: "rgba(255, 255, 255, 0.08)",
    borderRadius: 16,
    outline: "1px rgba(175.31, 175.31, 175.31, 0.50) solid",
    outlineOffset: "-1px",
    backdropFilter: "blur(12px)",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 16,
    display: "inline-flex",
  },
  image: {
    // alignSelf: "stretch",
    flex: "1 1 0",
    borderRadius: 8,
    width: "-webkit-fill-available",
    objectFit: "cover",
    height: "0",
  },
  icon: {
    flex: "1 1 0",
    borderRadius: 8,
    maxWidth: "-webkit-fill-available",
    objectFit: "cover",
    height: "0",
  },
  textContainer: {
    alignSelf: "stretch",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 8,
    display: "flex",
  },
  nameContainer: {
    alignSelf: "stretch",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 8,
    // display: "inline-flex",
  },
  name: {
    flex: "1 1 0",
    color: "white",
    fontSize: 24,
    fontWeight: "bolder",
    letterSpacing: 1.92,
    wordWrap: "break-word",
  },
  role: {
    flex: "1 1 0",
    textAlign: "right",
    color: "white",
    fontSize: 14,
    fontWeight: "bolder",
    letterSpacing: 0.28,
    wordWrap: "break-word",
  },
  statusContainer: {
    alignSelf: "stretch",
    justifyContent: "space-between",
    alignItems: "center",
    display: "inline-flex",
  },
  statusIndicator: {
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 8,
    display: "flex",
  },
  statusText: {
    color: "white",
    fontSize: 14,
    fontWeight: "bolder",
    letterSpacing: 0.56,
    wordWrap: "break-word",
  },
  priceContainer: {
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 8,
    display: "flex",
  },
  priceContainerImg: {
    width: 16,
    height: 16
  },
  priceContainerValue: {
    display: "flex",
    alignItems: "center",
    gap: 2
  },
  priceText: {
    color: "white",
    fontSize: 14,
    fontWeight: "bolder",
    letterSpacing: 0.28,
    wordWrap: "break-word",
  },
  buttonContainer: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: 24,
    display: "inline-flex",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 16px",
    backgroundColor: "rgba(41, 104, 254, 0.8)", // Default background color
    color: "#fff", // Text color
    border: "none",
    borderRadius: 16,
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bolder",
    transition: "background-color 0.3s ease",
    ":hover": {
      backgroundColor: "#0056b3", // Hover background color
    },
    ":active": {
      backgroundColor: "#004080", // Active (clicked) background color
    },
    gap: 8,
    width: "100%",
  },
  buttonText: {
    textAlign: "right",
    color: "white",
    fontSize: 16,
    fontWeight: "bolder",
    letterSpacing: 0.64,
    wordWrap: "break-word",
  },
  section: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 8,
    display: "inline-flex"
  },
  sectionTitle: {
    color: "white",
    fontSize: 14,
    fontWeight: "bolder",
    letterSpacing: 1.12,
    wordWrap: "break-word",
  },
  bioContent: {
    width: "100%",
    color: "white",
    fontSize: 16,
    // fontWeight: "bold",
    letterSpacing: 0.32,
    wordWrap: "break-word",
    height: 190,
    overflow: "hidden",  // Prevents content from overflowing
    overflowY: "auto",
    scrollbarWidth: "none",  /* Firefox */
    msOverflowStyle: "none",
  },
  interestsContainer: {
    alignSelf: "stretch",
    overflow: "hidden",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 16,
    display: "inline-flex",
    flexWrap: "wrap",
    height: 32,
    overflow: "hidden",  // Prevents content from overflowing
    overflowY: "auto",
    scrollbarWidth: "none",  /* Firefox */
    msOverflowStyle: "none",
  },
  pill: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    background: "rgba(255, 255, 255, 0.80)",
    borderRadius: 24,
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 4,
    display: "flex",
  },
  pillText: {
    textAlign: "right",
    justifyContent: "flex-end",
    display: "flex",
    flexDirection: "column",
    color: "#000029",
    fontSize: 14,
    fontWeight: "bolder",
    letterSpacing: 0.56,
    wordWrap: "break-word",
  },
  socialIconsContainer: {
    alignSelf: "stretch",
    height: 40,
    justifyContent: "space-between",
    alignItems: "center",
    display: "inline-flex",
  },
  socialLink: {
    width: 40,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 4
  },
};

// Reusable Components
const StatusIndicator = ({ Collaborate }) => (
  <div style={styles.statusIndicator}>
    <div className={Collaborate ? "recommentation_collab_status" : "recommentation_collab_status_false"} />
    <div style={styles.statusText}>{
      Collaborate ? "Let's Collaborate!" : "Not Now"}</div>
  </div>
);

const PriceIndicator = ({ Rate }) => (
  <div style={styles.priceContainer}>
    <img style={styles.priceContainerImg} src={DollarCoin} />
    <div style={styles.priceContainerValue}>
      <span style={styles.priceText}>$</span>
      <span style={styles.priceText}>{Rate || "-"}</span>
      <span style={styles.priceText}>/Ep</span>
    </div>
  </div>
);

const Button = ({ text, onClick, src }) => (
  <button style={styles.button} onClick={onClick}>
    <div style={styles.connectText}>{text}</div>
    <div style={styles.connectIcon}>
      <img src={src} alt="Light" style={{ width: "100%", height: "100%" }} />
    </div>
  </button>
);

const Card = ({
  FirstName,
  LastName,
  role,
  Collaborate,
  Rate,
  ProfileImageKey,
  style,
  onSkip,
  handleClickConnect,
  UserID,
}) => (
  <div style={{ ...styles.card, ...style }}>
    <img
      style={ProfileImageKey ? styles.image : styles.icon}
      src={ProfileImageKey ? ProfileImageKey : profileIcon}
      alt={FirstName}
    />
    <div style={styles.textContainer}>
      <div style={styles.nameContainer}>
        <div style={styles.name} className="ellipsis">
          {FirstName + " " + LastName}
        </div>
        <div style={styles.role}>{role}</div>
      </div>
      <div style={styles.statusContainer}>
        <StatusIndicator Collaborate={Collaborate} />
        <PriceIndicator price={Rate} />
      </div>
    </div>
    <div style={styles.buttonContainer}>
      <Button text="Skip" src={skip} onClick={onSkip} />
      <Button
        text="Connect"
        src={light}
        onClick={() => handleClickConnect(UserID)}
      />
    </div>
  </div>
);

const GlassPanelComponent = ({
  bioTitle = "Bio",
  bioContent = "This user hasn’t shared their bio yet!",
  // bioContent = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Ipsum is simply dummy text of the printing and typesetting industrLorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in",
  interestsTitle = "Interests",
  connectTitle = "Connect On",
  Bio,
  FacebookLink,
  SpotifyLink,
  InstagramLink,
  LinkedinLink,
  YoutubeLink,
  Topics,
  style,
}) => {
  return (
    <div style={{ ...styles.card, ...style }}>
      {/* Bio Section */}
      <Section title={bioTitle} style={{ height: "100%" }}>
        <div style={styles.bioContent}>{Bio || bioContent}</div>
      </Section>

      {/* Interests Section */}
      <Section title={interestsTitle} style={{ height: "100%" }}>
        <div style={styles.interestsContainer}>
          {Topics && Topics.length > 0 ? (
            Topics?.map((interest, index) => (
              <Pill key={index} text={interest} />
            ))) : (
            <span>User hasn't added any interested topics yet!</span>
          )
          }
        </div>
      </Section>


      {/* Connect Section */}
      <Section title={connectTitle}>
        <div style={styles.socialIconsContainer}>
          {[
            { icon: facebook, link: FacebookLink, alt: "Facebook" },
            { icon: spotify, link: SpotifyLink, alt: "Spotify" },
            { icon: insta, link: InstagramLink, alt: "Instagram" },
            { icon: linkedin, link: LinkedinLink, alt: "LinkedIn" },
            { icon: youtube, link: YoutubeLink, alt: "YouTube" },
          ].map((social, index) => (
            <SocialIcon
              key={index}
              icon={social.icon}
              link={social.link}
              alt={social.alt}
            />
          ))}
        </div>
      </Section>
    </div>
  );
};

// Reusable Section Component
const Section = ({ title, children, style }) => (
  <div style={{ ...styles.section, ...style }}>
    <div style={styles.sectionTitle}>{title}</div>
    {children}
  </div>
);

// Reusable Pill Component for Interests
const Pill = ({ text }) => (
  <div style={styles.pill}>
    <div style={styles.pillText}>#</div>
    <div style={styles.pillText}>{text}</div>
  </div>
);

// Reusable Social Icon Component
const SocialIcon = ({ icon, link, alt }) => {
  const isLinked = link !== undefined && link !== '';
  return (
    <a
      href={isLinked ? link : undefined} // Only set href if link is available
      target={isLinked ? "_blank" : undefined}
      rel={isLinked ? "noopener noreferrer" : undefined}
      style={{
        ...styles.socialLink,
      }}
      onClick={(e) => {
        if (!isLinked) {
          e.preventDefault(); // Prevent click action if link is unavailable
        }
      }}
    >
      <Tooltip
        title={!isLinked ? `${alt}'s account not linked!` : `Click to open ${alt}'s profile.`}
        arrow
        placement="top"
      >
        <img
          className={isLinked ? "socialIcon_linked" : "socialIcon_unlinked"}
          src={icon}
          alt={alt}
        />
      </Tooltip>
    </a>
  );
};


// Main Component
const Recommendations = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [creators, setCreators] = useState([]);
  const { triggerLoader, hideLoader } = useLoader();
  const handleSkip = () => {
    // Move to the next card
    setCurrentIndex((prevIndex) => (prevIndex + 1) % creators.length);
  };

  const handleClickConnect = async (id) => {
    triggerLoader("Sending connection request...");
    // setCurrentIndex((prevIndex) => (prevIndex + 1) % creators.length);
    const data = {
      SenderID: localStorage.getItem("user_id"),
      ReceiverID: id,
    };
    console.log("daaata", data)
    let res = await updateConnects(data)
    if (res?.status == API_STATUS.success) {
      hideLoader();
      setCurrentIndex((prevIndex) => (prevIndex + 1) % creators.length);
      console.log("connect updated successfully");
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRecommendations();
        console.log({ response });
        setCreators(response?.data?.body.Data);
      } catch (error) {
        console.error("Error fetching top creators:", error);
      }
    };

    fetchData();
  }, []);

  const visibleCreators = [
    creators[(currentIndex + 2) % creators.length],
    creators[(currentIndex + 1) % creators.length],
    creators[(currentIndex + 0) % creators.length],
  ];

  console.log("visibleCreators", visibleCreators)

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 8,
        display: "inline-flex",
        top: 90,
      }}
    >
      <div
        style={{
          alignSelf: "stretch",
          justifyContent: "space-between",
          alignItems: "center",
          display: "inline-flex",
        }}
      >
        <div
          style={{
            color: "white",
            fontSize: 24,
            fontWeight: "bolder",
            letterSpacing: 1.92,
            wordWrap: "break-word",
            marginLeft: 40,
          }}
        >
          Recommendations{" "}
        </div>
      </div>
      <div style={styles.container}>
        <div style={styles.cardContainer}>
          {visibleCreators.map((creator, index) => (
            <Card
              key={index}
              {...creator}
              style={{
                width: '22vw',
                height: 380 + index * 16,
                left: index * 52,
                top: index * 8,
                opacity: index === 0 ? 0.2 : index === 1 ? 0.4 : 1,
                filter: index < 2 ? "blur(4px)" : "none",
              }}
              onSkip={handleSkip}
              handleClickConnect={() => handleClickConnect(creator?.UserID)}
            />
          ))}
        </div>
        <div style={styles.cardContainer}>
          {visibleCreators?.map((creator, index) => (
            <GlassPanelComponent
              key={index}
              {...creator}
              style={{
                width: '27vw',
                height: 380 + index * 16,
                left: index * 12,
                top: index * 8,
                opacity: index === 0 ? 0.2 : index === 1 ? 0.4 : 1,
                filter: index < 2 ? "blur(4px)" : "none",
              }}
              onSkip={handleSkip}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Recommendations;
