import { height, padding, width } from "@mui/system";

export const bannerColor = [
  { id: 0, color: "#FF4B42" },
  { id: 1, color: "#00A6FF" },
  { id: 2, color: "#8E5DFF" },
  { id: 3, color: "#44B255" },
  { id: 4, color: "#E3254E" },
  { id: 6, color: "#E5B411" },
];

export const bannerTheme = [
  {
    padding: '10px 10px',
    textAlign: 'center',
    borderRadius: '5px',
    zIndex: 1000,
    left: '0',
    right: '0',
    overflow: 'hidden',
    wordWrap: 'break-word',
    background: 'linear-gradient(229.85deg, #00DAFC 15.12%, #0192FD 43.86%, #2959EB 66.92%, #5E0BCD 86.76%)',
    color: "White",
    label:"Curved",
    width:'20px'
  }, {
    padding: '10px 10px',
    textAlign: 'center',
    zIndex: 1000,
    overflow: 'hidden',
    wordWrap: 'break-word',
    left: '0',
    right: '0',
    background: 'linear-gradient(229.85deg, #00DAFC 15.12%, #0192FD 43.86%, #2959EB 66.92%, #5E0BCD 86.76%)',
    color: "White",
    label:"Block",
    width:'20px'
  }, {
    padding: '10px 10px',
    textAlign: 'center',
    zIndex: 1000,
    overflow: 'hidden',
    wordWrap: 'break-word',
    left: '0',
    right: '0',
    borderRadius: '20px',
    background: 'linear-gradient(229.85deg, #00DAFC 15.12%, #0192FD 43.86%, #2959EB 66.92%, #5E0BCD 86.76%)',
    color: "White",
    label:"Bubble",
    width:'20px'
  },
  {
    padding:'20px 0px',
    textAlign: 'center',
    zIndex: 1000,
    overflow: 'hidden',
    wordWrap: 'break-word',
    background: 'linear-gradient(229.85deg, #00DAFC 15.12%, #0192FD 43.86%, #2959EB 66.92%, #5E0BCD 86.76%)',
    clipPath: 'polygon(0% 100%, 90% 100%,100% 0%, 0% 0% )', 
    borderTopRightRadius: '10px',
    backgroundColor: "#1976d2",
    color: "White",
    label:"Classic",
    width:'20px',
    height:"10px"
  }
]

export const commonConstants = {
  NO_AGENDA : 'The agenda has not been provided.'
}

export const API_STATUS = {
  success: '200',
  error: '400',
}


export const SEARCH_TYPE = [
  { "id": 0, type: "Podcaster" },
  { "id": 1, type: "Topic" }
]