import React, { useRef } from 'react'
import SideBar from '../../Components/Sidebar'
import HomepageContainer from '../Container/HomePage/HomepageContainer'
import './Homepage.css'
import Pageheader from '../Container/PageHeader/Pageheader'
import { useDispatch, useSelector } from 'react-redux'
import useClickOutside from '../../Hook/useClickOutside'
import { setShow } from '../../redux/notificationSlice'

const HomePage = () => {
    const dispatch = useDispatch()
    const info = useSelector((state) => state.info)

    return (
        <>
            <div className='theme-bg clr-white height-100' style={({ display: "flex" })}>
                <SideBar></SideBar>
                <div className='container-fluid overflow-x-none ml-80p'>
                    <div className='row'>
                        <div className="container" style={{ minHeight: "100vh", color: "#fff" }}>
                            <div>
                                {/* <HomepageTop /> */}
                                <Pageheader pageTitle="Home Page" />
                            </div>
                            <div className='homepage_maincontainer'>
                                <HomepageContainer />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default HomePage
