import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import chevron from "../../Assests/Chevron.svg";
import MSG from "../../Assests/MSG.svg";
import "./Chat.css";
import ChatList from "../Container/Chat/ChatList";
import ChatArea from "../Container/Chat/ChatArea";
import EmptyChatArea from "../Container/Chat/EmptyChatArea";
import ChatProfile from "../Container/Chat/ChatProfile";

function Chat() {
  
  const navigate = useNavigate(); // Initialize navigate function

  const [showChatArea, setChatArea] = useState(false);
  const [userName, setUserName] = useState("");
  const [profileimage, setProfileImage] = useState("");
  const [messages, setMessages] = useState([]); // Store all messages
  const [UID, setUID] = useState("");
  const [showProfile, setShowProfile] = useState(false);

  return (
    <div className="row theme-bg height-1000">
      <div className="main">
        <div className="chat-header">
          <div className="left-items">
            <div className="chevron-icon" onClick={() => navigate(-1)}>
              <img src={chevron} alt="Back" />
            </div>
            <div className="page-title">Messages</div>
          </div>
          <div className="right-item">
            <img src={MSG} alt="messages" />
          </div>
        </div>

        <div className="chat-section">
          <ChatList
            setChatArea={setChatArea}
            setUserName={setUserName}
            setProfileImage={setProfileImage}
            setMessages={setMessages}
            setUID={setUID} selectedUserId="44a83458-5081-707d-8c92-9929ea13b23d"w
          />
          {showChatArea ? (
            <ChatArea
              name={userName}
              profileimage={profileimage}
              message={messages}
              receiverUID={UID}
              setShowProfile={setShowProfile}
            />
          ) : (
            <EmptyChatArea />
          )}

          {showProfile ? <ChatProfile name={userName} profileimage={profileimage} setShowProfile={setShowProfile} /> : null}
        </div>
      </div>
    </div>
  );
}

export default Chat;
