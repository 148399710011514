// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from './Pages/Login';
import Signup from './Pages/Signup';
import ProfileSetupStep from './Pages/profileSetupStep';
import Shows from './Pages/Shows';
import { createTheme, ThemeProvider } from '@mui/material';
import Schedules from "./Pages/Schedules";
import Schedule from "./Pages/Schedule";
// import JoinStudio from "./Pages/JoinStudio";
import Community from "./Pages/Community/Community";
import Settings from "./Pages/Settings";
import BrandCentral from "./Pages/BrandCentral";
// import Studio from "./Pages/Studio";
import ShowDetails from "./Pages/ShowDetails";
import Meeting from "./Meeting/Meeting";
import JoinStudio from "./Pages/JoinStudio";
import Studio from "./Pages/Studio";
import Header from "./Components/Header";
import MeetingLeft from "./Pages/MeetingLeft";
import CustomBanner from "./Components/CustomBanner";
import { SnackbarProvider } from "./Components/SnackbarContext";
import SetupAuthenticator from "./Pages/SetupAuthenticator";
import SocialMediaYT from "./Components/SocialMediaYT";
import SocialMediaLI from "./Components/SocialMediaLI";
import HomePage from "./Pages/HomePage/HomePage";
import { LoaderProvider } from "./Components/LoaderContext";
import Profile from "./Pages/Profile/Profile";
import ConnectionPage from "./Pages/ConnectionPages/ConnectionPage";


import Chat from "./Pages/Chat/Chat";


const theme = createTheme({
  typography: {
    fontFamily: 'Nexa Extra Light',
  },
});

function App() {

  return (
    // <ThemeProvider theme={theme}>
    <LoaderProvider>
      <SnackbarProvider>
        <Router>
          <div className="App">
            {/* <Header /> */}
            <Routes>
              <Route path="/*" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/information" element={<ProfileSetupStep />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/user-profile" element={<Profile />} />
              <Route path="/shows" element={<Shows />} />
              <Route path="/setup-mfa" element={<SetupAuthenticator />} />
              <Route path="/shows/details/:ShowID" element={<ShowDetails />} />
              <Route path="/schedules" element={<Schedules />} />
              <Route path="/shows/create" element={<Schedule />} />
              <Route path="/yt" element={<SocialMediaYT />} />
              <Route path="/li" element={<SocialMediaLI />} />
              <Route path="/community" element={<Community />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/brand-central" element={<BrandCentral />} />
              <Route path="/meeting" element={<Meeting />} />
              <Route path="/left-meeting" element={<MeetingLeft />} />
              <Route path="/custombanner" element={<CustomBanner />} />
              <Route path="/setup-mfa" element={<SetupAuthenticator />} />
              <Route path="/chat" element={<Chat />} />
              <Route path="/connections" element={<ConnectionPage/>} />
            </Routes>
          </div>
        </Router>
      </SnackbarProvider>
    </LoaderProvider>
  );
}

export default App;
