import React from 'react';
import X from "../../../Assests/X.svg";
import userprofile from "../../../Assests/profile1.png";
import usersearch from "../../../Assests/usersearch.png";
import mute from "../../../Assests/mute.png";
import block from "../../../Assests/block.png";
import report from "../../../Assests/report.png";

function ChatProfile({ name, profileimage, setShowProfile }) {
  return (
    <div className="chat-profile">
      {/* Close Button (X Symbol) */}
      <div className="close-button"  style={{border:"none !important"}}onClick={() => { setShowProfile(false); }}>
        <img src={X} alt="Close" />
      </div>

      {/* Profile Image and Name */}
      <div className="profile-section">
        <img src={profileimage} alt={name} className="profile-image" />
        <span className="profile-name" style={{fontSize:"30px"}}>{name}</span>
      </div>

      {/* Icons Section */}
      <div className="icons-section">
        <div className="icon-container">
          <img src={userprofile} alt="Profile" className="icon icon-border" />
          <span className="icon-label">Profile</span> {/* Profile Text */}
        </div>
        <div className="icon-container">
          <img src={usersearch} alt="Search" className="icon icon-border" />
          <span className="icon-label">Search</span> {/* Search Text */}
        </div>
      </div>
      <div className="profile-options">
	  <div className="option-item" style={{textAlign:"center",justifyContent:"center"}}>
    		<span style={{fontSize:"24px"}}>Privacy and Support</span>
  	</div>

        {/* Mute Notifications */}
        <div className="option-item">
          <img src={mute} alt="Mute" className="icon" />
          <span>Mute Notifications</span>
        </div>

        {/* Block User */}
        <div className="option-item">
          <img src={block} alt="Block" className="icon" />
          <span>Block</span>
        </div>

        {/* Report User */}
        <div className="option-item">
          <img src={report} alt="Report" className="icon" />
          <span>
<div style={{display:"flex",flexDirection:"column"}}>
<span>Report</span>
          <span style={{fontSize:"12px"}}>Give Feedback and report this Conversations</span>
</div>
</span>
        </div>
      </div>
    </div>
  );
}

export default ChatProfile;
