import useEventListener from "./useEventListener";

export default function useClickOutside(refs, cb) {
	useEventListener(
		"click",
		(e) => {
			// Check if click is inside any of the given refs
			if (refs.some(ref => ref.current && ref.current.contains(e.target))) {
				return; // Do nothing if clicking inside
			}
			cb(e); // Close if clicking outside
		},
		document
	);
}

// import useEventListener from "./useEventListener";

// export default function useClickOutside(ref, cb) {
// 	useEventListener(
// 		"click",
// 		(e) => {
// 			if (ref.current == null || ref.current.contains(e.target)) return;
// 			cb(e);
// 		},
// 		document
// 	);
// }