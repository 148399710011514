import React, { useState, useRef, useEffect } from "react";
import noprofile from "../../../Assests/noprofile.svg";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { FaEllipsisV, FaPaperclip, FaSmile, FaTelegramPlane } from "react-icons/fa";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";

function ChatArea({ name, profileimage, receiverUID, setShowProfile }) {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false); // Emoji picker state
  const chatContentRef = useRef(null);
  const currentUID = localStorage.getItem("user_id");

  useEffect(() => {
    if (!receiverUID) return;

    const fetchMessages = async () => {
      try {
        const limit = 50;
        const messagesRequest = new CometChat.MessagesRequestBuilder()
          .setUID(receiverUID)
          .setLimit(limit)
          .build();

        const fetchedMessages = await messagesRequest.fetchPrevious();
        console.log("Fetched Messages:", fetchedMessages);

        setMessages(fetchedMessages);
        scrollToBottom();
      } catch (error) {
        console.error("Fetching messages failed:", error);
      }
    };

    fetchMessages();
  }, [receiverUID]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const listenerID = "chatMessageListener";

    CometChat.addMessageListener(
      listenerID,
      new CometChat.MessageListener({
        onTextMessageReceived: (message) => {
          console.log("New message received:", message);
          if (message.sender.uid === receiverUID) {
            setMessages((prevMessages) => [...prevMessages, message]);
          }
        },
      })
    );

    return () => {
      CometChat.removeMessageListener(listenerID);
    };
  }, [receiverUID]);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (chatContentRef.current) {
        chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
      }
    }, 100);
  };

  const handleSendMessage = () => {
    if (inputMessage.trim() === "") return;

    const textMessage = new CometChat.TextMessage(
      receiverUID,
      inputMessage.trim(),
      CometChat.RECEIVER_TYPE.USER
    );

    CometChat.sendMessage(textMessage).then(
      (message) => {
        console.log("Message Sent:", message);
        setMessages((prevMessages) => [...prevMessages, message]);
        scrollToBottom();
      },
      (error) => {
        console.error("Message sending failed:", error);
      }
    );

    setInputMessage("");
  };

  // Handle emoji selection
  const handleEmojiSelect = (emoji) => {
    setInputMessage((prevMessage) => prevMessage + emoji.native);
  };

  return (
    <div className="chat-area">
      <div className="chatarea-header">
        <div className="chat-user-info">
          <img src={profileimage || noprofile} alt="profile" className="user-avatar" />
          <span className="username">{name}</span>
        </div>
        <FaEllipsisV className="menu-icon" onClick={() => setShowProfile(true)} />
      </div>

      <div className="chat-content" ref={chatContentRef}>
        {messages.length === 0 ? (
          <p className="no-messages"></p>
        ) : (
          messages.map((msg, index) =>
            msg.type === "text" ? (
              <div
                key={index}
                className={`message-container ${msg.sender.uid === currentUID ? "sent-container" : "received-container"}`}
              >
                {msg.sender.uid !== currentUID && (
                  <img src={msg.sender?.avatar || noprofile} alt="profile" className="message-avatar" />
                )}
                <div className={`message ${msg.sender.uid === currentUID ? "sent" : "received"}`}>
                  <div className="message-content">
                    {msg.text}
                    <span className="message-time">
                      {new Date(msg.sentAt * 1000).toLocaleTimeString()}
                    </span>
                  </div>
                </div>
              </div>
            ) : null
          )
        )}
      </div>

      <div className="chat-input-section">
        <input
          type="text"
          placeholder="Send Message"
          className="message-input"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
        />
        <button className="send-button" onClick={handleSendMessage}>
          <FaTelegramPlane className="send-icon" />
        </button>

        {/* Emoji Picker */}
        <div style={{ position: "relative", display: "inline-block" }}>
          <FaSmile className="input-icon" onClick={() => setShowEmojiPicker(!showEmojiPicker)} />
          {showEmojiPicker && (
            <div style={{ position: "absolute", bottom: "40px", right: "0", zIndex: 10 }}>
<Picker data={data} onEmojiSelect={handleEmojiSelect} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChatArea;
