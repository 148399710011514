// AddParticipantsBTN.js
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import AddIcon from "@mui/icons-material/Add";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { AddButton, CancelButton } from "./styledButtons";
import { emailInvite } from "../../Services/ShowsApi";
import MobileIconButton from "../components/MobileIconButton";
import {
  useMeetingAppContext,
  sideBarModes,
} from "../MeetingAppContextDef";
import { useSnackbar } from "../../Components/SnackbarContext";
import Adduser from '../../Assests/Add User.svg'

const AddParticipantsBTN = ({ isMobile, isTab }) => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [emailIds, setEmailIds] = useState([]);
  const [emailInput, setEmailInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const { sideBarMode } = useMeetingAppContext();
  const theme = useTheme();
  const { triggerSnackbar } = useSnackbar();

  const handleButtonClick = () => setPopupVisible(true);
  const handleClosePopup = () => {
    setPopupVisible(false);
    setEmailInput("");
    setEmailIds([]);
    setErrorMessage("");
  };

  const handleAddEmail = () => {
    if (emailInput.trim()) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(emailInput.trim())) {
        setEmailIds([...emailIds, emailInput.trim()]);
        setEmailInput("");
        setErrorMessage("");
      } else {
        setErrorMessage("Invalid email address");
      }
    }
  };

  const handleAddButtonClick = async () => {
    if (emailIds.length === 0) {
      setErrorMessage("No emails added to send invites.");
      return;
    }
    const roomLink = `${window.location.origin}/meeting${window.location.search}&guest=true`;
    const payload = { email: emailIds, roomLink };

    try {
      await emailInvite(payload);
      triggerSnackbar("Invite sent");
      setPopupVisible(false);
    } catch (error) {
      setErrorMessage("Failed to send invites. Please try again later.");
    }
  };

  return (
    <>
      {isMobile || isTab ? (
        <MobileIconButton
          tooltipTitle="Add Participants"
          buttonText="Add"
          Icon={GroupAddIcon}
          isFocused={sideBarMode === sideBarModes.ADD_PARTICIPANTS}
          onClick={handleButtonClick}
        />
      ) : (
        <div
          onClick={handleButtonClick}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            cursor: "pointer",
            marginBottom: "15%",
            paddingLeft: "10px",
            minWidth: "100%",
            paddingRight: "10px",
            backgroundColor:
              sideBarMode === sideBarModes.ADD_PARTICIPANTS ? "white" : "transparent",
            color: sideBarMode === sideBarModes.ADD_PARTICIPANTS ? "black" : "white",
            border: "1px solid",
            borderColor:
              sideBarMode === sideBarModes.ADD_PARTICIPANTS ? "black" : "transparent",
            borderRadius: "4px",
            transition: "all 0.3s ease",
          }}
          title="Add Participants"
        >
          {/* <GroupAddIcon
            style={{
              fontSize: "24px",
              color: sideBarMode === sideBarModes.ADD_PARTICIPANTS ? "black" : "white",
            }}
          /> */}
          <img
            style={{
              fontSize: "24px",
              color: sideBarMode === sideBarModes.ADD_PARTICIPANTS ? "black" : "white",
            }}
            src={Adduser}
          />
          <span
            style={{
              fontSize: "12px",
              color: sideBarMode === sideBarModes.ADD_PARTICIPANTS ? "black" : "white",
            }}
          >
            Add
          </span>
        </div>
      )}
      <Snackbar
        open={!!snackBarMessage}
        autoHideDuration={5000}
        onClose={() => setSnackBarMessage("")}
        message="Invite sent"
        style={{ backgroundColor: "white", color: "black" }}
      />
      <Dialog open={popupVisible} onClose={handleClosePopup} fullWidth maxWidth="xs">
        <DialogTitle>Add Participants</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="filled"
            placeholder="Enter guest emails.."
            value={emailInput}
            onChange={(e) => {
              setEmailInput(e.target.value);
              setErrorMessage("");
            }}
            onKeyPress={(e) => e.key === "Enter" && handleAddEmail()}
            error={!!errorMessage}
            helperText={errorMessage}
            sx={{
              bgcolor: "rgba(255, 255, 255, 0.1)",
              borderRadius: 1,
              color: "white",
              width: "100%",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleAddEmail}>
                    <AddIcon sx={{ color: "white" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ style: { color: "white" } }}
          />
          {emailIds.length > 0 && (
            <div style={{ marginTop: "8px" }}>
              {emailIds.map((email, index) => (
                <Chip
                  key={index}
                  label={email}
                  color="primary"
                  variant="filled"
                  onDelete={() => setEmailIds(emailIds.filter((e) => e !== email))}
                  sx={{ margin: "2px", bgcolor: "rgba(255, 255, 255, 0.1)" }}
                />
              ))}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={handleClosePopup} color="primary">
            Cancel
          </CancelButton>
          <AddButton onClick={handleAddButtonClick} disabled={emailIds.length === 0}>
            Add
          </AddButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddParticipantsBTN;
