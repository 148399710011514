import React, { useState } from "react";
import light from "../Assests/Light.svg";
import pending from "../Assests/pending.svg";
import { updateConnects } from "../Services/ShowsApi";

const styles = {
  buttonContainer: {
    display: "flex",
    gap: "8px",
  },
  connectText: {
    textAlign: "right",
    color: "white",
    fontSize: 16,
    fontWeight: "bolder",
    letterSpacing: 0.64,
    wordWrap: "break-word",
  },
  connectIcon: {
    width: 24,
    height: 24,
    position: "relative",
    overflow: "hidden",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 16px",
    backgroundColor: "rgba(41, 104, 254, 0.8)",
    color: "#fff",
    border: "none",
    borderRadius: 16,
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    transition: "all 0.3s ease",
    gap: 8,
    "&:hover": {
      backgroundColor: "#0056b3",
    },
    "&:active": {
      backgroundColor: "#004080",
    },
    "&:disabled": {
      backgroundColor: "rgba(150, 150, 150, 0.5)",
      cursor: "not-allowed",
    },
  },
  pendingButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 16px",
    backgroundColor: "rgba(41, 104, 254, 0.8)",
    color: "#fff",
    border: "none",
    borderRadius: 16,
    cursor: "pointer",
    fontSize: "16px",
    transition: "all 0.3s ease",
    gap: 8,
    backgroundColor: "rgba(255, 166, 0, 0)",
    "&:hover": {
      backgroundColor: "rgba(255, 166, 0, 0)",
    },
  },
  connectedButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 16px",
    color: "#fff",
    border: "1px solid #fff",
    borderRadius: 16,
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    transition: "all 0.3s ease",
    gap: 8,
    padding: "8px 16px",
    backgroundColor: "rgba(255, 166, 0, 0)",
    "&:hover": {
        backgroundColor: "rgba(255, 166, 0, 0)",
    },
  },
};

const ConnectButton = ({ ConnectionStatus: initialStatus, ReceiverID }) => {
    const [connectionStatus, setConnectionStatus] = useState(initialStatus);
    const [isLoading, setIsLoading] = useState(false);
  
    const data = {
      SenderID: localStorage.getItem("user_id"),
      ReceiverID: ReceiverID,
    };
  
    const handleConnect = async () => {
      try {
        setIsLoading(true);
        await updateConnects(data);
        // Update status to PENDING after successful API call
        setConnectionStatus("PENDING");
      } catch (error) {
        console.error("Connection failed:", error);
      } finally {
        setIsLoading(false);
      }
    };
  
    // Don't show anything if it's the user's own profile
    if (connectionStatus === "SELF") {
      return null;
    }
  
    // Determine button properties based on connection status
    const getButtonConfig = () => {
      switch (connectionStatus) {
        case "NOT SENT":
          return {
            text: isLoading ? "Sending" : "Connect",
            onClick: handleConnect,
            style: styles.button,
            img: light,
            disabled: isLoading,
          };
        case "PENDING":
          return {
            text: "Pending",
            disabled: true,
            style: { ...styles.button, ...styles.pendingButton },
            img: pending,
          };
        case "ACCEPTED":
          return {
            text: "Connected",
            disabled: false,
            style: { ...styles.button, ...styles.connectedButton },
          };
        default:
          return {
            text: "Connect",
            onClick: handleConnect,
            style: styles.button,
            img: light,
          };
      }
    };
  
    const buttonConfig = getButtonConfig();
  
    return (
      <button
        style={buttonConfig.style}
        onClick={buttonConfig.onClick}
        disabled={buttonConfig.disabled}
      >
        <div style={styles.connectText}>{buttonConfig.text}</div>
        {buttonConfig.img && !isLoading && (
          <div style={styles.connectIcon}>
            <img src={buttonConfig.img} alt="Button icon" style={{ width: "100%", height: "100%" }} />
          </div>
        )}
        {isLoading && (
          <div style={styles.connectIcon}>
            {/* Add your loading spinner here if you have one */}
          </div>
        )}
      </button>
    );
  };

export default ConnectButton;