import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ProfileSetupStep.css";
import logo from "./../Assests/dyrect-logo.png";
import ProgressBar from "../Components/progressBar";
import LinkDialog from "../Components/linkDialog";
import SearchIcon from "@mui/icons-material/Search";
import insta from "../Assests/Insta.svg";
import facebook from "../Assests/Facebook.svg";
import spotify from "../Assests/Spotify.svg";
import linkedin from "../Assests/Linkedin.svg";
import youtube from "../Assests/Youtube.svg";
import edit from "../Assests/edit.svg";
import links from "../Assests/Link.svg";
import upload from "../Assests/upload.jpg";
import SentimentsHappy from "../Assests/SentimentsHappy.svg";
import { useDispatch, useSelector } from "react-redux";
import { setDataValue } from "../redux/dataSlice";
import { information } from "../Services/ShowsApi";
import { InterestTopics } from "../Constants/InterestTopics";
const ProfileSetupStep = () => {
  const navigate = useNavigate();
  const informationData = useSelector((state) => state?.data);
  const steps = [1, 2, 3];
  const [currentStep, setCurrentStep] = useState(1);
  const topics = InterestTopics;

  const payload = {
    UserID: localStorage.getItem("user_id"),
    Topics: informationData.Topics,
    Rate: informationData.Rate,
    Collaborate: informationData.Collaborate,
    Visibility: informationData.Visibility,
    ProfileImage: informationData.ProfileImage,
    Bio: informationData.Bio,
    FacebookLink: informationData.links?.Facebook,
    SpotifyLink: informationData.links?.Spotify,
    InstagramLink: informationData.links?.Instagram,
    LinkedinLink: informationData.links?.LinkedIn,
    YoutubeLink: informationData.links?.YouTube,
    IsProfileCompleted: true,
  };

  const selectedTopicsCount = 0;

  const handleNext = () => {
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handlePodcast = async () => {
    // await information(payload);
    navigate("/home");
  };

  return (
    <div className="profile-setup-step-1">
      <div className="profile-setup-step-1-child" />
      <div className="profile-setup-step-1-item" />
      <div className="logo-container">
        <img src={logo} alt="Dyrect Logo" className="logo-image" />
        <span className="logo-text">yrect</span>
      </div>

      {currentStep === 1 && (
        <Step1
          steps={steps}
          currentStep={currentStep}
          topics={topics}
          selectedTopicsCount={selectedTopicsCount}
          onNext={handleNext}
        />
      )}

      {currentStep === 2 && (
        <Step2
          steps={steps}
          currentStep={currentStep}
          onBack={handleBack}
          onNext={handleNext}
          payload={payload}
        />
      )}

      {currentStep === 3 && (
        <Step3
          steps={steps}
          currentStep={currentStep}
          onPodcast={handlePodcast}
        />
      )}
    </div>
  );
};

// Step 1 Component
const Step1 = ({ steps, currentStep, topics, selectedTopicsCount, onNext }) => {
  const informationData = useSelector((state) => state?.data);
  const [error, setError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const handleNextClick = () => {
    if (informationData?.Topics.length === 0) {
      setError("Please select at least one topic.");
      setIsButtonDisabled(true);
    } else if (isButtonDisabled === false) {
      setError("");
      onNext();
    }
  };

  useEffect(() => {
    if (informationData?.Topics?.length === 0 || informationData?.errorRate) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [informationData?.errorRate, informationData?.Topics]);

  return (
    <div className="setup-1">
      <ProgressBar steps={steps} currentStep={currentStep} />
      <div className="lets-get-to">Let’s get to know you!</div>
      <div className="header-parent">
        <Header
          title="Tell us what interests you."
          subtitle="Pick up to 5 topics that excite you!"
        />
        <TagSelection topics={topics} selectedTopicsCount={selectedTopicsCount} error={error} />
      </div>
      <div className="collab-preferences">
        <CollaborationRate />
        <CollaborationSwitch />
      </div>
      <div className="button-parent">
        <Button
          label="Next"
          className="button2"
          onClick={handleNextClick}
          disabled={isButtonDisabled}
          style={{ marginLeft: "80%", backgroundColor: isButtonDisabled ? "rgba(212, 216, 225, 0.8)" : "rgba(41, 104, 254, 0.8)" }}
        />
      </div>
    </div>
  )
};

// Step 2 Component
const Step2 = ({ steps, currentStep, onBack, onNext, payload }) => {
  const dispatch = useDispatch();
  const informationData= useSelector((state) => state.data);
  const [bio, setBio] = useState(informationData?.Bio || "");
  const [error, setError] = useState("");

  useEffect(() => {
    dispatch(setDataValue({ Bio: bio }));
    if (bio.trim() === "") {
      // setError("Bio cannot be empty.");
    } else {
      setError("");
    }
  }, [bio, dispatch]);

  const handleNextClick = async () => {
    if (bio.trim() === "") {
      setError("Bio cannot be empty.");
    } else {
      setError("");
      onNext();
      await information(payload);
    }
  };

  return (
    <div className="signup-box">
      <ProgressBar steps={steps} currentStep={currentStep} />
      <div className="top-section">
        <ProfilePicture />
        <Bio bio={bio} setBio={setBio} error={error} setError={setError} />
      </div>
      <ProfileVisibility />
      <SocialLinks />
      <div className="socials">
        <Button label="Back" className="button5" onClick={onBack} />
        <Button label="Next" className="button6" onClick={handleNextClick} />
      </div>
    </div>
  );
};

// Step 3 Component
const Step3 = ({ steps, currentStep, onPodcast }) => (
  <div className="signup-box">
    <ProgressBar steps={steps} currentStep={currentStep} />
    <div className="header-signup">
      <div className="youre-all-set">You’re All Set!</div>
      <div className="your-profile-is">
        Your profile is now complete! Start exploring, creating, and
        collaborating with ease.
      </div>
    </div>
    <img className="happy-icon" alt="" src={SentimentsHappy} />
    <div className="your-profile-will">
      Your profile will now be visible in the Community Center, allowing others
      to discover and connect with you based on shared interests. You can update
      your profile settings anytime.
    </div>
    <Button
      label="Start Your First Podcast"
      className="button6"
      onClick={onPodcast}
    />
  </div>
);

// Profile Picture Component
const ProfilePicture = () => {
  const dispatch = useDispatch();
  const informationData = useSelector((state)=>state.data)
  const [image, setImage] = useState(informationData?.profileImg || null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      let prefix = "data:image/png;base64,";

      reader.onloadend = () => {
        setImage(reader.result);
        dispatch(setDataValue({ profileImg: reader.result }));
      };
      reader.readAsDataURL(file);
      console.log("hhhhhhhhh", image);
    }
  };

  useEffect(() => {
    console.log({ image });
    let prefix = "data:image/png;base64,";
    if (image?.startsWith("data:image/jpeg")) {
      prefix = "data:image/jpeg;base64,";
    }
    const base64 = image?.substring(prefix.length);
    console.log({ base64 });
    if (base64) {
      dispatch(setDataValue({ ProfileImage: base64 }));
    }
  }, [image]);

  return (
    <div className="profile-picture">
      <div className="header">
        <div className="upload-a-profile">Upload a Profile Picture</div>
        <div className="dding-a-photo">
          Adding a photo makes your profile stand out
        </div>
      </div>

      <div className="edit-wrapper">
        <label htmlFor="profile-upload" className="edit">
          <img className="layer-2-icon" alt="Profile" src={image || upload} />
          <img
            className="profile-logo"
            alt="Logo"
            src={edit}
            style={{ backgroundColor: "rgba(255, 255, 255, 0.16)" }}
          />
        </label>
        <input
          type="file"
          id="profile-upload"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: "none" }} // Hide default file input
        />
      </div>
    </div>
  );
};

// Bio Component
const Bio = ({ bio, setBio, error, setError }) => {
  const handleChange = (e) => {
    if (e.target.value.length <= 300) {
      setBio(e.target.value);
      setError(""); // Clear the error when the user types
    }
  };

  return (
    <div className="bio">
      <div className="header">
        <div className="upload-a-profile">Your Bio</div>
        <div className="dding-a-photo">
          Share a little about yourself, your podcast, or what excites you!
        </div>
      </div>
      <div className="bio1">
        <textarea
          className="bio2"
          value={bio}
          onChange={handleChange}
          placeholder="Write your bio..."
        />
        <div className="div4">{bio.length}/300</div>
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

// Profile Visibility Component
const ProfileVisibility = () => {
  const dispatch = useDispatch();
  const informationData = useSelector((state)=>state.data)
  const [selectedOption, setSelectedOption] = useState(informationData?.Visibility || "ALL"); // Default selection

  useEffect(() => {
    dispatch(setDataValue({ Visibility: selectedOption }));
  }, [selectedOption]);

  return (
    <div className="collab-rate">
      <div className="header2">
        <div className="upload-a-profile">Who can see your profile?</div>
        <div className="your-profile-is">
          Your profile is visible to the community by default, allowing other
          podcasters to discover and connect with you. You can change this
          anytime in your Privacy Settings.
        </div>
      </div>
      <div className="visibility-status">
        <RadioButton
          label="Visible to Everyone"
          description="Your profile is visible to all users in the community."
          selected={selectedOption === "ALL"}
          onChange={() => setSelectedOption("ALL")}
        />
        <RadioButton
          label="Visible to Connections Only"
          description="Only users you connect with can see your profile."
          selected={selectedOption === "CONNECTIONS"}
          onChange={() => setSelectedOption("CONNECTIONS")}
        />
      </div>
    </div>
  );
};

// Radio Button Component (Keeping Existing Class Names)
const RadioButton = ({ label, description, selected, onChange }) => (
  <div className="radio-button-parent">
    <label className="radio-button" onClick={onChange}>
      <div className="radio-button1">
        <div className={` ${selected ? "radio-button-child" : ""}`} />
      </div>
      <div className="button-label">{label}</div>
    </label>
    <div className="only-users-you">{description}</div>
  </div>
);

// Social Links Component
const SocialLinks = () => {
  const informationData = useSelector((state) => state.data);
  const [links, setLinks] = useState(informationData?.links || {}); // Store links for each platform
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDataValue({ links: links }));
  }, [links]);

  const validateURL = (url) => {
    const urlPattern = /^(https?:\/\/)?([\w\-]+\.)+[\w\-]+(\/[\w\-./?%&=]*)?$/;
    return urlPattern.test(url);
  };

  const handleSaveLink = (platformName, link) => {
    if (validateURL(link)) {
      setLinks((prevLinks) => ({
        ...prevLinks,
        [platformName]: link, // Update only the specific platform
      }));
    }else{
      console.log("invalidUrl")
    }


  };

  return (
    <div className="collab-rate">
      <div className="header2">
        <div className="upload-a-profile">
          Where can others find you online?
        </div>
        <div className="your-profile-is">
          Add links to your podcast or social media so collaborators can easily
          find your work. You can update these anytime in your profile settings.
        </div>
      </div>
      <div className="socials">
        {[
          { name: "Facebook", icon: facebook },
          { name: "Spotify", icon: spotify },
          { name: "Instagram", icon: insta },
          { name: "LinkedIn", icon: linkedin },
          { name: "YouTube", icon: youtube },
        ].map(({ name, icon }) => (
          <SocialLink
            key={name}
            platform={icon}
            platformName={name}
            link={links[name]} // Pass stored link
            onSave={handleSaveLink} // Pass save function
          />
        ))}
      </div>
    </div>
  );
};

// Social Link Component
const SocialLink = ({ platform, platformName, link, onSave }) => {
  const [inputValue, setInputValue] = useState(link || ""); // Store input value
  const [isModalOpen, setIsModalOpen] = useState(false); // Control popup

  useEffect(() => {
    setInputValue(link || ""); // Sync state when link updates
  }, [link]);

  const handleSave = (value) => {
    onSave(platformName, value); // Save link to parent
    setIsModalOpen(false); // Close modal
  };

  return (
    <div className="fb">
      <img className="socialsfacebook-icon" alt="" src={platform} />

      <div className="button22">
        <div
          className="text"
          onClick={() => setIsModalOpen(true)} // Open popup
          style={{ cursor: "pointer" }}
        >
          {link ? (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "white",
                textDecoration: "none",
                textShadow: "0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff0080, 0 0 30px #ff0080, 0 0 40px #ff0080, 0 0 50px #ff0080, 0 0 75px #ff0080"
              }}
            >
              Linked
            </a>
          ) : (
            "Link"
          )}
        </div>
        <img
          className="link-icon"
          alt=""
          src={links} // Change icon if needed
          onClick={() => setIsModalOpen(true)}
          style={{ cursor: "pointer" }}
        />
      </div>

      {/* LinkDialog Component */}
      <LinkDialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleSave}
        title={`Edit ${platformName} Link`}
        inputValue={inputValue}
        setInputValue={setInputValue}
      />
    </div>
  );
};

// Header Component
const Header = ({ title, subtitle }) => (
  <div className="header">
    <div className="tell-us-what">{title}</div>
    <div className="pick-up-to">{subtitle}</div>
  </div>
);

// Tag Selection Component
const TagSelection = ({ topics, error }) => {
  const informationData = useSelector((state) => state?.data);
  const dispatch = useDispatch();
  const [selectedTopics, setSelectedTopics] = useState( informationData?.Topics|| []); // Store selected topics
  const [searchTerm, setSearchTerm] = useState(""); // Store search input
  const [customTopics, setCustomTopics] = useState([]); // Store custom topics separately

  // Handle topic selection and removal
  const toggleTopicSelection = (topic) => {
    setSelectedTopics(
      (prev) =>
        prev.includes(topic)
          ? prev.filter((t) => t !== topic) // Remove if already selected
          : prev.length < 5
            ? [...prev, topic] // Add if not already selected
            : prev // Limit selection to 5 topics
    );
  };

  useEffect(() => {
    dispatch(setDataValue({ Topics: selectedTopics }));
  }, [selectedTopics]);

  // Remove a selected topic
  const removeSelectedTopic = (topic) => {
    setSelectedTopics((prev) => prev.filter((t) => t !== topic));
  };

  // Handle adding a new topic when pressing Enter
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const newTopic = searchTerm.trim();
      if (
        newTopic &&
        !topics.includes(newTopic) &&
        !selectedTopics.includes(newTopic) &&
        !customTopics.includes(newTopic) &&
        selectedTopics.length < 5
      ) {
        setCustomTopics((prev) => [...prev, newTopic]); // Add to custom topics
        setSelectedTopics((prev) => [...prev, newTopic]); // Move to selected
      }
      setSearchTerm(""); // Clear search input
    }
  };

  // Filter topics based on search and remove selected topics
  const filteredTopics = topics
    .filter((topic) => topic.toLowerCase().includes(searchTerm.toLowerCase()))
    .filter((topic) => !selectedTopics.includes(topic)); // Remove selected topics

  // const [error, setError] = useState('');

  // Check if selectedTopics is empty and set the error message
  // React.useEffect(() => {
  //   if (selectedTopics.length === 0) {
  //     setError('Please select at least one topic');
  //   } else {
  //     setError('');
  //   }
  // }, [selectedTopics]);

  return (
    <div className="tag-selection">
      <div className="frame-parent">
        <SelectedTopics count={selectedTopics.length} />
        <SearchBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleKeyDown={handleKeyDown}
        />

        {/* Selected Topics Section - Now BELOW Search Bar */}
        {selectedTopics.length > 0 ? (
          <div className="selected-tags">
            {selectedTopics.map((topic, index) => (
              <div key={index} className="tag89">
                <span className="topic">{topic}</span>
                <span className="remove-icon" onClick={() => removeSelectedTopic(topic)}>✖</span>
              </div>
            ))}
          </div>
        ) : (
          <p className="error-message">{error}</p>
        )}
      </div>


      {/* Recommended Topics - Only predefined topics (not custom ones) */}
      <RecommendedTopics
        topics={filteredTopics}
        selectedTopics={selectedTopics}
        toggleTopicSelection={toggleTopicSelection}
      />
    </div>
  );
};

// Selected Topics Counter
const SelectedTopics = ({ count }) => (
  <div className="your-selected-topics-parent">
    <div className="tell-us-what">Your Selected Topics</div>
    <div className="div3">{count}/5</div>
  </div>
);

// Search Bar Component
const SearchBar = ({ searchTerm, setSearchTerm, handleKeyDown }) => (
  <div className="search">
    <div className="search-bar">
      <input
        type="text"
        className="search-input"
        placeholder="Search or add a topic..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={handleKeyDown} // Press Enter to add custom topic
      />
      <SearchIcon className="search-icon" />
    </div>
  </div>
);

// Recommended Topics Component (Only predefined topics)
const RecommendedTopics = ({
  topics,
  selectedTopics,
  toggleTopicSelection,
}) => (
  <div className="recommended-for-you-parent">
    <div className="tell-us-what">Recommended for You</div>
    <div className="recommended">
      {topics.map((topic, index) => (
        <button
          className={`tag ${selectedTopics.includes(topic) ? "selected" : ""}`}
          key={index}
          onClick={() => toggleTopicSelection(topic)}
        >
          <div className="topic">{topic}</div>
          {selectedTopics.includes(topic) && (
            <img className="tick-icon" alt="Selected" src={`/tick.svg`} />
          )}
        </button>
      ))}
    </div>
  </div>
);

// Collaboration Preferences Component

// Collaboration Rate Component
const CollaborationRate = ({ disable }) => {
  const dispatch = useDispatch();
  const [rate, setRate] = useState(20); // State to manage rate
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      if (value === '' || parseInt(value, 10) <= 999) {
        setRate(value);
        dispatch(setDataValue({ Rate: value }));
        dispatch(setDataValue({ errorRate: null }));
        setError(''); // Clear any previous error
      } else {
        setError('Value cannot exceed 999');
        dispatch(setDataValue({ errorRate: 'Value cannot exceed 999' }));
      }
    } else {
      setError('Only numeric values are allowed');
      dispatch(setDataValue({ errorRate: 'Only numeric values are allowed' }));
    }
  };

  const handleBlur = () => {
    if (rate === '') {
      setError('Value cannot be empty');
      dispatch(setDataValue({ errorRate: 'Value cannot be empty' }));
    }
  };

  return (
    <div className="collab-rate">
      <Header
        title="Set Your Collaboration Rate"
        subtitle="If you charge for collaborations, set an hourly rate. Otherwise, leave it blank."
      />
      <div className="rate-confirmation">
        <div className="hourly-rate">Hourly rate</div>
        <div className="rate">
          <div className="hourly-rate">$</div>
          <div className="value">
            <div className="wrapper">
              <input
                type="text"
                value={rate}
                onChange={handleChange}
                onBlur={handleBlur}
                className="div9 custom-input"
                disabled={disable}
              />
            </div>
          </div>
          <div className="hourly-rate">/Hr</div>
        </div>
      </div>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

// Collaboration Switch Component
const CollaborationSwitch = () => (
  <div className="collab">
    <Header
      title="Are you open to collaborating with others?"
      subtitle="Let fellow podcasters know if you're available for collaborations. You can always change this later."
    />
    <div className="switch">
      <div className="switch1">
        <Switch />
        <div className="title">
          <div className="hourly-rate">Open to Collaborate</div>
        </div>
      </div>
    </div>
  </div>
);

// Button Component
const Button = ({ label, className, onClick, style }) => (
  <button
    className={className}
    onClick={onClick}
    style={{ ...style, border: "0px" }}
  >
    <div className="topic">{label}</div>
    <div className="messagelight">
      <div className="msg">
        <div className="msg-child" />
        <div className="msg-item" />
        <div className="msg-inner" />
      </div>
    </div>
  </button>
);

const Switch = () => {
  const dispatch = useDispatch();
  const informationData = useSelector((state) => state.data);
  const [isOn, setIsOn] = useState(informationData?.Collaborate || false);

  const toggleSwitch = () => {
    setIsOn(!isOn);
    dispatch(setDataValue({ Collaborate: !isOn }));
  };

  const styles = {
    switchContainer: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      cursor: "pointer",
    },
    switchTrack: {
      width: "45px",
      height: "25px",
      borderRadius: "15px",
      backgroundColor: isOn ? "#4caf50" : "gray",
      position: "relative",
      transition: "background-color 0.3s ease",
    },
    switchThumb: {
      width: "21px",
      height: "21px",
      borderRadius: "50%",
      backgroundColor: "white",
      position: "absolute",
      top: "2px",
      left: isOn ? "21px" : "2px",
      transition: "transform 0.3s ease",
    },
    switchLabel: {
      marginLeft: "10px",
      fontSize: "14px",
      color: "#333",
    },
  };

  return (
    <div style={styles.switchontainer} onClick={toggleSwitch}>
      <div style={styles.switchTrack}>
        <div style={styles.switchThumb} />
      </div>
    </div>
  );
};

export default ProfileSetupStep;
