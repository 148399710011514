import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, MenuItem, Select, InputLabel, FormControl, Typography } from '@mui/material';
import { uploadToLI, getLinkedinPost } from '../Services/SchedulesApi';
import SuccessModal from '../Components/SuccessModal';
import ErrorModal from '../Components/ErrorModal';
import Loader from "../Components/Loader";

const LIPopup = ({ data, closePopup }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(true);
    const [title, setTitle] = useState(data?.ShowName || "");
    const [description, setDescription] = useState("");


    // Success modal state
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    // Error modal state
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isUploadEnabled, setIsUploadEnabled] = useState(false);
    const [loading, setLoading] = useState(true);

    const [selectedVideo, setSelectedVideo] = useState({
        type: data?.ShowURL ? 'full' : '',
        url: data?.ShowURL || ''
    });

    useEffect(() => {
        const fetchDescription = async () => {
            setLoading(true); // Set loading state
            try {
                const response = await getLinkedinPost(data?.ShowID);
                console.log("response:", response);
                const postDescription = response?.data?.linkedinPost || ''; // Fallback to an empty string
                console.log("response data:", postDescription);
                setDescription(postDescription || '');
            } catch (error) {
                console.error("Error fetching LinkedIn post description:", error);
                setDescription(data?.summary || ''); // Fallback to data.summary or empty string
            } finally {
                setLoading(false);
            }
        };

        if (data?.ShowID) {
            fetchDescription();
        } else {
            setDescription(data?.summary || '');
        }

        setTitle(data?.ShowName || '');
    }, [data]);

    useEffect(() => {
        setIsUploadEnabled(!!description && !!title); // Enable if both video and title are filled
    }, [description, title]);

    const closeDialog = () => {
        setIsDialogOpen(false);
    };


    const handleUpload = async () => {
        const finalTitle = title || data?.ShowName;
        let videoUrl = selectedVideo?.url ? selectedVideo?.url : data?.ShowURL;
        const parsedUrl = new URL(videoUrl);
        const bucketName = parsedUrl.hostname.split(".")[0];

        const path = parsedUrl.pathname;
        const keyName = path.startsWith("/") ? path.slice(1) : path;

        const finalDescription = description;
        const payload = {
            accessCode: localStorage.getItem('code_li'),
            title: finalTitle,
            commentary: finalDescription,
            showID: data?.ShowID || '',
            userID: localStorage.getItem("user_id"),
            bucketName,
            objectKey: keyName,
        };
        console.log("payload:", payload);

        try {
            const response = await uploadToLI(payload);
            console.log("response", response);
            if (response?.data?.statusCode == 200) {
                setSuccessMessage("Video uploaded successfully!");
                setIsSuccessModalOpen(true); // Open success modal
            } else {
                // setErrorMessage("An error occurred while uploading the post. Please try again later.");
                setErrorMessage("An error occurred while uploading the post. Please upload a shorter video and try again.");
                setIsErrorModalOpen(true); // Open error modal
            }
        } catch (error) {
            console.error("Error uploading video:", error);
            setErrorMessage("An error occurred while uploading the post. Please try again later.");
            setIsErrorModalOpen(true); // Open error modal
        }
        closeDialog();
    };

    useEffect(() => {
        setTitle(data?.ShowName || '');
    }, [data]);

    const handleVideoSelection = (video) => {
        setSelectedVideo(video);
        setTitle(video.type === 'full' ? (data?.ShowName) : (data?.ShowName) + " Promo Video")
    };

    return (
        <div>
            <Dialog
                open={isDialogOpen}
                onClose={closePopup}
                fullWidth
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: 'var(--theme-bg)',
                        color: 'white',
                    },
                }}>

                <DialogTitle>LinkedIn Post Details</DialogTitle>
                <DialogContent>
                    <FormControl
                        variant="filled"
                        fullWidth
                        margin="normal"
                        sx={{
                            bgcolor: 'rgba(255, 255, 255, 0.1)',
                            borderRadius: 1,
                            '.MuiInputLabel-root': {
                                color: 'white',
                            },
                            '.Mui-focused .MuiInputLabel-root': {
                                color: 'white',
                            },
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '8px',
                        }}
                    >

                        <InputLabel sx={{ color: 'white' }}>
                            Select Video<span>*</span>
                        </InputLabel>
                        <Select
                            value={selectedVideo?.url ? JSON.stringify(selectedVideo) : " Select Video"}
                            onChange={(e) => handleVideoSelection(JSON.parse(e.target.value))}
                            sx={{
                                color: 'white',
                                '.MuiSelect-icon': { color: 'white' },
                                '&:focus': {
                                    backgroundColor: 'transparent',
                                },
                                flex: 1,
                            }}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 150,
                                        width: 250,
                                    },
                                },
                            }}
                        >
                            {/* Full Video */}
                            <MenuItem value={JSON.stringify({ type: 'full', url: data?.ShowURL })}>
                                {data?.ShowName}
                            </MenuItem>

                            {/* Promo Videos */}
                            {data?.PromoVideos?.length > 0 ? (
                                data.PromoVideos.map((promo, index) => (
                                    <MenuItem key={index} value={JSON.stringify({ type: 'promo', url: promo.url })}>
                                        {data?.ShowName} Promo Video {index + 1}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem disabled>No promo videos available</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <TextField
                        label="Title"
                        variant="filled"
                        InputProps={{ style: { color: 'white' } }}
                        InputLabelProps={{ style: { color: 'white' } }}
                        sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 1 }}
                        fullWidth
                        margin="normal"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                    {loading ? (
                        <Loader message="Fetching AI generated LinkedIn Post..." />
                    ) : (
                        <TextField
                            label="Description"
                            variant="filled"
                            InputProps={{
                                style: { color: 'white' },
                            }}
                            InputLabelProps={{
                                style: { color: 'white' },
                            }}
                            sx={{
                                bgcolor: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: 1,
                            }}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={6}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} sx={{ color: 'white' }}>Cancel</Button>
                    <Button
                        onClick={handleUpload}
                        sx={{
                            bgcolor: !isUploadEnabled
                                ? '#B0B0B0' // Gray color for disabled state
                                : 'linear-gradient(to right, #472DDB, #0985FB, #00D7FD)', // Gradient background for enabled state
                            color: 'white',
                            '&:hover': {
                                bgcolor: !isUploadEnabled ? '#B0B0B0' : 'linear-gradient(to right, #472DDB, #0985FB, #00D7FD)', // Keep same background on hover
                            },
                            border: '1px solid white'
                        }}
                        disabled={!isUploadEnabled} // Save button disabled unless both first and last name are filled
                    >
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Success Modal */}
            <SuccessModal
                open={isSuccessModalOpen}
                message={
                    <>
                        Your content has been successfully uploaded to LinkedIn! You can now visit your LinkedIn profile to see the post.
                    </>
                }
                onClose={() => setIsSuccessModalOpen(false)}
            />
            {/* Error Modal */}
            <ErrorModal
                open={isErrorModalOpen}
                message={errorMessage}
                onClose={() => setIsErrorModalOpen(false)}
            />
        </div>
    );
};

export default LIPopup;