import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { FaFacebook, FaLinkedin, FaSpotify, FaItunes, FaYoutube } from 'react-icons/fa';
import './Pages.css';
import { authorize } from '../Services/SchedulesApi';
import { getShowDetails } from "../Services/ShowsApi";
import ErrorModal from '../Components/ErrorModal';
import { IconButton, Tooltip } from '@mui/material';

const parsedUrl = new URL(window.location.href);
const redirectUri = `${parsedUrl.protocol}//${parsedUrl.host}/li`;

const SocialMediaShare = () => {
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { ShowID } = useParams();

  // Trigger OAuth2 authorization flow
  const handleYouTubeAuth = async () => {
    const response = await authorize();
    console.log("response::", response);
    if (response?.data?.body?.body) {
      localStorage.setItem('previousPage_yt', window.location.pathname);
      window.location.href = response.data.body.body;
    }
  };

  const handleLinkedinAuth = async () => {
    try {
      // Fetch the show details using ShowID
      const response = await getShowDetails(ShowID);
      console.log(response);

      // Check if YoutubeURL exists and is not null or an empty string
      const youtubeURL = response?.data?.Data?.YouTubeURL;


      const linkedInAuthURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86shqxoly8bgso&redirect_uri=${redirectUri}&state=myposttestdata&scope=openid%20profile%20w_member_social%20email`;
      localStorage.setItem('previousPage_li', window.location.pathname);
      window.location.href = linkedInAuthURL;

      // if (youtubeURL && youtubeURL.trim() !== "") {
      //   const linkedInAuthURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86shqxoly8bgso&redirect_uri=${redirectUri}&state=myposttestdata&scope=openid%20profile%20w_member_social%20email`;
      //   localStorage.setItem('previousPage_li', window.location.pathname);
      //   window.location.href = linkedInAuthURL;
      // } else {
      //   setErrorMessage("Please make sure to upload your content to YouTube first before sharing it on LinkedIn.");
      //   setIsErrorModalOpen(true);
      // }
    } catch (error) {
      console.error("Error fetching show details:", error);
      setErrorMessage("An error occurred please try again later.");
      setIsErrorModalOpen(true);
    }
  };

  return (
    <div className="social-media-share__section mt-3">
      <h2 className="social-media-share__heading">Share</h2>
      <div className="social-media-share__icons">
        <div
          className="social-media-share__icon-container"
        >
          <Tooltip
            title={
              <span style={{ fontSize: '10px' }}>
                Please create a YouTube channel before you share your content. For reference, you can follow this{" "}
                <a
                  href="https://youtu.be/aKydtOXW8mI?feature=shared"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#90caf9", textDecoration: "underline" }} // MUI blue color
                >
                  video
                </a>
              </span>
            }
            arrow
          >
            {/* <FaYoutube className="social-media-share__icon youtube" onClick={handleYouTubeAuth} /> */}
            <IconButton onClick={handleYouTubeAuth}>
              <FaYoutube className="social-media-share__icon youtube" />
            </IconButton>
          </Tooltip>
        </div>
        <Tooltip
          title={
            <span style={{ fontSize: '10px' }}>
              Share your podcast on LinkedIn.
            </span>
          }
        >
          <div className="social-media-share__icon-container">
            <FaLinkedin className="social-media-share__icon linkedin" onClick={handleLinkedinAuth} />
          </div>
        </Tooltip>
        <Tooltip
          title={
            <span style={{ fontSize: '10px' }}>
              Upcoming feature....Share your podcast on Facebook.
            </span>
          }
        >
          <div className="social-media-share__icon-container">
            <FaFacebook className="social-media-share__icon facebook" />
          </div>
        </Tooltip>
        <Tooltip
          title={
            <span style={{ fontSize: '10px' }}>
              Upcoming feature....Share your podcast on Spotify.
            </span>
          }
        >
          <div className="social-media-share__icon-container">\
            <FaSpotify className="social-media-share__icon spotify" />
          </div>
        </Tooltip>
        <Tooltip
          title={
            <span style={{ fontSize: '10px' }}>
              Upcoming feature....Share your podcast on Itunes.
            </span>
          }
        >
          <div className="social-media-share__icon-container">
            <FaItunes className="social-media-share__icon itunes" />
          </div>
        </Tooltip>
      </div>
      <ErrorModal
        open={isErrorModalOpen}
        message={errorMessage}
        onClose={() => setIsErrorModalOpen(false)}
      />
    </div>
  );
};

export default SocialMediaShare;