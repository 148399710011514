import MSG from "../../../Assests/MSG.svg";

function EmptyChatArea() {
  return (
    <div className="chat-area">
      <div className="center-content">
        <img
          src={MSG}
          alt="Illustration of messages"
          className="empty-chat-icon"
        />
        <span className="message-text">
          Start a Conversation to connect with podcasters and explore
          collaborations
        </span>
      </div>
      <button className="message-button" aria-label="Send a message">
        Send a Message
        <img
          src={MSG}
          alt="Send message icon"
          className="send-message-icon"
        />
      </button>
    </div>
  );
}

export default EmptyChatArea;
