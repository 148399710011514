import React, { useEffect, useState } from "react";
import { getTopCreators } from "../../Services/ShowsApi";
import light from "../../Assests/Light.svg";
import { MarginRounded } from "@mui/icons-material";
import profileIcon from "../../Assests/profile.png";
import ConnectButton from "../../Components/ConnectButton";

const CreatorCard = ({ FirstName, LastName, ShowCount, ProfileImageKey,ConnectionStatus,UserID}) => {
  return (
    <div style={styles.creatorCard}>
      <div style={styles.creatorInfo}>
        <div
          style={{
            ...styles.avatar,
            backgroundImage: `url(${ProfileImageKey || profileIcon})`,
          }}
        />
        <div style={styles.creatorDetails}>
          <div style={styles.creatorName}>{FirstName + " " + LastName}</div>
          <div style={styles.creatorRole}>{ShowCount}</div>
        </div>
      </div>
      <ConnectButton ConnectionStatus={ConnectionStatus} ReceiverID={UserID}/>
    </div>
  );
};

const Creators = ({ creators, onDropdownChange }) => {
  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    console.log("Selected value:", selectedValue);
    // Notify the parent component about the dropdown change
    onDropdownChange(selectedValue);
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.title}>Top Creators</div>
        <select style={styles.dropdown} onChange={handleDropdownChange}>
          <option style={styles.option} value="all_time">
            All Time
          </option>
          <option style={styles.option} value="last_week">
            Last Week
          </option>
          <option style={styles.option} value="last_month">
            Last Month
          </option>
          <option style={styles.option} value="last_year">
            Last Year
          </option>
        </select>
      </div>
      <div style={styles.creatorList}>
        {creators.map((creator, index) => (
          <CreatorCard key={index} {...creator} />
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    height: "100%",
    padding: 16,
    background: "rgba(0, 0, 0, 0.10)",
    overflow: "hidden",
    borderRadius: 24,
    outline: "1px rgba(175.31, 175.31, 175.31, 0.20) solid",
    outlineOffset: "-1px",
    display: "flex",
    flexDirection: "column",
    gap: 24,
  },
  title: {
    alignSelf: "stretch",
    color: "white",
    fontSize: 24,
    fontWeight: "bolder",
    letterSpacing: 1.92,
    wordWrap: "break-word",
  },
  dropdown: {
    padding: "8px",
    fontSize: "16px",
    borderRadius: "13px",
    cursor: "pointer",
    backgroundColor: "rgba(255, 255, 255, 0.08)",
    border: "1px solid rgba(255, 255, 255, 0.2)",
    color: "white",
  },
  option: {
    backgroundColor: "rgba(255, 255, 255, 0.08)",
    color: "black",
  },
  header: {
    display: "flex",
    justifyContent: "space-between", // Align title and dropdown
    alignItems: "center", // Vertically center items
    marginBottom: "16px", // Space between header and creator list
  },
  creatorList: {
    alignSelf: "stretch",
    flex: 1,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 8,
    minHeight: "380px",
    maxHeight: "380px",
    overflowY: "auto", // Enable vertical scrolling
    scrollbarWidth: "none", // Hide scrollbar in Firefox
    msOverflowStyle: "none", // Hide scrollbar in IE/Edge
    "&::-webkit-scrollbar": {
      // Hide scrollbar in WebKit browsers (Chrome, Safari)
      display: "none",
    },
  },
  creatorCard: {
    alignSelf: "stretch",
    padding: 8,
    background: "rgba(71, 45, 219, 0.10)",
    borderRadius: 16,
    backdropFilter: "blur(8px)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  creatorInfo: {
    alignSelf: "stretch",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 16,
  },
  avatar: {
    width: 64,
    height: 64,
    padding: 16,
    borderRadius: 64,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
  },
  creatorDetails: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: 8,
  },
  creatorName: {
    color: "white",
    fontSize: 16,
    fontWeight: "bolder",
    letterSpacing: 1.28,
    wordWrap: "break-word",
  },
  creatorRole: {
    color: "white",
    fontSize: 12,
    fontWeight: "bolder",
    letterSpacing: 0.24,
    wordWrap: "break-word",
  },
  connectButton: {
    padding: "8px 16px",
    background: "rgba(41, 104, 254, 0.8)",
    borderRadius: 16,
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
  },
  connectText: {
    textAlign: "right",
    color: "white",
    fontSize: 16,
    fontWeight: "bolder",
    letterSpacing: 0.64,
    wordWrap: "break-word",
  },
  connectIcon: {
    width: 24,
    height: 24,
    position: "relative",
    overflow: "hidden",
  },
  iconInner: {
    width: 16,
    height: 16,
    left: 4,
    top: 4,
    position: "absolute",
    background: "white",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 16px",
    backgroundColor: "rgba(41, 104, 254, 0.8)", // Default background color
    color: "#fff", // Text color
    border: "none",
    borderRadius: 16,
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    transition: "background-color 0.3s ease",
    ":hover": {
      backgroundColor: "#0056b3", // Hover background color
    },
    ":active": {
      backgroundColor: "#004080", // Active (clicked) background color
    },
    gap: 8,
  },
};

const TopCreators = ({ width, height }) => {
  const containerStyle = {
    width: width || "100%", // Default to 100% if no width is provided
    maxHeight: height || "100%", // Default to 100% if no height is provided
    marginRight: "40px",
  };

  const [creators, setCreators] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("all_time"); // Default to "All Time"

  const handleDropdownChange = (filter) => {
    setSelectedFilter(filter); // Update the selected filter
  };

  // Function to calculate start and end dates based on the selected filter
  const getDateRange = (filter) => {
    const endDate = new Date(); // End date is always the current date
    let startDate = new Date();

    switch (filter) {
      case "last_week":
        startDate.setDate(endDate.getDate() - 7); // Subtract 7 days
        break;
      case "last_month":
        startDate.setMonth(endDate.getMonth() - 1); // Subtract 1 month
        break;
      case "last_year":
        startDate.setFullYear(endDate.getFullYear() - 1); // Subtract 1 year
        break;
      case "all_time":
        startDate = null; // No start date for "All Time"
        break;
      default:
        startDate = null;
    }

    return { startDate, endDate };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { startDate, endDate } = getDateRange(selectedFilter);
        const response = await getTopCreators(startDate, endDate);
        console.log({ response });
        setCreators(response?.data?.body.Data);
      } catch (error) {
        console.error("Error fetching top creators:", error);
      }
    };

    fetchData();
  }, [selectedFilter]);

  return (
    <div style={containerStyle}>
      <Creators creators={creators} onDropdownChange={handleDropdownChange} />
    </div>
  );
};
export default TopCreators;
