import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useMeeting, usePubSub } from "@videosdk.live/react-sdk";
import { useLocation } from "react-router-dom";

// Import images directly for the overlays
import welcome from './../Assests/welcome.jpg';
import brb from './../Assests/brb.jpg';
import CompanyName_Logo_T from './../Assests/trans_dyrect.png';
import welcome_T from './../Assests/trans_welcome.png';
import { createStageOverlays, deleteStageOverlays, getStageOverlays } from "../Services/BannersApi";
import { FaEye, FaEyeSlash, FaTrash } from 'react-icons/fa'; // Import icons from Font Awesome

const MeetingOverlayPanel = () => {
    const { publish } = usePubSub("UPDATE_MEETING_OVERLAY");  // PubSub to update overlay
    const mMeeting = useMeeting();

    // Updated overlays with unique id and StageOverlayUrl
    const [overlays, setOverlays] = useState([
        { StageOverlayID: '1', StageOverlayUrl: welcome, isDefault: true },
        { StageOverlayID: '2', StageOverlayUrl: brb, isDefault: true },
        { StageOverlayID: '3', StageOverlayUrl: CompanyName_Logo_T, isDefault: true },
        { StageOverlayID: '4', StageOverlayUrl: welcome_T, isDefault: true }
    ]);

    const [newOverlay, setNewOverlay] = useState(null);  // Stores the new overlay file
    const [isCreatingOverlay, setIsCreatingOverlay] = useState(false);  // Flag for overlay creation
    const [selectedOverlay, setSelectedOverlay] = useState(() => {
        return localStorage.getItem('selectedStageOverlayID') || null;
    });
    const [hoveredOverlay, setHoveredOverlay] = useState(null);  // Hovered overlay

    const fileInputRef = useRef(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const meetingId = queryParams.get("meetingId");

    useEffect(() => {

        fetchStageOverlays();
    }, []); // Empty dependency array to run only once when the component is mounted

    // Fetch logos on mount
    useEffect(() => {
        const savedOverlayId = localStorage.getItem("selectedStageOverlayID");
        if (savedOverlayId) {
            setSelectedOverlay(savedOverlayId); // Ensure it's a number
        }
    }, []);
    const fetchStageOverlays = async () => {
        try {
            const response = await getStageOverlays();
            if (response?.Data) {
                const apiOverlays = response.Data.map(overlay => ({
                    ...overlay,
                    isDefault: false // API-fetched overlays should not be marked as default
                }));
                setOverlays([...apiOverlays,
                { StageOverlayID: '1', StageOverlayUrl: welcome, isDefault: true },
                { StageOverlayID: '2', StageOverlayUrl: brb, isDefault: true },
                { StageOverlayID: '3', StageOverlayUrl: CompanyName_Logo_T, isDefault: true },
                { StageOverlayID: '4', StageOverlayUrl: welcome_T, isDefault: true }
                ]);
            }
            // Get the selected logo from localStorage if available
            const selectedStageOverlayID = localStorage.getItem('selectedStageOverlayID');
            if (selectedStageOverlayID) {
                const stageOverlay = response?.Data?.find(stageOverlay => stageOverlay.stageOverlayID === selectedStageOverlayID);
                // setSelectedLogo(logo); // Set the selected logo if found
            }
        } catch (error) {
            console.error('Error fetching logos:', error);
        }
    };

    // Trigger file input to upload overlay
    const triggerFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Trigger file input click
        }
    };

    // Handle file change (selecting new overlay)
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewOverlay(file);
            setIsCreatingOverlay(true); // Start overlay creation process
        }
    };

    // Convert image file to base64
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => resolve(reader.result);  // Resolve with base64 data
            reader.onerror = (error) => reject(error);  // Reject if there's an error
        });
    };

    // Handle saving the new overlay
    const handleSaveOverlay = async () => {
        if (newOverlay) {
            try {
                // Convert the new overlay to base64
                const base64Image = await convertToBase64(newOverlay);
                let prefix = "data:image/png;base64,";
                const overlayData = {
                    UserID: localStorage.getItem('user_id'),
                    StageOverlayBase64: base64Image.substring(prefix.length),
                };
                await createStageOverlays(overlayData);
                await fetchStageOverlays();

                console.log('overlayData', overlayData);

                setNewOverlay(null);  // Reset the new overlay state
                setIsCreatingOverlay(false);  // Reset the creation state
            } catch (error) {
                console.error("Error saving overlay:", error);
            }
        }
    };

    const handleDeleteOverlay = async (overlay) => {
        try {
            // Call the delete API function
            if (selectedOverlay === overlay.StageOverlayID) {
                setSelectedOverlay(null);
                publish({ OverlayId: null, OverlayUrl: null, visibility: false }, { persist: true });
                localStorage.removeItem('selectedStageOverlayID');  // Remove from localStorage
            }

            // Delete the overlay from the database
            await deleteStageOverlays(overlay.StageOverlayID);

            // Fetch updated overlays list after deletion
            fetchStageOverlays();

        } catch (error) {
            console.error("Error deleting overlay:", error);
        }
    };

    // Handle selecting a overlay (show or hide)
    const handleOverlayClick = (overlayId) => {
        console.log('overlayId', overlayId);
        localStorage.setItem('selectedStageOverlayID', overlayId)

        if (selectedOverlay === overlayId) {
            // Deselect the overlay
            console.log('overlay123', overlayId);
            setSelectedOverlay(null);
            publish({ OverlayId: null, OverlayUrl: null, visibility: false }, { persist: true });
            localStorage.removeItem('selectedStageOverlayID');  // Remove from localStorage
        } else {
            // Select the new overlay
            console.log('overlay123 else', overlayId);
            setSelectedOverlay(overlayId);
            const overlay = overlays.find(b => b.StageOverlayID === overlayId);  // Get the overlay by id
            console.log('overlay', overlay)
            publish({ OverlayId: overlayId, OverlayUrl: overlay.StageOverlayUrl, visibility: true }, { persist: true });
            localStorage.setItem('selectedStageOverlayID', overlayId);  // Store the selected overlay ID
        }
    };

    return (
        <Box color="white" className="px-3">
            <div style={{ minHeight: "55vh", maxHeight: "75vh", overflowY: "auto" }}>
                <div className="my-2">
                    <Typography
                        variant="h6"
                        onClick={triggerFileInput} // Trigger file input click
                        style={{
                            cursor: 'pointer',
                            width: '100%',
                            textAlignLast: 'center',
                            padding: '2%',
                            borderRadius: '10px',
                        }}
                        className="schedule-btn"
                    >
                        Add Overlay Image
                    </Typography>
                </div>

                {/* File input for selecting overlay */}
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept="image/png, image/jpeg"  // Updated to accept both PNG and JPG/JPEG
                    onChange={handleFileChange}
                />

                {/* Preview and Save button for the new overlay */}
                {isCreatingOverlay && newOverlay && (
                    <div style={{ marginTop: "20px", textAlign: "center" }}>
                        <img
                            src={URL.createObjectURL(newOverlay)} // Preview selected image
                            alt="Overlay Preview"
                            style={{ width: "20em", height: "10em", objectFit: "contain" }}
                        />
                        <div className="py-3">
                            <Button variant="contained" color="primary" onClick={handleSaveOverlay}>
                                Save Overlay
                            </Button>
                        </div>
                    </div>
                )}

                {/* Display available overlays */}
                <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>

                    {overlays?.map((overlay) => (
                        <div
                            key={overlay.StageOverlayID}
                            className="overlayData pointer"
                            onMouseEnter={() => setHoveredOverlay(overlay.StageOverlayID)}
                            onMouseLeave={() => setHoveredOverlay(null)}
                            onClick={() => handleOverlayClick(overlay.StageOverlayID)}
                            style={{
                                position: "relative",
                                width: "45%",
                                marginBottom: "10px",
                                backgroundColor: selectedOverlay === overlay.StageOverlayID ? '#ffffff50' : hoveredOverlay === overlay.StageOverlayID ? '#ffffff30' : 'transparent',
                            }}
                        >
                            <img
                                src={overlay.StageOverlayUrl}
                                alt={`Overlay ${overlay.StageOverlayID}`}
                                style={{ width: "100%", height: "100px", objectFit: "contain" }}
                            />

                            {/* The buttons are visible when hovered */}
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                    color: 'white',
                                    padding: '5px 10px',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    visibility: selectedOverlay === overlay.StageOverlayID || hoveredOverlay === overlay.StageOverlayID ? 'visible' : 'hidden',
                                    display: 'flex', // Use flexbox for horizontal alignment
                                    // gap: '10px', // Add space between icons
                                    transition: 'transform 0.3s ease-out, opacity 0.3s ease-out',
                                }}
                            >
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleOverlayClick(overlay.StageOverlayID);
                                    }}
                                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent: 'center', padding: '5px 10px' }}
                                >
                                    {selectedOverlay === overlay.StageOverlayID ? <FaEyeSlash /> : <FaEye />} {/* Show or hide icon */}
                                    {!overlay.isDefault && (
                                        <FaTrash
                                            style={{
                                                marginLeft: '10px',
                                                cursor: 'pointer',
                                                color: 'red',
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteOverlay(overlay);
                                            }}
                                        />
                                    )}

                                </div>
                            </div>
                        </div>
                    ))}


                </div>
            </div>
        </Box>
    );
};

export default MeetingOverlayPanel;
