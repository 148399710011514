  import React from 'react'

  const Inputbox = ({ label, placeholder, ...props }) => {
    return (
      <div className="custom_input-container">
        <div className="input-label">
          <span >{label}</span>
        </div>

        <input className="input-box" placeholder={placeholder} {...props} />
      </div>
    )
  }

  export default Inputbox
